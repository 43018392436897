import differenceInYears from 'date-fns/differenceInYears';

const defaultDate = 'Mon Jan 01 0001';

export const validateAge = (dateOfBirth: Date, minAge: number) =>
    differenceInYears(new Date(), dateOfBirth) >= minAge &&
    dateOfBirth.toDateString() !== defaultDate;

export const validateAgeAtDate = (
    dateOfBirth: Date,
    date: Date,
    minAge: number,
) =>
    differenceInYears(date, dateOfBirth) >= minAge &&
    dateOfBirth.toDateString() !== defaultDate;

export const validateNotInPastWithExcludedValue = (
    date: string,
    exclude = ['Tue Jan 01 1901', defaultDate],
): boolean =>
    date.startsWith(exclude[0]) || date.startsWith(exclude[1])
        ? true
        : new Date(date) > new Date();

export const validateNotEarlierThanPickOffDateWithExcludedValue = (
    date: string,
    dropOffDate: string,
    exclude = ['Tue Jan 01 1901', defaultDate],
): boolean =>
    date.startsWith(exclude[0]) || date.startsWith(exclude[1])
        ? true
        : new Date(dropOffDate) < new Date(date);
