import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { B2C, NotificationBanner } from 'cosmos-components';

import { UkBrandNames, UsBrandNames } from 'src/enums';
import { sciBrandNameSelector } from 'src/components/shared/SciPage/SciPage.selector';
import globalMsg from 'src/messages';
import { NotificationBannerMessage } from 'src/components/shared/NotificationBannerMessage';

import * as SShared from './products.form.style';

import { useProductsForm } from './products.hooks';
import { MODULE_PREFIX } from './products.constants';
import { ProductFormProps } from './products.types';
import { ProductNodes } from './productsNodes.form';

import productMessages from './products.messages';
import * as S from './products.style';

export const ProductsForm = ({
    category,
    successCallback,
}: ProductFormProps) => {
    const { isLoading, loadingNodeId, groups, control, groupError, onSubmit } =
        useProductsForm({
            category,
            successCallback,
        });

    const intl = useIntl();
    const brandName = useSelector(sciBrandNameSelector);
    const [visible, setVisible] = useState(false);
    const [popupInfo, setPopupInfo] = useState('');

    if (isLoading) {
        return <B2C.LoaderSpinner size="large" />;
    }
    if (!groups || groups.length <= 0) {
        return <div>No result</div>;
    }

    const hideLiabilityOnChildren = Object.values(UkBrandNames).includes(
        brandName as UkBrandNames,
    );

    const hideLiability = Object.values(UsBrandNames).includes(
        brandName as UsBrandNames,
    );

    return (
        <SShared.StyledForm onSubmit={onSubmit}>
            {groups
                .sort((group1, group2) => group1.order - group2.order)
                .map((group, index) => {
                    return (
                        <>
                            {index > 0 &&
                                (group.title || group.description) && (
                                    <S.Line />
                                )}
                            {!!group?.title && (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: group?.title,
                                    }}
                                />
                            )}
                            {!!group?.description && (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: group?.description,
                                    }}
                                />
                            )}
                            <ProductNodes
                                category={category}
                                control={control}
                                configurationNodes={group.configurationNodes}
                                display={group.display}
                                required={group.required}
                                childrenConfigurationNode={group.children}
                                hideLiability={hideLiability}
                                hideLiabilityOnChildren={
                                    hideLiabilityOnChildren
                                }
                                loadingNodeId={loadingNodeId}
                                setPopupInfo={setPopupInfo}
                                setVisible={setVisible}
                                key={`ProductNode-${group.id.configurationNodeGroupId}`}
                            />
                        </>
                    );
                })}
            <SShared.ActionWrapper>
                <SShared.StyledPrimaryButton
                    data-test-id={`${MODULE_PREFIX}-save`}
                    type="submit"
                    disabled={!!loadingNodeId}
                >
                    {intl.formatMessage(globalMsg.buttons.continue)}
                </SShared.StyledPrimaryButton>
            </SShared.ActionWrapper>
            {!!groupError && (
                <NotificationBanner
                    message={
                        <NotificationBannerMessage
                            header={
                                <strong>
                                    {intl.formatMessage(
                                        productMessages.errorAndWarnings
                                            .errorHeader,
                                    )}
                                </strong>
                            }
                            content={
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: groupError,
                                    }}
                                />
                            }
                        />
                    }
                    type={'error'}
                    isClosable={false}
                    notificationIconSize="medium1"
                    hasBorder
                    isNotificationIconFilled
                />
            )}
            <S.StyledModal
                data-test-id="modal"
                visible={visible}
                onClose={() => setVisible(false)}
                closable={false}
                style={{
                    display: 'grid',
                    alignContent: 'center',
                    height: 'calc(100% - 6.4rem)',
                    margin: '0px auto',
                    marginTop: '3.2rem',
                    maxWidth: 'calc(100% - 6.4rem)',
                }}
            >
                <div dangerouslySetInnerHTML={{ __html: popupInfo }} />
                <S.StyledModalPrimaryButton onClick={() => setVisible(false)}>
                    <S.StyledExitIcon name="close" size="medium" isInverted />
                    <S.Close>
                        {intl.formatMessage(globalMsg.buttons.close)}
                    </S.Close>
                </S.StyledModalPrimaryButton>
            </S.StyledModal>
        </SShared.StyledForm>
    );
};
