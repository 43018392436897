import { ProductChoice } from 'src/modules/addProducts/types';
import { Booking } from 'src/modules/retrieveBooking/types';

const orgId = 'mock-org-id';

export const MOCK_RES = {
    // retrieveBooking module
    GET_selfcheckin: {
        id: {
            rentalId: 'mock-rental-id',
            organizationId: orgId,
        },
        vehicleName: 'Maui Ultima',
        pickUpDate: '2021-10-10',
        pickUpBranch: 'Auckland Airport(AKL)',
        dropOffDate: '2021-10-15',
        dropOffBranch: 'Auckland Airport(AKL)',
        openingHours: {
            start: '8:00AM',
            end: '4:00PM',
        },
        rentalReference: 'mock-rental-reference',
        customerInfo: {
            firstName: 'Lux',
            lastName: 'Liu',
            addressLine1: '123 cosmos street',
            addressLine2: '456 line 2',
            state: 'Bla',
            city: 'akl',
            country: 'nz',
            postCode: '0101',
            email: 'mock@gmail.com',
            mobile: { countryCode: '+64', phoneNumber: '0211234567' },
            altPhone: { countryCode: '+64', phoneNumber: '0217654321' },
        },
        priceDetail: {
            id: {
                calculatedId: 'mock-calculated-id',
                organizationId: orgId,
            },
            primaryProductPrice: {
                amountDueAtSupplier: 500,
                currency: 'NZD',
                description: '6BB - Frontier - 6 Berth',
                priceType: 'Gross',
                product: {
                    id: {
                        productId: 'mock-product-id',
                        organizationId: orgId,
                    },
                    name: 'Frontier - 6 Berth',
                    code: '6BB',
                    quantityRequired: false,
                    rentalProductId: 'mock-rental-product-id',
                },
                startDate: '2021-07-12T00:00:00+00:00',
                endDate: '2021-07-17T00:00:00+00:00',
                duration: {
                    length: 5,
                    unit: 'Day',
                },
                unitValue: 100,
                rateType: 'Day',
            },
            liabilityProductPrices: [
                {
                    amountDueAtSupplier: 100,
                    currency: 'NZD',
                    description:
                        'BOND2 - 4WD Liability (with Liability Reduction Option)',
                    priceType: 'Gross',
                    product: {
                        id: {
                            productId: 'mock-product-id-1',
                            organizationId: orgId,
                        },
                        name: '4WD Liability (with Liability Reduction Option)',
                        code: 'BOND2',
                        quantityRequired: false,
                        rentalProductId: 'mock-rental-product-id',
                    },
                    startDate: '2021-07-12T00:00:00+00:00',
                    endDate: '2021-07-17T00:00:00+00:00',
                    duration: {
                        length: 1,
                        unit: 'Fixed',
                    },
                    unitValue: 100,
                    rateType: 'Fixed',
                },
            ],
            otherProductPrices: [],
            grandTotal: {
                currency: 'AUD',
                value: 6803.7321,
            },
        },
    } as Booking,
    GET_retrieveChoice: [
        {
            id: {
                productId: 'mock-product-id-1',
                organizationId: orgId,
            },
            name: '4WD Liability (with Liability Reduction Option)',
            code: 'BOND2',
            quantityRequired: false,
            unitValue: 100,
        },
        {
            id: {
                productId: 'mock-product-id-2',
                organizationId: orgId,
            },
            name: 'Table',
            code: 'TABLE',
            quantityRequired: true,
            unitValue: 20,
        },
    ] as ProductChoice[],
};
