import styled from 'styled-components';
import { Page, PrimaryButton } from 'cosmos-components';
import { Divider } from 'src/components';

import { getScreenBreaks } from 'src/utils/htmlHelper';
import { CarousalDots } from '../CarousalDots';
import { CAHeader } from 'src/components/shared/Header';
import { DeviceType } from '../../../enums';

export const StyledPage = styled(Page)`
    color: ${({ theme: { colors } }) => colors.secondBlack};
    background-color: unset;
    width: 100%;
    height: 100%;
    padding: 0;
    header {
        margin-bottom: 0;
    }
`;

export const StyledCAHeaderWrapper = styled.div`
    #header-icons {
        margin: auto;

        @media (max-width: ${(props) => getScreenBreaks(props).screenSMax}) {
            margin: 0 2rem;
        }

        @media (min-width: ${(props) =>
                getScreenBreaks(props).screenSMax}) and (max-width: ${(props) =>
                getScreenBreaks(props).screenMDMax}) {
            width: 70.8rem;
        }

        @media (min-width: ${(props) => getScreenBreaks(props).screenLMin}) {
            width: 109rem;
        }
    }
`;

export const StyledCarouselDots = styled(CarousalDots)`
    margin: auto;

    @media (max-width: ${(props) => getScreenBreaks(props).screenSMax}) {
        margin: 0 2rem;
    }

    @media (min-width: ${(props) =>
            getScreenBreaks(props).screenSMax}) and (max-width: ${(props) =>
            getScreenBreaks(props).screenMDMax}) {
        width: 70.8rem;
    }

    @media (min-width: ${(props) => getScreenBreaks(props).screenLMin}) {
        width: 109rem;
    }
`;

// IMPORTANT: This is a temporary solution for US release. Please remove this Styled component entirely once US work is done.
export const StyledCarouselDotsUS = styled(CarousalDots)`
    margin: auto;

    @media (max-width: ${(props) => getScreenBreaks(props).screenSMax}) {
        margin: 0 2rem;
    }

    @media (min-width: ${(props) =>
            getScreenBreaks(props).screenSMax}) and (max-width: ${(props) =>
            getScreenBreaks(props).screenMDMax}) {
        width: 70.8rem;
    }

    @media (min-width: ${(props) => getScreenBreaks(props).screenLMin}) {
        width: 72rem;
        padding-right: 3.2rem;
    }
`;

export const BookingSummaryModalContainer = styled.div`
    .rc-dialog-root {
        .rc-dialog-wrap {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: fit-content;
            height: 90% !important;
            overflow: auto;
        }

        .rc-dialog {
            width: auto !important;
        }

        .rc-dialog-body {
            padding: 0;
        }

        .rc-dialog-close {
            z-index: 1;
        }
    }
`;

export const Container = styled.div`
    position: relative;
    overflow: unset !important;
    width: fit-content !important;
    display: contents;
    .rc-dialog-root {
        .rc-dialog-wrap {
            position: absolute;
            top: 30rem;
            overflow: unset;
            .rc-dialog {
                width: 30.8rem;
                @media (min-width: ${(props) =>
                        getScreenBreaks(props).screenMDMin}) and (max-width: ${(
                        props,
                    ) => getScreenBreaks(props).screenMDMax}) {
                    width: 70.8rem;
                }

                @media (min-width: ${(props) =>
                        getScreenBreaks(props).screenLMin}) {
                    width: 72rem;
                }
                .rc-dialog-content {
                    min-height: 12.8rem;
                    border-top: ${({ theme: { colors } }) =>
                        `0.4rem solid ${colors.secondaryOrange}`};
                    color: ${({ theme }) => theme.colors.black};
                    background: ${({ theme }) => theme.colors.white};
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                }
            }
            .rc-dialog-body {
                padding: 1.5rem;
                display: flex;
                flex-direction: column;
                align-content: center;
                align-items: center;
                gap: 1rem;
            }
        }
    }
`;

export const LoaderContainer = styled.div`
    width: 100vw;
    height: 100vh;
`;

export const PageContainer = styled.div<{ deviceType: DeviceType }>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: ${({ deviceType }) =>
        deviceType === DeviceType.Desktop ? '109rem' : '100%'};
    margin: auto;
    margin-top: ${({ deviceType }) =>
        deviceType === DeviceType.Desktop ? '3rem' : '1rem'};
`;

export const TravelSummaryContainer = styled.div<{ deviceType: DeviceType }>`
    margin: auto;

    ${({ deviceType }) =>
        deviceType === DeviceType.Mobile &&
        `
        margin: 0.8rem 2rem 0 2rem;
    `}

    ${({ deviceType }) =>
        deviceType === DeviceType.Tablet &&
        `
        width: 70.8rem;
    `}
`;

// IMPORTANT: This is a temporary solution for US release. Please remove this Styled component entirely once US work is done.
export const TravelSummaryContainerUs = styled.div<{ deviceType: DeviceType }>`
    margin: auto;

    ${({ deviceType }) =>
        deviceType === DeviceType.Mobile &&
        `
        margin: 0.8rem 2rem 0 2rem;
    `}

    ${({ deviceType }) =>
        deviceType === DeviceType.Tablet &&
        `
        width: 70.8rem;
    `}


    ${({ deviceType }) =>
        deviceType === DeviceType.Desktop &&
        `
        width: 72rem;
        padding-right: 3.2rem;
        margin-bottom: 2rem;
    `}
`;

export const ChildrenContainer = styled.div<{ deviceType: DeviceType }>`
    ${({ deviceType }) =>
        deviceType === DeviceType.Mobile &&
        `
        width: 100%;
        margin: 2rem;
    `}

    ${({ deviceType }) =>
        deviceType === DeviceType.Tablet &&
        `
        width: 70.8rem;
        margin-top: 2rem;
    `}
    
    ${({ deviceType }) =>
        deviceType === DeviceType.Desktop &&
        `
        width: 72rem;
        padding-right: 3.2rem;
    `}
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
    position: relative;
    margin: auto;
    display: flex;
    opacity: 1;
    min-width: 15.85rem;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
`;

export const StyledParagraph = styled.p`
    margin: 0;
    letter-spacing: 0;
    color: ${({ theme: { colors } }) => colors.black};
    opacity: 1;
    padding: 1rem 0 1.5rem 0;
    font-weight: normal;
    font-size: 1.8rem;

    a {
        color: ${({ theme: { colors } }) => colors.black};
    }
`;

export const StyledErrorA = styled.a`
    color: ${({ theme: { colors } }) => colors.black};
    font-size: 1.8rem;
    text-decoration: underline;
`;

export const StyledDivider = styled(Divider)``;

export const QFFDisclamerParagraph = styled.p`
    display: flex;
    font-size: 1.28rem;
    margin-top: 1rem;

    sup {
        margin-top: -0.3rem;
        margin-right: 0.3rem;
        vertical-align: super;
        font-size: smaller;
    }
`;
