import axios, {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    AxiosHeaders,
} from 'axios';

type HeaderValue = string | number | boolean | AxiosHeaders | string[] | null;

export abstract class HttpClient {
    protected readonly _instance: AxiosInstance;
    protected ErrorHandlers: any;

    public constructor(config: AxiosRequestConfig) {
        this._instance = axios.create(config);
        this.ErrorHandlers = {};
    }

    private readonly _handleRequest = (config: AxiosRequestConfig) => ({
        ...config,
        ...config.data,
    });

    protected handleError = (error: any) =>
        Promise.reject(error.response.data.error);

    private readonly _handleResponse = ({ data }: AxiosResponse) => data;

    protected initializeResponseInterceptor = () => {
        this._instance.interceptors.response.use(
            this._handleResponse,
            this.handleError,
        );
        this._instance.interceptors.request.use(this._handleRequest);
    };

    protected appendInstanceHeadersByKey = (
        key: string,
        value: HeaderValue,
    ) => {
        this._instance.defaults.headers[key] = value;
    };

    protected getHeaderElementByKey = (key: string): HeaderValue => {
        return this._instance.defaults.headers[key];
    };
}
