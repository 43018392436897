/* eslint-disable react/prop-types */
import { FC } from 'react';
import { Logo } from 'cosmos-components';
import { Divider } from 'src/components';
import styled, { css } from 'styled-components';
import { getScreenBreaks } from 'src/utils/htmlHelper';
import heroBannerRB from 'src/assets/images/driving_sunset_RB.png';
import heroBannerRBIph from 'src/assets/images/driving_sunset_RB_iph.png';
import heroBannerRBIpa from 'src/assets/images/driving_sunset_RB_ipa.png';

export const RBHeader: FC = () => {
    return (
        <StyledHeader>
            <HeaderIcons>
                <HeaderIcons>
                    <RoadBearStyledLogo name="coloredRoadBear" isInverted />
                    <BritzStyledLogo name="coloredBritz" />
                </HeaderIcons>
            </HeaderIcons>
            <DefaultStyledHeroBanner src={heroBannerRB} />
            <IpaStyledHeroBanner src={heroBannerRBIpa} />
            <IphStyledHeroBanner src={heroBannerRBIph} />
            <StyledDivider />
        </StyledHeader>
    );
};

export const StyledHeader = styled.div`
    display: inline-block;
    margin: 0;
    width: 100%;
    margin-bottom: 0;
`;
export const StyledDivider = styled(Divider)`
    background-image: ${({ theme: { colors } }) =>
        `linear-gradient(to left, ${colors.yellow},  ${colors.orange}, ${colors.red})`};
    height: 0.7rem;
    border-top: none;
    display: inline-block;
    padding-right: calc(100% - 0.5px);
    position: relative;
    top: -0.3rem;
    @media (min-width: ${(props) => getScreenBreaks(props).screenLMin}) {
        height: 1rem;
    }
`;
export const HeaderIcons = styled.div`
    display: flex;
    padding: 0rem;
    margin: auto;
    border-top: 0.1rem solid ${({ theme: { colors } }) => colors.secondaryGrey};
    border-bottom: solid 0.1rem
        ${({ theme: { colors } }) => colors.secondaryGrey};
    justify-content: flex-end;
    height: 5rem;
    align-items: center;
    @media (max-width: ${(props) => getScreenBreaks(props).screenSMax}) {
        width: 30.2rem;
    }

    @media (min-width: ${(props) =>
            getScreenBreaks(props).screenSMax}) and (max-width: ${(props) =>
            getScreenBreaks(props).screenMDMax}) {
        width: 60.8rem;
    }

    @media (min-width: ${(props) => getScreenBreaks(props).screenLMin}) {
        width: 72rem;
    }
`;

const coloredlogo = css`
    @media (min-width: ${(props) => getScreenBreaks(props).screenMDMin}) {
        padding-left: 0.1rem;
    }
`;

export const RoadBearStyledLogo = styled(Logo)`
    ${coloredlogo};
    width: 10rem;
    height: 5rem;
    margin-right: 1.6rem;
`;

export const BritzStyledLogo = styled(Logo)`
    ${coloredlogo};
    width: 5rem;
    height: 3rem;
    margin-top: 1rem;
    margin-right: 1.6rem;
`;

export const DefaultStyledHeroBanner = styled.img`
    display: none;
    @media (min-width: ${(props) => getScreenBreaks(props).screenLMin}) {
        height: 48.9rem;
        display: block;
        width: 100%;
        object-fit: cover;
        object-position: 0px 100%;
    }
`;

export const IpaStyledHeroBanner = styled.img`
    display: none;
    @media (min-width: ${(props) =>
            getScreenBreaks(props).screenMDMin}) and (max-width: ${(props) =>
            getScreenBreaks(props).screenMDMax}) {
        height: 38.4rem;
        display: block;
        width: 100%;
        object-fit: cover;
        object-position: 0px 100%;
    }
`;

export const IphStyledHeroBanner = styled.img`
    display: none;
    @media (max-width: ${(props) => getScreenBreaks(props).screenSMax}) {
        display: block;
        width: 100%;
        object-fit: cover;
        object-position: 0px 100%;
        height: 20.6rem;
    }
`;
