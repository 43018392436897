import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { Product } from '../modules/addProducts/types';
import * as api from '../modules/updateCustomerInfo/updateCustomerInfo.api';
import { usePrimaryProductInfoKey } from './useSci';
import { SCIApi } from '../apis';
import { sciLanguageSelector } from 'src/components/shared/SciPage/SciPage.selector';
import { getLangCode } from 'src/utils/string';

const getPrimaryProductInfo = async (language: string) => {
    const apiCalls = SCIApi.getInstance();
    apiCalls.configureLanguage(language);
    const result: AxiosResponse<Product> = await apiCalls.get<Product>(
        api.getPrimaryProductInfo,
    );

    return result?.data ?? result;
};

export const usePrimaryProductInfo = () => {
    const language = getLangCode(useSelector(sciLanguageSelector));
    const { isLoading, isError, error, data, refetch } = useQuery({
        queryKey: [usePrimaryProductInfoKey],
        queryFn: () => getPrimaryProductInfo(language),
    });

    useEffect(() => {
        refetch();
    }, [language, refetch]);

    return {
        isLoading,
        isError,
        error,
        data,
    };
};
