import React from 'react';
import {
    Route,
    Routes as ReactRouterDomRoutes,
    Navigate,
} from 'react-router-dom';

import { AddAdditionalDriversComponent } from 'src/modules/addAdditionalDrivers/addAdditionalDrivers.Component';
import { AddDriverComponent } from 'src/modules/addDriver/addDriver.component';

import { RetrieveSelfCheckInComponent } from 'src/modules/retrieveSelfCheckIn/retrieveSelfCheckIn.component';
import { UpdateCustomerInfoComponent } from 'src/modules/updateCustomerInfo/updateCustomerInfo.component';
import { RegisterCompletionComponent } from 'src/modules/registerCompletion/registerCompletion.component';
import { VehicleProtectionsComponent } from 'src/modules/products/vehicleProtections/vehicleProtections.component';
import { ExtrasComponent } from 'src/modules/products/extras/extras.component';
import { PaymentComponent } from 'src/modules/payment/payment.component';

import { SciPagesEnum } from 'src/components/shared/SciPage/types';
import { usePageAccessibility } from 'src/hooks/usePageAccessibility';

import { SagaReactRouterMiddlewareComponent } from 'src/components/shared/SagaReactRouterMiddleware';

interface RouteValidateProps {
    children: React.ReactNode;
    sciPage: SciPagesEnum;
}

const RouteValidation = ({ children, sciPage }: RouteValidateProps) => {
    const { validatePageAccessibility } = usePageAccessibility();

    const { canAccess, redirectUrl } = validatePageAccessibility(sciPage);

    if (canAccess) {
        return <>{children}</>;
    }

    return <Navigate to={redirectUrl} />;
};

export const Routes = () => (
    <ReactRouterDomRoutes>
        <Route
            path="/"
            element={
                <SagaReactRouterMiddlewareComponent>
                    <RetrieveSelfCheckInComponent />
                </SagaReactRouterMiddlewareComponent>
            }
        />
        <Route
            path="/updateCustomerInfo"
            element={
                <SagaReactRouterMiddlewareComponent>
                    <RouteValidation sciPage={SciPagesEnum.UpdateCustomerPage}>
                        <UpdateCustomerInfoComponent />
                    </RouteValidation>
                </SagaReactRouterMiddlewareComponent>
            }
        />
        <Route
            path="/addDriver"
            element={
                <SagaReactRouterMiddlewareComponent>
                    <RouteValidation sciPage={SciPagesEnum.AddDriverPage}>
                        <AddDriverComponent />
                    </RouteValidation>
                </SagaReactRouterMiddlewareComponent>
            }
        />
        <Route
            path="/addAdditionalDrivers"
            element={
                <SagaReactRouterMiddlewareComponent>
                    <RouteValidation
                        sciPage={SciPagesEnum.AddAdditionalDriversPage}
                    >
                        <AddAdditionalDriversComponent />
                    </RouteValidation>
                </SagaReactRouterMiddlewareComponent>
            }
        />
        <Route
            path="/vehicleProtections"
            element={
                <SagaReactRouterMiddlewareComponent>
                    <RouteValidation sciPage={SciPagesEnum.VehicleProtections}>
                        <VehicleProtectionsComponent />
                    </RouteValidation>
                </SagaReactRouterMiddlewareComponent>
            }
        />
        <Route
            path="/extras"
            element={
                <SagaReactRouterMiddlewareComponent>
                    <RouteValidation sciPage={SciPagesEnum.Extras}>
                        <ExtrasComponent />
                    </RouteValidation>
                </SagaReactRouterMiddlewareComponent>
            }
        />
        <Route
            path="/payment"
            element={
                <SagaReactRouterMiddlewareComponent>
                    <RouteValidation sciPage={SciPagesEnum.Payment}>
                        <PaymentComponent />
                    </RouteValidation>
                </SagaReactRouterMiddlewareComponent>
            }
        />
        <Route
            path="/registrationComplete"
            element={
                <SagaReactRouterMiddlewareComponent>
                    <RouteValidation
                        sciPage={SciPagesEnum.RegistrationComplete}
                    >
                        <RegisterCompletionComponent />
                    </RouteValidation>
                </SagaReactRouterMiddlewareComponent>
            }
        />
        <Route path="*" element={<>Not found</>}></Route>
    </ReactRouterDomRoutes>
);
