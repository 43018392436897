/* eslint-disable react/prop-types */
import { FC, ReactNode } from 'react';
import { Labeled } from 'cosmos-components';
import { IconNameType } from 'cosmos-components/dist/components/Icon/Icon';

import styled from 'styled-components';
import { ContainedModal } from '../ContainedModal';

type LabelWithContainedModalProps = {
    iconName?: IconNameType;
    label: string;
    isLabelRequired?: boolean;
    modalInfo: string | ReactNode;
    modalWidth?: number;
};

export const LabelWithContainedModal: FC<LabelWithContainedModalProps> = ({
    iconName = 'info',
    label,
    isLabelRequired,
    modalInfo,
    modalWidth,
}) => (
    <Wrapper data-test-id="label-with-contained-modal">
        <StyledLabeled isRequired={isLabelRequired} label={label}>
            <StyledContainedModal
                iconName={iconName}
                dialogInfo={modalInfo}
                width={modalWidth}
            />
        </StyledLabeled>
    </Wrapper>
);

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const StyledLabeled = styled(Labeled)`
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 0;
    height: 2rem;
    position: relative;
    label {
        flex-basis: 94%;
    }
`;

const StyledContainedModal = styled(ContainedModal)`
    align-self: flex-end;
`;
