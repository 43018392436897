import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { SCIApi, selfCheckInRoute } from '../apis';
import { SciContent } from '../types/SciContent';
import { sciLanguageSelector } from 'src/components/shared/SciPage/SciPage.selector';
import { getLangCode } from 'src/utils/string';

export const useGetContentKey = 'useGetContentKey';
export const getContentRoute = `${selfCheckInRoute}/contents`;

const getContent = async (key: string, language: string) => {
    const apiCalls = SCIApi.getInstance();
    apiCalls.configureLanguage(language);
    const result: AxiosResponse<SciContent[]> = await apiCalls.get<
        SciContent[]
    >(getContentRoute, {
        key,
    });

    return result?.data ?? result;
};

export const useSciContent = (key: string) => {
    const language = getLangCode(useSelector(sciLanguageSelector));
    const { isLoading, isError, data, refetch } = useQuery({
        queryKey: [useGetContentKey, key],
        queryFn: () => getContent(key, language),
    });

    useEffect(() => {
        refetch();
    }, [language, refetch]);

    return {
        isLoading,
        isError,
        data: data?.[0]?.content,
    };
};
