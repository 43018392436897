import { createRoutine } from 'redux-saga-routines';
import { Action } from 'redux-actions';

import {
    genApiSchemaState,
    setSingleState,
    wrapWithModule,
    RESET_APP_ACTION,
} from 'src/utils/redux';
import { UpdateCustomerInfoPayload, UpdateCustomerInfoState } from './types';

const wrapWithNamespace = wrapWithModule('modules/updateCustomerInfo');

export const updateCustomerInfoAction = createRoutine(
    wrapWithNamespace('update'),
);

const initialState: UpdateCustomerInfoState = genApiSchemaState({
    data: undefined,
});

const updateCustomerInfoReducer = (
    state = initialState,
    { type, payload }: Action<UpdateCustomerInfoPayload>,
): UpdateCustomerInfoState => {
    const handler = HANDLERS[type];
    return handler ? handler(state, payload) : state;
};

const handleTrigger = setSingleState('loading', true);

const handleSuccess = (payload: UpdateCustomerInfoPayload) => ({
    loading: false,
    error: {},
    data: payload,
});

const handleError = (_: UpdateCustomerInfoState, payload: any) => ({
    ...initialState,
    error: payload,
});

const HANDLERS = {
    [updateCustomerInfoAction.TRIGGER]: handleTrigger,
    [updateCustomerInfoAction.SUCCESS]: handleSuccess,
    [updateCustomerInfoAction.FAILURE]: handleError,
    [RESET_APP_ACTION]: () => initialState,
};

export default updateCustomerInfoReducer;
