import React from 'react';
import { B2C } from 'cosmos-components';

import { sciContentKeys } from 'src/constants';
import { useSciContent } from 'src/hooks/useSciContent';

const SciContent = ({
    sciContentKey,
    hideLoader = false,
}: {
    sciContentKey: (typeof sciContentKeys)[keyof typeof sciContentKeys];
    hideLoader?: boolean;
}) => {
    const { data, isLoading } = useSciContent(sciContentKey);

    if (isLoading && !hideLoader) {
        return <B2C.LoaderSpinner size="small" />;
    } else if (isLoading) {
        return <></>;
    }

    return (
        <span
            dangerouslySetInnerHTML={{
                __html: data ?? '',
            }}
        ></span>
    );
};

export default SciContent;
