import { IconNameType } from 'cosmos-components/dist/components/Icon/Icon';
import { PaymentMethodEnum } from './payment.enum';

export const MODULE_PREFIX = 'payment';
export const KEY_3DSECURE = '3DSecure';

export const PAYMENT_METHOD_ICON_MAPPING: { [key: string]: IconNameType } = {
    [PaymentMethodEnum.DebitCard]: 'b2cCard',
    [PaymentMethodEnum.CreditCard]: 'b2cCard',
    [PaymentMethodEnum.Manual]: 'b2cDollar',
};
