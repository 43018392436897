import styled, { css } from 'styled-components';
import {
    Input,
    PrimaryButton,
    FormField,
    IconButton,
    SecondaryButton,
    NotificationBanner,
} from 'cosmos-components';
import { getScreenBreaks } from 'src/utils/htmlHelper';

export const StyledFormField = styled(FormField)`
    margin-top: 1.5rem;
    margin-bottom: 0;
    flex: 1 1 0;
    label {
        margin-bottom: 0.5rem;
    }
`;

export const ActionWrapper = styled.div`
    width: 100%;
    text-align: center;
`;
export const WhiteSpace = styled.div`
    height: 13.1rem;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
    position: relative;
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    width: 100%;
`;

export const StyledInput = styled(Input)`
    ${({ disabled }) =>
        disabled &&
        css`
            background-color: ${({ theme: { colors } }) => colors.primaryGrey};
        `}
`;

export const AddAdditionalDriversForm = styled.form`
    margin: auto;
`;

export const Row = styled.div`
    display: flex;
    > div:last-child {
        margin-left: 0.8rem;
    }
    .dateOfBirth_fr label, .licenseNumber_fr label {
        height: 3rem;
    }
`;

export const AdditionalDriversFormHeader = styled.h3`
    margin-top: 0;
    padding: 0.8rem 0rem;
    border-bottom: 2px solid ${({ theme: { colors } }) => colors.red};
`;

export const AdjacentPairsWrapper = styled.div`
    flex-basis: 50%;
`;

export const StyledIconButton = styled(IconButton)`
    background: unset;
    height: auto;
    position: relative;
    bottom: 0.6rem;
    float: right;
    padding-right: 1rem;
  }
`;

export const StyledConfirmMessage = styled.h3`
    height: 7.75rem;
    background: #ffe8d6 0% 0% no-repeat padding-box;
    opacity: 1;
    padding-top: 1.5rem;
    padding-left: 1.5rem;
    margin-top: 0;
`;
export const StyledNoSecondaryButton = styled(SecondaryButton)`
    float: right;
    opacity: 1;
    min-width: 1.2rem;
    margin-right: 2.5rem;
    margin-top: 1.5rem;
    background: #ffe8d6;
    font-size: 1.4rem;
    color: ${({ theme: { colors } }) => colors.black} !important;
    opacity: 1;
`;
export const StyledYesPrimaryButton = styled(PrimaryButton)`
    float: right;
    opacity: 1;
    min-width: 6.1rem;
    margin-right: 1.5rem;
    margin-top: 1.5rem;
    font-size: 1.4rem;
    opacity: 1;
    color: ${({ theme: { colors } }) => colors.white};
`;
export const ConfirmMessage = styled.div`
    color: #231f20;
    font-weight: normal;
`;

export const StyledNotificationBanner = styled(NotificationBanner)`
    margin-top: 1rem;
    @media (min-width: ${(props) => getScreenBreaks(props).screenMDMin}) {
        margin-left: auto;
        margin-right: auto;
        width: 32.7rem;
    }
`;

export const StyledMessageContent = styled.div`
    margin: 0;
    padding-top: 0.9rem;
    font-weight: 500;
`;

export const SpanFeeMayApply = styled.span`
    color: ${({ theme: { colors } }) => colors.lightBlack};
    font-family: Montserrat;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
