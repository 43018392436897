import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { DeviceType } from '../enums';

const getWindowDimensions = () => ({
    width: window?.innerWidth,
    height: window?.innerHeight,
});

const getDeviceType = (
    dimensions: { width: number },
    mediaQueryBreakPoints: { screenSMax: string; screenMDMax: string },
) => {
    if (dimensions.width < parseInt(mediaQueryBreakPoints.screenSMax)) {
        return DeviceType.Mobile;
    }
    if (dimensions.width < parseInt(mediaQueryBreakPoints.screenMDMax)) {
        return DeviceType.Tablet;
    }
    return DeviceType.Desktop;
};

export const useWindowDimensions = () => {
    const theme: any = useTheme();
    const mediaQueryBreakPoints = theme.mediaQueryBreakPoints;

    const [dimensions, setDimensions] = useState(getWindowDimensions());
    const [deviceType, setDeviceType] = useState<DeviceType>(
        getDeviceType(dimensions, mediaQueryBreakPoints),
    );

    useEffect(() => {
        const handleResize = () => {
            setDimensions(getWindowDimensions());
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        setDeviceType(getDeviceType(dimensions, mediaQueryBreakPoints));
    }, [dimensions, mediaQueryBreakPoints]);

    return {
        dimensions,
        deviceType,
    };
};
