import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { SciPage } from 'src/components/shared/SciPage';
import { SciPagesEnum } from 'src/components/shared/SciPage/types';
import { sciBrandNameSelector } from 'src/components/shared/SciPage/SciPage.selector';
import {
    setCarouselDotsCurrentPage,
    setCarouselPageIsCompletedAction,
} from 'src/components/shared/SciPage/SciPage.duck';
import { SciContent } from 'src/components/shared/SciContent';

import { useSci } from 'src/hooks/useSci';
import { sciContentKeys } from 'src/constants';
import { UkBrandNames, UsBrandNames } from 'src/enums';
import { customerInfoSelector } from '../updateCustomerInfo/updateCustomerInfo.selector';

import { AddAdditionalDriversForm } from './addAdditionalDrivers.form';
import msg from './addAdditionalDriverMessages';
import * as S from './addAdditionalDrivers.Component.style';

const MODULE_PREFIX = 'add-additionalDriver';
export const componentAddAdditionalDrivers = `${MODULE_PREFIX}-component`;

export const AddAdditionalDriversComponent: FC = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const brandName = useSelector(sciBrandNameSelector);
    const selfCheckIn = useSci();

    const customerInfo = useSelector(customerInfoSelector);
    const headerTitle = intl.formatMessage(
        msg.formHeaderItems.additionalDriverDetails,
    );
    const showInformationPopup = !(
        brandName === UsBrandNames.roadBear ||
        brandName === UsBrandNames.elmonte ||
        brandName === UkBrandNames.europe
    );
    const rental = selfCheckIn?.data?.rental;
    const selfCheckingIsLoading = selfCheckIn?.isLoading;
    const numberOfAdults = rental?.numberOfAdults ?? 0;

    useEffect(() => {
        const showAdditionalDriversScreen = customerInfo?.isDriver
            ? numberOfAdults > 1
            : numberOfAdults > 2;

        if (
            !selfCheckingIsLoading &&
            !!customerInfo?.id?.profileId &&
            !showAdditionalDriversScreen
        ) {
            dispatch(
                setCarouselPageIsCompletedAction({
                    page: SciPagesEnum.AddAdditionalDriversPage,
                }),
            );
        }
    }, [
        dispatch,
        selfCheckingIsLoading,
        numberOfAdults,
        customerInfo?.isDriver,
        customerInfo?.id?.profileId,
    ]);

    useEffect(() => {
        dispatch(
            setCarouselDotsCurrentPage(SciPagesEnum.AddAdditionalDriversPage),
        );
    }, [dispatch]);

    return (
        <SciPage data-test-id={componentAddAdditionalDrivers}>
            <S.Header>
                <S.AdditionalDriverDetails>
                    <S.AdditionalDriverDetailsLabel>
                        {headerTitle}
                    </S.AdditionalDriverDetailsLabel>
                    {showInformationPopup && (
                        <S.StyleContainedModal
                            dialogInfo={
                                <SciContent
                                    sciContentKey={
                                        sciContentKeys.sciInfoBoxAdditionalDriverDetails
                                    }
                                />
                            }
                        />
                    )}
                </S.AdditionalDriverDetails>
                <S.AdditionalDriverDetailsNote>
                    <SciContent
                        sciContentKey={
                            sciContentKeys.sciAdditionalDriverDetailsPage
                        }
                    />
                </S.AdditionalDriverDetailsNote>
            </S.Header>
            {<AddAdditionalDriversForm />}
        </SciPage>
    );
};
