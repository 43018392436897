import { combineReducers } from 'redux';
import { default as updateCustomerInfo } from 'src/modules/updateCustomerInfo/updateCustomerInfo.duck';
import { default as retrieveCustomerInfo } from 'src/modules/updateCustomerInfo/retrieveCustomerInfo.duck';
import { default as retrieveSelfCheckIn } from 'src/modules/retrieveSelfCheckIn/retrieveSelfCheckIn.duck';
import { default as retrieveCountries } from 'src/modules/updateCustomerInfo/retrieveCountries.duck';
import { default as addDriver } from 'src/modules/addDriver/addDriver.duck';
import { default as addAdditionalDrivers } from 'src/modules/addAdditionalDrivers/addAdditionalDrivers.duck';
import { default as retrievePrimaryProduct } from 'src/modules/updateCustomerInfo/retrievePrimaryProductInfo.duck';
import { default as retrieveOrganizationSettings } from 'src/modules/updateCustomerInfo/retrieveOrganizationSettings.duck';

import { default as sciPageInfo } from 'src/components/shared/SciPage/SciPage.duck';
import { default as sagaReactRouterMiddleware } from 'src/components/shared/SagaReactRouterMiddleware/SagaReactRouterMiddleware.duck';

import { SCIApi } from 'src/apis';
import { QueryClientAccessor } from 'src/redux/QueryClientReduxCompatible';
import { RESET_APP_ACTION } from 'src/utils/redux';

const allReducers = combineReducers({
    updateCustomerInfo,
    retrieveCustomerInfo,
    retrieveSelfCheckIn,
    retrieveCountries,
    sciPageInfo,
    addDriver,
    addAdditionalDrivers,
    retrievePrimaryProduct,
    retrieveOrganizationSettings,
    sagaReactRouterMiddleware,
});

const rootReducer = (state: any, action: any) => {
    if (action.type === RESET_APP_ACTION) {
        // reset everything
        const apiCalls = SCIApi.getInstance();
        apiCalls.configureSessionId('');
        QueryClientAccessor.getInstance()?.clear();
    }
    return allReducers(state, action);
};

export default rootReducer;
