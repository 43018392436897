import React, { FC } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { getReCaptchaKey } from 'src/utils/configStore';

export interface GoogleReCaptchaWrapperProps {
    children: React.ReactNode;
}

export const GoogleReCaptchaWrapper: FC<GoogleReCaptchaWrapperProps> = ({
    children,
}) => {
    const key = getReCaptchaKey();

    if (key) {
        return (
            <GoogleReCaptchaProvider reCaptchaKey={key}>
                {children}
            </GoogleReCaptchaProvider>
        );
    }
    return <>{children}</>;
};
