import { defineMessages } from 'react-intl';

const fields = defineMessages({
    email: {
        id: `addDriver.formField.email`,
        defaultMessage: 'Email',
    },
    firstName: {
        id: `addDriver.formField.firstName`,
        defaultMessage: 'First Name',
    },
    lastName: {
        id: `addDriver.formField.lastName`,
        defaultMessage: 'Last Name',
    },
    dateOfBirth: {
        id: `addDriver.formField.dateOfBirth`,
        defaultMessage: 'Date of Birth',
    },
    licenseNumber: {
        id: `addDriver.formField.licenseNumber`,
        defaultMessage: 'License Number',
    },
    licenceNumber: {
        id: `addDriver.formField.licenceNumber`,
        defaultMessage: 'Licence Number',
    },
    issuingCountry: {
        id: `addDriver.formField.issuingCountry`,
        defaultMessage: 'Issuing Country',
    },
    state: {
        id: `addDriver.formField.state`,
        defaultMessage: 'Issuing State/Province/Region',
    },
    issueDate: {
        id: `addDriver.formField.issueDate`,
        defaultMessage: 'Date of Issue',
    },
    expiryDate: {
        id: `addDriver.formField.expiryDate`,
        defaultMessage: 'Expiry Date',
    },
    dateOfTest: {
        id: `addDriver.formField.dateOfTest`,
        defaultMessage: 'Date of Test',
    },
    expiredLicense: {
        id: `addDriver.formField.expiredLicense`,
        defaultMessage: 'License has expired',
    },
    expiredLicence: {
        id: `addDriver.formField.expiredLicence`,
        defaultMessage: 'Licence has expired',
    },
    licenseEarlierThanDropOff: {
        id: `addDriver.formField.licenseEarlierThanDropOff`,
        defaultMessage: 'License cannot be earlier than the Drop off Date',
    },
    invalidIssueDate: {
        id: `addDriver.formField.invalidIssueDate`,
        defaultMessage: `Date of issue can't be in the future`,
    },
    validEmail: {
        id: 'addDriver.formField.validEmail',
        defaultMessage: 'Email must be a valid email',
    },
    enterEmail: {
        id: `addDriver.formField.enterEmail`,
        defaultMessage: 'Please enter email address',
    },
    enterFirstName: {
        id: `addDriver.formField.enterFirstName`,
        defaultMessage: 'Please enter first name',
    },
    enterLastName: {
        id: `addDriver.formField.enterLastName`,
        defaultMessage: 'Please enter last name',
    },
    enterLicenseNumber: {
        id: `addDriver.formField.enterLicenseNumber`,
        defaultMessage: `Please enter the license number`,
    },
    enterLicenceNumber: {
        id: `addDriver.formField.enterLicenceNumber`,
        defaultMessage: `Please enter the licence number`,
    },
    enterDateOfBirth: {
        id: `addDriver.formField.enterDateOfBirth`,
        defaultMessage: `Please enter the date of birth`,
    },
    invalidAge: {
        id: `addDriver.formField.invalidAge`,
        defaultMessage: `Age should be greater than {minAge}`,
    },
    invalidDateOfTest: {
        id: `addDriver.formField.invalidDateOfTest`,
        defaultMessage: `License has to be valid for at least 2 years before pickup.`,
    },
});
const placeHolders = defineMessages({
    email: {
        id: `addDriver.placeHolder.email`,
        defaultMessage: 'Enter email',
    },
    firstName: {
        id: `addDriver.placeHolder.firstName`,
        defaultMessage: 'Enter first name',
    },
    lastName: {
        id: `addDriver.placeHolder.lastName`,
        defaultMessage: 'Enter last name',
    },
    dateOfBirth: {
        id: `addDriver.placeHolder.dateOfBirth`,
        defaultMessage: 'Enter date of birth',
    },
    licenseNumber: {
        id: `addDriver.placeHolder.licenseNumber`,
        defaultMessage: 'Enter license number',
    },
    licenceNumber: {
        id: `addDriver.placeHolder.licenceNumber`,
        defaultMessage: 'Enter licence number',
    },
    issuingCountry: {
        id: `addDriver.placeHolder.issuingCountry`,
        defaultMessage: 'Select country',
    },
    state: {
        id: `addDriver.placeHolder.state`,
        defaultMessage: 'Select State/Province/Region',
    },
    issueDate: {
        id: `addDriver.placeHolder.issueDate`,
        defaultMessage: 'Enter date of issue',
    },
    expiryDate: {
        id: `addDriver.placeHolder.expiryDate`,
        defaultMessage: 'Enter expiry date',
    },
    dateOfTest: {
        id: `addDriver.placeHolder.dateOfTest`,
        defaultMessage: 'Date of Test',
    },
});
const formHeaderItems = defineMessages({
    driverFormHeader: {
        id: 'addDriver.formHeaderItem.driverFormHeader',
        defaultMessage: 'Driver',
    },
    driverDetails: {
        id: 'addDriver.formHeaderItem.driverDetails',
        defaultMessage: 'Driver Details',
    },
    webChat: {
        id: 'addDriver.formHeaderItem.webChat',
        defaultMessage: `Web Chat`,
    },
    detailsDialogMsgP1: {
        id: 'addDriver.formHeaderItem.detailsDialogMsgP1',
        defaultMessage: `A minimum age of {minAge} years applies if the rented vehicle is a Action Pod, Mighty Highball or Mighty Double Down.`,
    },
    detailsDialogMsgP2: {
        id: 'addDriver.formHeaderItem.detailsDialogMsgP2',
        defaultMessage: `If this applies to you, please contact us via {webChatUrl} or phone number below for assistance.`,
    },
});

const buttons = defineMessages({
    continue: {
        id: 'addDriver.buttons.continueButton',
        defaultMessage: 'Save & Continue',
    },
    complete: {
        id: 'addDriver.buttons.completeButton',
        defaultMessage: 'Complete',
    },
});

const error = defineMessages({
    pleaseContactUs: {
        id: 'addDriver.error.pleaseContactUs',
        defaultMessage: `Please contact us using below details.`,
    },
    someThingWrong: {
        id: 'addDriver.error.someThingWrong',
        defaultMessage: `Oops, something went wrong.`,
    },
});

const addDriverMessages = {
    fields,
    placeHolders,
    formHeaderItems,
    buttons,
    error,
};

export default addDriverMessages;
