import { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import 'react-datepicker/dist/react-datepicker.css';
import { B2C, Select } from 'cosmos-components';
import { ValidationError } from 'src/components';
import { sciTheme } from 'src/themes';
import { DateTimePicker } from 'src/components/shared/DateTimePicker/DateTimePicker';
import { NotificationBannerMessage } from 'src/components/shared/NotificationBannerMessage';
import { prepareDate } from 'src/utils/DateHelper';
import { getLangCode } from 'src/utils/string';
import { convertObjectToArrayByKey } from 'src/utils';
import { countriesObjectSelector } from '../updateCustomerInfo/updateCustomerInfo.selector';
import { updateCountry, useAddDriverForm } from './addDriver.hooks';
import msg from './addDriverMessages';
import * as S from './addDriver.form.style';
import {
    sciBrandNameSelector,
    sciLanguageSelector,
} from 'src/components/shared/SciPage/SciPage.selector';
import { UkBrandNames, UsBrandNames } from 'src/enums';

const MODULE_PREFIX = 'addDriver';
export const formAddDriver = `${MODULE_PREFIX}-form`;
export const inputEmail = `${MODULE_PREFIX}-input-email`;
export const inputEmailErrorMessage = `${MODULE_PREFIX}-email-error-message`;
export const inputLastName = `${MODULE_PREFIX}-input-lastName`;
export const inputLastNameErrorMessage = `${MODULE_PREFIX}-lastName-error-message`;
export const inputFirstName = `${MODULE_PREFIX}-input-firstName`;
export const inputFirstNameErrorMessage = `${MODULE_PREFIX}-firstName-error-message`;
export const dateExpiryDate = `${MODULE_PREFIX}-date-expiryDate`;
export const dateOfTest = `${MODULE_PREFIX}-dateOfTest`;
export const dateExpiryDateErrorMessage = `${MODULE_PREFIX}-date-expiryDate-error-message`;
export const dateOfTestErrorMessage = `${MODULE_PREFIX}-dateOfTest-error-message`;
export const dateIssueDate = `${MODULE_PREFIX}-date-issueDate`;
export const dateIssueDateErrorMessage = `${MODULE_PREFIX}-date-issueDate-error-message`;
export const inputLicenseNumber = `${MODULE_PREFIX}-input-licenseNumber`;
export const inputLicenseNumberErrorMessage = `${MODULE_PREFIX}-licenseNumber-error-message`;
export const selectState = `${MODULE_PREFIX}-select-state`;
export const selectStateErrorMessage = `${MODULE_PREFIX}-select-state-error-message`;
export const selectIssuingCountry = `${MODULE_PREFIX}-select-issuingCountry`;
export const selectIssuingCountryErrorMessage = `${MODULE_PREFIX}-select-issuingCountry-error-message`;
export const dateDateOfBirth = `${MODULE_PREFIX}-date-dateOfBirth`;
export const dateDateOfBirthErrorMessage = `${MODULE_PREFIX}-date-dateOfBirth-error-message`;
export const submitButton = `${MODULE_PREFIX}-submit-button`;

export const AddDriverForm: FC = () => {
    const {
        control,
        formState,
        trigger,
        onSubmit,
        isFormLoading,
        driver,
        handleCountryChange,
        updateDriverErrors,
        toggleState,
        stateRequired,
        handleStateChange,
        states,
        countries,
    } = useAddDriverForm();
    const intl = useIntl();
    const statesArray = convertObjectToArrayByKey(states ?? [], 'name').sort();
    const issuingCountry = driver?.document?.documentType?.issuerName;
    const state = driver?.document?.documentType?.state;
    const { validationErrors, serverErrors } = updateDriverErrors;

    const lang = getLangCode(useSelector(sciLanguageSelector));
    const brandName = useSelector(sciBrandNameSelector);
    const isUSBrand =
        brandName === UsBrandNames.roadBear ||
        brandName === UsBrandNames.elmonte;
    const isUKBrand = brandName === UkBrandNames.europe;

    const [_lang, setLang] = useState(lang);
    const isErr = !!Object.keys(formState?.errors).length;
    const countriesWithCodes = useSelector(countriesObjectSelector);
    const [selectedCountry, setSelectedCountry] = useState(issuingCountry);

    useEffect(() => {
        if (isErr && lang !== _lang) {
            trigger();
            setLang(lang);
        }
    }, [_lang, lang, trigger, isErr]);

    useEffect(() => {
        updateCountry(
            selectedCountry,
            countriesWithCodes,
            countries,
            setSelectedCountry,
        );
    }, [countriesWithCodes]);

    useEffect(() => {
        if (issuingCountry) {
            setSelectedCountry(issuingCountry);
        }
    }, [issuingCountry]);

    return (
        <S.AddDriverForm onSubmit={onSubmit} data-test-id={formAddDriver}>
            <S.DriverFormHeader>
                {intl.formatMessage(msg.formHeaderItems.driverFormHeader)}
            </S.DriverFormHeader>
            <B2C.LoaderContainer
                loading={isFormLoading}
                opacity={{ opacity: '90%', color: sciTheme.colors.b2cWhite }}
            >
                <Controller
                    name="email"
                    control={control}
                    defaultValue={driver?.email}
                    render={({ field, fieldState: { error } }) => {
                        return (
                            <S.AdjacentPairsWrapper>
                                <S.StyledFormField
                                    isRequired
                                    label={intl.formatMessage(msg.fields.email)}
                                    name="email"
                                >
                                    <S.StyledInput
                                        testId={inputEmail}
                                        disabled={!!driver?.email}
                                        value={field.value}
                                        placeholder={intl.formatMessage(
                                            msg.placeHolders.email,
                                        )}
                                        autoComplete="off"
                                        name="email"
                                        onInputChange={field.onChange}
                                    />
                                </S.StyledFormField>
                                <ValidationError
                                    data-test-id={inputEmailErrorMessage}
                                >
                                    {validationErrors?.['email'] ??
                                        error?.message}
                                </ValidationError>
                            </S.AdjacentPairsWrapper>
                        );
                    }}
                />
                <S.Row>
                    <Controller
                        name="firstName"
                        control={control}
                        defaultValue={driver?.firstName}
                        render={({ field, fieldState: { error } }) => (
                            <S.AdjacentPairsWrapper>
                                <S.StyledFormField
                                    isRequired
                                    label={intl.formatMessage(
                                        msg.fields.firstName,
                                    )}
                                    name="firstName"
                                >
                                    <S.StyledInput
                                        testId={inputFirstName}
                                        value={field.value}
                                        placeholder={intl.formatMessage(
                                            msg.placeHolders.firstName,
                                        )}
                                        autoComplete="off"
                                        name="firstName"
                                        onInputChange={field.onChange}
                                        disabled={!!driver?.firstName}
                                    />
                                </S.StyledFormField>
                                <ValidationError
                                    data-test-id={inputFirstNameErrorMessage}
                                >
                                    {error?.message}
                                </ValidationError>
                            </S.AdjacentPairsWrapper>
                        )}
                    />
                    <Controller
                        name="lastName"
                        control={control}
                        defaultValue={driver?.lastName}
                        render={({ field, fieldState: { error } }) => (
                            <S.AdjacentPairsWrapper>
                                <S.StyledFormField
                                    isRequired
                                    label={intl.formatMessage(
                                        msg.fields.lastName,
                                    )}
                                    name="lastName"
                                >
                                    <S.StyledInput
                                        testId={inputLastName}
                                        value={field.value}
                                        placeholder={intl.formatMessage(
                                            msg.placeHolders.lastName,
                                        )}
                                        autoComplete="off"
                                        name="lastName"
                                        onInputChange={field.onChange}
                                        disabled={!!driver?.lastName}
                                    />
                                </S.StyledFormField>
                                <ValidationError
                                    data-test-id={inputLastNameErrorMessage}
                                >
                                    {error?.message}
                                </ValidationError>
                            </S.AdjacentPairsWrapper>
                        )}
                    />
                </S.Row>
                <S.Row>
                    <Controller
                        name="document.dateOfBirth"
                        control={control}
                        defaultValue={driver?.document?.dateOfBirth}
                        render={({ field, fieldState: { error, invalid } }) => (
                            <S.AdjacentPairsWrapper>
                                <S.StyledFormField
                                    isRequired
                                    label={intl.formatMessage(
                                        msg.fields.dateOfBirth,
                                    )}
                                    name="dateOfBirth"
                                    className={`dateOfBirth_${lang}`}
                                >
                                    <DateTimePicker
                                        testId={dateDateOfBirth}
                                        name="dateOfBirth"
                                        selected={prepareDate(field.value)}
                                        onChange={(date) =>
                                            field.onChange(date ?? undefined)
                                        }
                                        placeholderText={intl.formatMessage(
                                            msg.placeHolders.dateOfBirth,
                                        )}
                                        disabled={
                                            !!driver?.document?.dateOfBirth
                                        }
                                        isError={invalid}
                                    ></DateTimePicker>
                                </S.StyledFormField>

                                <ValidationError
                                    data-test-id={dateDateOfBirthErrorMessage}
                                >
                                    {error?.message}
                                </ValidationError>
                            </S.AdjacentPairsWrapper>
                        )}
                    />
                    <Controller
                        name="document.number"
                        control={control}
                        defaultValue={driver?.document?.number}
                        render={({ field, fieldState: { error, invalid } }) => (
                            <S.AdjacentPairsWrapper>
                                <S.StyledFormField
                                    className={`licenseNumber_${lang}`}
                                    isRequired
                                    label={
                                        isUSBrand
                                            ? intl.formatMessage(
                                                  msg.fields.licenseNumber,
                                              )
                                            : intl.formatMessage(
                                                  msg.fields.licenceNumber,
                                              )
                                    }
                                    name="licenseNumber"
                                >
                                    <S.StyledInput
                                        testId={inputLicenseNumber}
                                        value={field.value ?? ''}
                                        placeholder={
                                            isUSBrand
                                                ? intl.formatMessage(
                                                      msg.placeHolders
                                                          .licenseNumber,
                                                  )
                                                : intl.formatMessage(
                                                      msg.placeHolders
                                                          .licenceNumber,
                                                  )
                                        }
                                        autoComplete="off"
                                        name="licenseNumber"
                                        onInputChange={field.onChange}
                                        isError={invalid}
                                    />
                                </S.StyledFormField>
                                <ValidationError
                                    data-test-id={
                                        inputLicenseNumberErrorMessage
                                    }
                                >
                                    {error?.message}
                                </ValidationError>
                            </S.AdjacentPairsWrapper>
                        )}
                    />
                </S.Row>
                <Controller
                    name="document.documentType.issuerName"
                    control={control}
                    defaultValue={selectedCountry}
                    render={({ field, fieldState: { error } }) => (
                        <S.StyledFormField
                            isRequired
                            label={intl.formatMessage(
                                msg.fields.issuingCountry,
                            )}
                            name="issuingCountry"
                        >
                            <Select
                                data-test-id={selectIssuingCountry}
                                value={selectedCountry}
                                onChange={(selected) => {
                                    setSelectedCountry(selected.target.value);
                                    field.onChange(selected);
                                    handleCountryChange(selected.target.value);
                                }}
                                placeholder={intl.formatMessage(
                                    msg.placeHolders.issuingCountry,
                                )}
                                options={R.uniq(countries)}
                            />
                            <ValidationError
                                data-test-id={selectIssuingCountryErrorMessage}
                            >
                                {error?.message}
                            </ValidationError>
                        </S.StyledFormField>
                    )}
                />
                {toggleState && (
                    <Controller
                        name="document.documentType.state"
                        control={control}
                        defaultValue={state}
                        render={({ field, fieldState: { error } }) => (
                            <S.StyledFormField
                                isRequired={stateRequired}
                                label={intl.formatMessage(msg.fields.state)}
                                name="state"
                            >
                                <Select
                                    data-test-id={selectState}
                                    value={statesArray?.find(
                                        (option) => option === field.value,
                                    )}
                                    onChange={(selected) => {
                                        field.onChange(selected);
                                        handleStateChange(
                                            selected.target.value,
                                        );
                                    }}
                                    placeholder={intl.formatMessage(
                                        msg.placeHolders.state,
                                    )}
                                    options={R.uniq(statesArray)}
                                />
                                <ValidationError
                                    data-test-id={selectStateErrorMessage}
                                >
                                    {error?.message}
                                </ValidationError>
                            </S.StyledFormField>
                        )}
                    />
                )}
                <S.Row>
                    <Controller
                        name="document.validStart"
                        control={control}
                        defaultValue={driver?.document?.validStart}
                        render={({ field, fieldState: { error, invalid } }) => (
                            <S.AdjacentPairsWrapper>
                                <S.StyledFormField
                                    label={intl.formatMessage(
                                        msg.fields.issueDate,
                                    )}
                                    name="issueDate"
                                >
                                    <DateTimePicker
                                        testId={dateIssueDate}
                                        name="issueDate"
                                        selected={prepareDate(field.value)}
                                        onChange={(date) =>
                                            field.onChange(date ?? undefined)
                                        }
                                        placeholderText={intl.formatMessage(
                                            msg.placeHolders.issueDate,
                                        )}
                                        isError={invalid}
                                    ></DateTimePicker>
                                </S.StyledFormField>
                                <ValidationError
                                    data-test-id={dateIssueDateErrorMessage}
                                >
                                    {error?.message}
                                </ValidationError>
                            </S.AdjacentPairsWrapper>
                        )}
                    />
                    <Controller
                        name="document.validEnd"
                        control={control}
                        defaultValue={driver?.document?.validEnd}
                        render={({ field, fieldState: { error, invalid } }) => (
                            <S.AdjacentPairsWrapper>
                                <S.StyledFormField
                                    label={intl.formatMessage(
                                        msg.fields.expiryDate,
                                    )}
                                    name="expiryDate"
                                >
                                    <DateTimePicker
                                        testId={dateExpiryDate}
                                        name="expiryDate"
                                        selected={prepareDate(field.value)}
                                        onChange={(date) =>
                                            field.onChange(date ?? undefined)
                                        }
                                        placeholderText={intl.formatMessage(
                                            msg.placeHolders.expiryDate,
                                        )}
                                        isError={invalid}
                                    ></DateTimePicker>
                                </S.StyledFormField>
                                <ValidationError
                                    data-test-id={dateExpiryDateErrorMessage}
                                >
                                    {error?.message}
                                </ValidationError>
                            </S.AdjacentPairsWrapper>
                        )}
                    />
                </S.Row>
                {isUKBrand && (
                    <Controller
                        name="document.dateOfTest"
                        control={control}
                        defaultValue={driver?.document?.dateOfTest}
                        render={({ field, fieldState: { error, invalid } }) => (
                            <S.AdjacentPairsWrapper>
                                <S.StyledFormField
                                    label={intl.formatMessage(
                                        msg.fields.dateOfTest,
                                    )}
                                    name="dateOfTest"
                                >
                                    <DateTimePicker
                                        testId={dateOfTest}
                                        name="dateOfTest"
                                        selected={prepareDate(field.value)}
                                        onChange={(date) =>
                                            field.onChange(date ?? undefined)
                                        }
                                        placeholderText={intl.formatMessage(
                                            msg.placeHolders.dateOfTest,
                                        )}
                                        isError={invalid}
                                    ></DateTimePicker>
                                </S.StyledFormField>
                                <ValidationError
                                    data-test-id={dateOfTestErrorMessage}
                                >
                                    {error?.message}
                                </ValidationError>
                            </S.AdjacentPairsWrapper>
                        )}
                    />
                )}
            </B2C.LoaderContainer>
            <S.ActionWrapper>
                <S.StyledPrimaryButton
                    data-test-id={submitButton}
                    isLoading={isFormLoading}
                    type="submit"
                >
                    {intl.formatMessage(msg.buttons.continue)}
                </S.StyledPrimaryButton>
            </S.ActionWrapper>
            {serverErrors && serverErrors.length > 0 && (
                <S.StyledNotificationBanner
                    message={
                        <NotificationBannerMessage
                            header={
                                <strong>
                                    {intl.formatMessage(
                                        msg.error.someThingWrong,
                                    )}
                                </strong>
                            }
                            content={
                                <>
                                    {serverErrors.map((error, index) => {
                                        return (
                                            <S.StyledMessageContent key={index}>
                                                <>{error}</>
                                            </S.StyledMessageContent>
                                        );
                                    })}
                                    <>
                                        {intl.formatMessage(
                                            msg.error.pleaseContactUs,
                                        )}
                                    </>
                                </>
                            }
                        />
                    }
                    type={'error'}
                    isClosable={false}
                    notificationIconSize="medium1"
                    hasBorder
                    isNotificationIconFilled
                />
            )}
        </S.AddDriverForm>
    );
};
