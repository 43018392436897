const orgId = '478242c4-3cbe-48af-9180-1e330e0b83d3';

export const MOCK_RES = {
    GET_ShuttlePoints: [
        {
            id: {
                organizationId: orgId,
                pointId: 'baaf3365-8db0-4778-b6bd-74183785e442',
            },
            code: 'baymont',
            name: 'Baymont by Wyndham Denver International Airport',
            type: 'Shuttle',
            address: {
                type: 'Physical',
                primary: false,
                line1: '6805 Argonne St, Denver, CO 80249',
                line2: '',
                line3: '',
                postCode: '80249',
                city: 'Denver',
                state: 'Colorado',
                stateCode: 'CO',
                countryCode: 'US',
                country: 'UnitedStates',
                id: {
                    organizationId: orgId,
                    addressId: '185eb6aa-454d-4cd1-a09b-6180f60df3b5',
                },
            },
        },
        {
            id: {
                organizationId: orgId,
                pointId: '85581fa0-56a8-4b93-bbce-a903df9ba5ea',
            },
            code: 'marriot',
            name: 'Courtyard by Marriott Denver Airport',
            type: 'Shuttle',
            address: {
                type: 'Physical',
                primary: false,
                line1: '6901 Tower Rd, Denver, CO 80249',
                line2: '',
                line3: '',
                postCode: '5032',
                city: 'Denver',
                state: 'Colorado',
                stateCode: 'CO',
                countryCode: 'US',
                country: 'UnitedStates',
                id: {
                    organizationId: orgId,
                    addressId: '185eb6aa-454d-4cd1-a09b-6180f60df3b5',
                },
            },
        },
    ],
};
