import { createRoutine } from 'redux-saga-routines';
import { Action } from 'redux-actions';

import {
    genApiSchemaState,
    setSingleState,
    wrapWithModule,
} from 'src/utils/redux';
import { CustomerInfo, ShuttleInfo, UpdateCustomerInfoState } from './types';

const wrapWithNamespace = wrapWithModule('modules/retrieveCustomerInfo');
export const retrieveCustomerInfoAction = createRoutine(
    wrapWithNamespace('retrieve'),
);
export const createShuttleAction = createRoutine(
    wrapWithNamespace('shuttle/create'),
);
export const deleteShuttleAction = createRoutine(
    wrapWithNamespace('shuttle/delete'),
);

const defaultCustomerInfo: CustomerInfo & ShuttleInfo = {
    id: { profileId: '', organizationId: '' },
    firstName: '',
    lastName: '',
    address: {
        country: '',
        countryCode: '',
        line1: '',
        line2: '',
        city: '',
        postCode: '',
        state: '',
    },
    email: '',
    adultsNum: 0,
    childrenNum: 0,
    isDriver: false,
    mobile: {
        countryCode: '',
        number: '',
        numberWithoutCountryCode: '',
        id: null,
    },
    phone: {
        countryCode: '',
        number: '',
        numberWithoutCountryCode: '',
        id: null,
    },
    maxPassengers: 0,
    maxAdults: 0,
    maxChildren: 0,
    isShuttleSelected: false,
    shuttleSelected: '',
    preferredLanguage: {
        name: '',
        locale: '',
    },
};
const updateInitialState: UpdateCustomerInfoState =
    genApiSchemaState<UpdateCustomerInfoState>({
        data: defaultCustomerInfo,
    });

const retrieveCustomerInfoReducer = (
    state = updateInitialState,
    { type, payload }: Action<CustomerInfo>,
): UpdateCustomerInfoState => {
    const handler = HANDLERS[type];
    return handler ? handler(state, payload) : state;
};

const handleTrigger = setSingleState('loading', true);

const handleSuccess = (state: UpdateCustomerInfoState, payload: any) => ({
    ...state,
    error: {},
    loading: false,
    data: { ...defaultCustomerInfo, ...payload, isDriver: payload.driver },
});

const handleError = (_: UpdateCustomerInfoState, payload: any) => ({
    ...updateInitialState,
    error: payload,
});

const HANDLERS = {
    [retrieveCustomerInfoAction.TRIGGER]: handleTrigger,
    [retrieveCustomerInfoAction.SUCCESS]: handleSuccess,
    [retrieveCustomerInfoAction.FAILURE]: handleError,
    RESET_APP: () => updateInitialState,
};

export default retrieveCustomerInfoReducer;
