import { all, put, takeLatest } from 'redux-saga/effects';

import { SCIApi } from 'src/apis';
import * as api from '../addDriver/addDriver.api';
import {
    BatchDriverItem,
    PostAdditionalDriversPayload,
    DeleteAdditionalDriverPayload,
} from '../addDriver/types';
import { ProfileIdentifier } from '../updateCustomerInfo/types';

import { retrieveDriversAction } from '../addDriver/ducks/retrieveDriver.duck';
import { updateAdditionalDriversAction } from './ducks/updateAdditionalDrivers.duck';
import { BatchResponseStatusEnum } from '../enums';
import { setCarouselPageIsCompletedAction } from 'src/components/shared/SciPage/SciPage.duck';
import { SciPagesEnum } from 'src/components/shared/SciPage/types';
import { BatchError, BatchResponse } from '../types';
import { deleteAdditionalDriverAction } from './ducks/deleteAdditionalDriver.duck';

const apiCalls = SCIApi.getInstance();

function* postRentalAdditionalDrivers({
    payload,
}: {
    payload: PostAdditionalDriversPayload;
}) {
    try {
        const response: BatchResponse<ProfileIdentifier> = yield apiCalls.post<
            BatchResponse<ProfileIdentifier>
        >(api.getRentalDriversPath(payload?.rentalId), payload?.drivers);
        if (!payload.drivers.length) {
            yield put(updateAdditionalDriversAction.success(payload.drivers));
            payload.reset(payload.drivers);

            yield put(
                setCarouselPageIsCompletedAction({
                    page: SciPagesEnum.AddAdditionalDriversPage,
                }),
            );
        }
        if (response.status === BatchResponseStatusEnum.success) {
            yield put(updateAdditionalDriversAction.success(payload.drivers));
            yield put(retrieveDriversAction.update(payload.drivers));
            payload.reset(payload.drivers);

            yield put(
                setCarouselPageIsCompletedAction({
                    page: SciPagesEnum.AddAdditionalDriversPage,
                }),
            );
        } else {
            const errors = [] as BatchError[];
            response.items.forEach(
                (driverResponse: BatchDriverItem, index: number) => {
                    if (
                        driverResponse.errorMessages.length ||
                        driverResponse.validationMessagesByProperty
                    ) {
                        const error = {
                            validationErrors:
                                driverResponse.validationMessagesByProperty,
                            serverErrors: driverResponse.errorMessages,
                            index: index,
                        } as BatchError;
                        errors.push(error);
                    }
                },
            );
            yield put(
                updateAdditionalDriversAction.failure({
                    errors,
                }),
            );
        }
    } catch (error) {
        yield put(
            updateAdditionalDriversAction.failure({ serverErrors: error }),
        );
    }
}
function* deleteAdditionalDriver({
    payload,
}: {
    payload: DeleteAdditionalDriverPayload;
}) {
    try {
        yield apiCalls.delete(
            yield api.getRentalDriversDeletePath(
                payload.rentalId,
                payload.profileId,
            ),
        );
        yield put(deleteAdditionalDriverAction.success({}));
        payload.onSuccessCallback();
    } catch (error) {
        yield put(deleteAdditionalDriverAction.failure(error));
    }
}

function* additionnalDriverSaga() {
    yield all([
        takeLatest(updateAdditionalDriversAction, postRentalAdditionalDrivers),
        takeLatest(deleteAdditionalDriverAction, deleteAdditionalDriver),
    ]);
}

export default additionnalDriverSaga;
