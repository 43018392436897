import styled from 'styled-components';
import { PrimaryButton } from 'cosmos-components';

export const StyledForm = styled.form`
    padding: 0;
    margin: auto;
    padding-bottom: 6rem;
`;

export const ActionWrapper = styled.div`
    width: 100%;
    text-align: center;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
    position: relative;
    margin: 1.6rem 0;
    width: 100%;
`;
