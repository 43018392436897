import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { retrieveSelfCheckInAction } from './retrieveSelfCheckIn.duck';
import { RetrieveSelfCheckInPayload } from './types';
import { rejectEmptyString } from 'src/utils/ramda';
import { useIntl } from 'react-intl';
import msg from './retrieveSelfCheckInMessages';

export const useRetrieveSelfCheckInForm = () => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleonSubmitWithReCaptcha = useCallback(
        async (data: any) => {
            if (executeRecaptcha) {
                const token = await executeRecaptcha(
                    'retrieveSelfCheckInAction',
                );

                dispatch(
                    retrieveSelfCheckInAction({
                        ...rejectEmptyString(data),
                        token,
                    }),
                );
            }
        },
        [executeRecaptcha, dispatch],
    );

    const schema = yup.object().shape({
        reference: yup
            .string()
            .required(intl.formatMessage(msg.fields.enterBookingReference)),
        lastName: yup
            .string()
            .required(intl.formatMessage(msg.fields.enterLastName)),
    });

    const { control, setValue, handleSubmit } =
        useForm<RetrieveSelfCheckInPayload>({
            resolver: yupResolver(schema),
        });

    return {
        control,
        setValue,
        onSubmit: handleSubmit(handleonSubmitWithReCaptcha),
    };
};
