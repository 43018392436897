import { rest } from 'msw';

import { getApiBaseUrl } from 'src/utils/configStore';
import { Point } from 'src/types/Point';

import { getShuttlePoints } from '../../updateCustomerInfo.api';
import { MOCK_RES } from './shuttleService.api.mock.responses';

const baseURL = getApiBaseUrl();
const delayDuration = 1500;

const shuttleApiMocks = [
    rest.get<Point[]>(`${baseURL}${getShuttlePoints}`, (req, res, ctx) =>
        res(
            ctx.delay(delayDuration),
            ctx.status(200),
            ctx.json(MOCK_RES.GET_ShuttlePoints),
        ),
    ),
];

export default shuttleApiMocks;
