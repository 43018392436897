/* eslint-disable react/prop-types */
import { useRef, useState, FC, ComponentProps, ReactNode } from 'react';
import 'rc-dialog/assets/index.css';
import styled from 'styled-components';
import { IconButton, Modal, PrimaryButton, Icon } from 'cosmos-components';
import { IconNameType } from 'cosmos-components/dist/components/Icon/Icon';
import { Size } from 'cosmos-components/dist/types';
import { getScreenBreaks } from 'src/utils/htmlHelper';

export type OnClickSignature = (
    event:
        | React.MouseEvent<HTMLButtonElement, MouseEvent>
        | React.MouseEvent<HTMLAnchorElement, MouseEvent>,
) => void;

type ContainedModalProps = ComponentProps<typeof Modal> & {
    type?: 'icon' | 'button';
    position?: 'absolute' | 'fixed';
    iconName?: IconNameType;
    iconSize?: Size;
    getButtonComponent?: (onClick: OnClickSignature) => ReactNode;
    getDialogComponent?: (onClick: OnClickSignature) => ReactNode;
    dialogInfo?: string | ReactNode;
    closeLabel?: string;
    closable?: boolean;
    className?: string;
    hideClose?: boolean;
};

export const ContainedModal: FC<ContainedModalProps> = ({
    type = 'icon',
    position = 'absolute',
    iconName = 'info',
    iconSize = 'medium',
    getButtonComponent,
    getDialogComponent,
    dialogInfo,
    closeLabel = 'Close',
    closable = false,
    className,
    hideClose,
    ...props
}) => {
    const [visible, setVisible] = useState(false);
    const container = useRef(null);
    return (
        <Container
            ref={container}
            iconSize={iconSize}
            data-test-id="contained-modal"
            className={className}
            position={position}
        >
            {type === 'button' && !!getButtonComponent ? (
                getButtonComponent((e) => {
                    e.preventDefault();
                    setVisible(true);
                })
            ) : (
                <StyledIconButton
                    testId="contained-modal-icon"
                    cursor="pointer"
                    name={iconName}
                    size={iconSize}
                    onClick={(e) => {
                        e.preventDefault();
                        setVisible(true);
                    }}
                    color={visible ? 'secondaryOrange' : undefined}
                />
            )}
            <Modal
                data-test-id="modal"
                visible={visible}
                onClose={() => setVisible(false)}
                closable={closable}
                getContainer={container.current ?? undefined}
                maskStyle={{
                    backgroundColor: '#231f20 !important',
                    opacity: '50% !important',
                }}
                {...props}
            >
                {!!dialogInfo && <StyledPargraph>{dialogInfo}</StyledPargraph>}
                {!!getDialogComponent && (
                    <>
                        {getDialogComponent((event) => {
                            event.preventDefault();
                            setVisible(false);
                        })}
                    </>
                )}
                {!hideClose && (
                    <StyledPrimaryButton onClick={() => setVisible(false)}>
                        <StyledExitIcon name="close" size="medium" isInverted />
                        <Close>{closeLabel}</Close>
                    </StyledPrimaryButton>
                )}
            </Modal>
        </Container>
    );
};

const Container = styled.div<{
    iconSize: string;
    position: 'absolute' | 'fixed';
}>`
    position: relative;
    overflow: unset !important;
    width: fit-content !important;
    display: contents;
    .rc-dialog-root {
        .rc-dialog-wrap {
            position: ${({ position }) => position};
            top: ${({ theme, iconSize }) =>
                theme?.size?.[iconSize]?.height ?? '1rem'};
            overflow: unset;
            .rc-dialog {
                margin: ${({ position }) =>
                    position === 'absolute' ? 'unset' : 'auto'};
                width: 30.8rem;
                @media (min-width: ${(props) =>
                        getScreenBreaks(props).screenMDMin}) and (max-width: ${(
                        props,
                    ) => getScreenBreaks(props).screenMDMax}) {
                    width: 60.8rem;
                }

                @media (min-width: ${(props) =>
                        getScreenBreaks(props).screenLMin}) {
                    width: 72rem;
                }
                .rc-dialog-content {
                    min-height: 12.8rem;
                    border-top: ${({ theme: { colors } }) =>
                        `0.4rem solid ${colors.secondaryOrange}`};
                    color: ${({ theme }) => theme.colors.black};
                    background: ${({ theme }) => theme.colors.white};
                }
            }
            .rc-dialog-body {
                padding: 1.5rem;
            }
        }
    }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    position: relative;
    margin-left: auto;
    display: flex;
    opacity: 1;
    min-width: 5.6rem;
    align-items: center;
`;

const StyledPargraph = styled.p`
    margin: 0;
    text-align: left;
    letter-spacing: 0;
    color: ${({ theme: { colors } }) => colors.secondBlack};
    opacity: 1;
    padding: 0 0 1.5rem 0;
    font-weight: normal;
`;

const Close = styled.div`
    text-align: left;
    letter-spacing: 0;
    color: ${({ theme: { colors } }) => colors.white};
    text-transform: capitalize;
    opacity: 1;
`;

const StyledExitIcon = styled(Icon)`
    position: relative;
    margin-right: 0.5rem;
`;

const StyledIconButton = styled(IconButton)`
    background: unset;
    height: auto;
    position: relative;
    bottom: 0.6rem;
    padding-bottom: 2rem;
`;
