import { FC } from 'react';
import { useIntl } from 'react-intl';

import { UsBrandNames } from 'src/enums';
import globalMessages from 'src/messages';
import { phoneLink } from 'src/utils/intlHelper';

import { ContactWrapper, ContactItem, ColoredTrialMessage } from './Contact';

const ContactUS: FC<{ brandName: UsBrandNames }> = ({ brandName }) => {
    const intl = useIntl();
    const isRoadBear = brandName === UsBrandNames.roadBear;

    return (
        <ContactWrapper>
            <ContactItem>
                <ColoredTrialMessage>
                    <span>
                        {intl.formatMessage(
                            isRoadBear
                                ? globalMessages.phones.roadBearPhone
                                : globalMessages.phones.elmontePhone,
                            {
                                a: phoneLink,
                            },
                        )}
                    </span>
                </ColoredTrialMessage>
            </ContactItem>
        </ContactWrapper>
    );
};

export default ContactUS;
