import { useIntl } from 'react-intl';
import { FC } from 'react';

import { B2C } from 'cosmos-components';

import { useConfiguration } from 'src/hooks/useConfiguration';
import { useSciContent } from 'src/hooks/useSciContent';
import { Point } from 'src/types/Point';
import * as GlobalStyle from 'src/styles/global.style';

import msg from './shuttleService.messages';
import ShuttleServiceForm from './shuttleService.form';

export interface ShuttleServiceProps {
    control: any;
    shuttlePoints?: Point[];
    isShuttleSelected: boolean;
}
const ShuttleService: FC<ShuttleServiceProps> = ({
    control,
    shuttlePoints,
    isShuttleSelected,
}) => {
    const intl = useIntl();
    const configuration = useConfiguration();
    const otherDetailKey =
        configuration.functionalities.shuttleService.otherDetailKey;
    const {
        data: sciShuttleDescription,
        isLoading: sciShuttleDescriptionLoading,
    } = useSciContent(otherDetailKey);

    if (!shuttlePoints || shuttlePoints.length <= 0) {
        return <></>;
    }

    return (
        <B2C.LoaderContainer
            loading={sciShuttleDescriptionLoading}
            size={'small'}
        >
            <GlobalStyle.HeaderText>
                {intl.formatMessage(msg.headers.shuttle)}
            </GlobalStyle.HeaderText>
            <span
                dangerouslySetInnerHTML={{
                    __html: sciShuttleDescription ?? '',
                }}
            ></span>
            <ShuttleServiceForm
                control={control}
                shuttlePoints={shuttlePoints}
                isShuttleSelected={isShuttleSelected}
            />
        </B2C.LoaderContainer>
    );
};

export default ShuttleService;
