import { createRoutine } from 'redux-saga-routines';
import { wrapWithModule } from 'src/utils/redux';

import { SetRedirectionState } from './types';

const wrapWithNamespace = wrapWithModule('sagaReactRouter');
const setRedirectionPageAction = wrapWithNamespace('setRedirection');
export const setRedirection = createRoutine(setRedirectionPageAction);

type Types = typeof setRedirectionPageAction;

const initialState: SetRedirectionState = {
    redirection: null,
};

const setRedirectionReducer = (
    state = initialState,
    { type, payload }: { type: Types; payload: any } = {
        type: '',
        payload: {},
    },
): SetRedirectionState => {
    const handler = HANDLERS[type];
    return handler ? handler(state, payload) : state;
};

const handleSetRedirectionTrigger = (
    state: SetRedirectionState,
    payload: string | null,
) => ({
    ...state,
    redirection: payload,
});

const HANDLERS = {
    [setRedirection.TRIGGER]: handleSetRedirectionTrigger,
};

export default setRedirectionReducer;
