import styled from 'styled-components';
import { useIntl } from 'react-intl';

import globalMessages from 'src/messages';
import { phoneLink } from 'src/utils/intlHelper';

const Contact = () => {
    const intl = useIntl();

    return (
        <ContactWrapper>
            <ContactItem>
                <ContactItemHeader>
                    <span>
                        {intl.formatMessage(
                            globalMessages.phones.callFromAustralia,
                        )}
                    </span>
                </ContactItemHeader>
                <ColoredTrialMessage>
                    <span>
                        {intl.formatMessage(
                            globalMessages.phones.secondaryPhone,
                            {
                                a: phoneLink,
                            },
                        )}
                    </span>
                </ColoredTrialMessage>
            </ContactItem>
            <ContactItem>
                <ContactItemHeader>
                    <span>
                        {intl.formatMessage(
                            globalMessages.phones.callFromNewZealand,
                        )}
                    </span>
                </ContactItemHeader>
                <ColoredTrialMessage>
                    <span>
                        {intl.formatMessage(
                            globalMessages.phones.primaryPhone,
                            {
                                a: phoneLink,
                            },
                        )}
                    </span>
                </ColoredTrialMessage>
            </ContactItem>
        </ContactWrapper>
    );
};

export default Contact;

export const ContactItem = styled.div`
    text-align: center;
    width: 15rem;
    white-space: break-spaces;
    font-size: 1rem;
`;

export const ContactWrapper = styled.div`
    display: flex;
    font-size: 1.1rem;
    padding-top: 1.5rem;
    justify-content: space-around;
`;

export const ColoredTrialMessage = styled.div<{ isBold?: boolean }>`
    a {
        color: ${({ theme: { colors } }) => colors.secondaryRed};
    }
    font-size: 1.3rem;
    font-weight: bold;
`;

export const ContactItemHeader = styled.div`
    min-height: 4rem;

    span {
        font-size: 1.4rem;
        font-weight: bold;
        color: ${({ theme: { colors } }) => colors.secondaryBlack};
    }
`;
