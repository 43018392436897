import styled from 'styled-components';
import { PrimaryButton, FormField } from 'cosmos-components';

export const StyledFormField = styled(FormField)`
    margin-top: 1.5rem;
    margin-bottom: 0;
    flex: 1 1 0;
    label {
        margin-bottom: 0.5rem;
    }
`;

export const ActionWrapper = styled.div`
    width: 100%;
    text-align: center;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
    position: relative;
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    width: 100%;
`;

export const CustomerInfoForm = styled.form`
    margin: auto;
`;

export const Row = styled.div`
    display: flex;
`;

export const LoyaltyMembershipRow = styled(Row)`
    margin-bottom: 2rem;
`;

export const PassengersHeader = styled.h3`
    margin-top: 1.8rem;
    margin-bottom: 0;
    padding: 0.8rem 0rem;
    border-bottom: 2px solid ${({ theme: { colors } }) => colors.red};
`;
