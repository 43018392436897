import { defineMessages } from 'react-intl';

const headers = defineMessages({
    shuttle: {
        id: 'shuttleService.headersPrefix.shuttle',
        defaultMessage: 'Shuttle Service',
    },
    shuttleRequested: {
        id: 'shuttleService.headersPrefix.shuttleRequested',
        defaultMessage: 'Do you need shuttle service ?',
    },
});

const fields = defineMessages({
    shuttleRequested: {
        id: 'shuttleService.fields.shuttleRequested',
        defaultMessage: 'Yes',
    },
    shuttlePickupLocation: {
        id: 'shuttleService.fields.shuttlePickupLocation',
        defaultMessage: 'Shuttle Pickup Location',
    },
});

const shuttleServiceMessages = {
    headers,
    fields,
};

export default shuttleServiceMessages;
