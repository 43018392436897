import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Icon, Modal } from 'cosmos-components';

import {
    ElMonteEmailUrl,
    RoadBearEmailUrl,
    ThlWebChatUrl,
} from 'src/constants';
import { HttpStatusCodeEnum, UkBrandNames, UsBrandNames } from 'src/enums';
import { intlLink } from 'src/utils/intlHelper';
import { colors } from 'src/themes';

import { sciBrandNameSelector } from './SciPage.selector';
import * as S from './SciPage.style';
import msg from './sciPageMessages';
import SciPageContactPhoneUK from './ContactPhonesUK';
import SciPageContactPhoneUS from './ContactPhonesUS';
import SciPageContactPhone from './ContactPhones';

export interface SciPageErrorProps {
    errorType?: HttpStatusCodeEnum | null;
}

const SciPageErrorContentPhone: FC<{ isUK: boolean; isUS: boolean }> = ({
    isUK,
    isUS,
}) => (
    <S.StyledParagraph>
        {isUK ? (
            <SciPageContactPhoneUK />
        ) : isUS ? (
            <SciPageContactPhoneUS />
        ) : (
            <SciPageContactPhone />
        )}
    </S.StyledParagraph>
);

const SciPageErrorContent: FC<SciPageErrorProps> = ({ errorType }) => {
    const intl = useIntl();
    const backHome = useCallback(() => {
        window.location.href = '/';
    }, []);
    const brandName = useSelector(sciBrandNameSelector);
    const isRoadBear = brandName === UsBrandNames.roadBear;
    const isElmonte = brandName === UsBrandNames.elmonte;
    const isUK = brandName === UkBrandNames.europe;
    const isUS = isRoadBear || isElmonte;

    const showErrorWithLink = !isUK && !isUS;
    let contactLink = '';

    if (isRoadBear) {
        contactLink = RoadBearEmailUrl;
    } else if (isElmonte) {
        contactLink = ElMonteEmailUrl;
    } else {
        contactLink = ThlWebChatUrl;
    }

    switch (errorType) {
        case HttpStatusCodeEnum.conflict: {
            return (
                <>
                    <Icon
                        color="secondaryOrange"
                        size="large"
                        key="warning"
                        name="warning"
                    />
                    <S.StyledParagraph>
                        {showErrorWithLink
                            ? intl.formatMessage(
                                  msg.content.conflictErrorWithLink,
                                  {
                                      a: intlLink(contactLink),
                                  },
                              )
                            : intl.formatMessage(msg.content.conflictError)}
                    </S.StyledParagraph>
                    <SciPageErrorContentPhone isUK={isUK} isUS={isUS} />
                    <S.StyledPrimaryButton onClick={() => backHome()}>
                        {intl.formatMessage(msg.buttons.tryAgain)}
                    </S.StyledPrimaryButton>
                </>
            );
        }
        case HttpStatusCodeEnum.unAuthorized: {
            return (
                <>
                    <Icon
                        color="secondaryOrange"
                        size="large"
                        key="history"
                        name="history"
                    />
                    <S.StyledParagraph>
                        {intl.formatMessage(msg.content.expiredSession)}
                    </S.StyledParagraph>
                    <SciPageErrorContentPhone isUK={isUK} isUS={isUS} />
                    <S.StyledPrimaryButton onClick={() => backHome()}>
                        {intl.formatMessage(msg.buttons.back)}
                    </S.StyledPrimaryButton>
                </>
            );
        }
        default:
            break;
    }

    return (
        <>
            <Icon
                color="secondaryOrange"
                size="large"
                key="warning"
                name="warning"
            />
            <S.StyledParagraph>
                {showErrorWithLink
                    ? intl.formatMessage(msg.content.genericErrorWithLink, {
                          a: intlLink(contactLink),
                      })
                    : intl.formatMessage(msg.content.genericError)}
            </S.StyledParagraph>
            <SciPageErrorContentPhone isUK={isUK} isUS={isUS} />
            <S.StyledPrimaryButton onClick={() => backHome()}>
                {intl.formatMessage(msg.buttons.tryAgain)}
            </S.StyledPrimaryButton>
        </>
    );
};

export const SciPageError: FC<SciPageErrorProps> = ({ errorType }) => {
    return (
        <S.Container>
            <Modal
                data-test-id="sci-page-error-modal"
                visible
                closable={false}
                maskStyle={{
                    backgroundColor: `${colors.lightBlack} !important`,
                    opacity: '50% !important',
                }}
            >
                <SciPageErrorContent errorType={errorType} />
            </Modal>
        </S.Container>
    );
};
