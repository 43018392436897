import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { intlLink } from 'src/utils/intlHelper';
import { ThlWebChatUrl } from 'src/constants';
import { UkBrandNames, UsBrandNames } from 'src/enums';
import { sciBrandNameSelector } from 'src/components/shared/SciPage/SciPage.selector';

import { SCIApi } from '../../../apis';
import { PaymentIssuerResponse } from '../payment.type';
import { paymentIssuers } from '../payment.api';
import { backendError } from '../payment.messages';

const getPaymentIssuers = async () => {
    const apiCalls = SCIApi.getInstance();
    const result: AxiosResponse<PaymentIssuerResponse[]> = await apiCalls.get<
        PaymentIssuerResponse[]
    >(paymentIssuers);

    return result?.data ?? result;
};

export const usePaymentIssuersKey = 'paymentIssuers';

export const usePaymentIssuers = (
    onSuccess?: (data: PaymentIssuerResponse[]) => void,
) => {
    const intl = useIntl();
    const brandName = useSelector(sciBrandNameSelector);
    const displayLinkInError = ![
        ...Object.values(UkBrandNames),
        ...Object.values(UsBrandNames),
    ].includes(brandName as UkBrandNames | UsBrandNames);

    const genericErrorMessage = displayLinkInError
        ? intl.formatMessage(backendError.genericWithLink, {
              a: intlLink(ThlWebChatUrl),
          })
        : intl.formatMessage(backendError.generic);

    const [error, setError] = useState<React.ReactNode | string | undefined>(
        undefined,
    );

    const { isLoading, isError, data } = useQuery({
        queryKey: [usePaymentIssuersKey],
        queryFn: getPaymentIssuers,
        onSuccess: onSuccess,
        onError: () => setError(genericErrorMessage),
    });

    return {
        isLoading,
        isError,
        data,
        error,
    };
};
