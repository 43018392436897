import { createSelector } from 'reselect';

import { getModuleSelector } from 'src/utils/redux';
import { RetrieveBookingStateV1, SelfCheckIn } from './types';

const moduleSelector = getModuleSelector<RetrieveBookingStateV1>(
    'retrieveSelfCheckIn',
);

export const selfCheckInSelector = createSelector<any, SelfCheckIn>(
    moduleSelector,
    (retrieveSelfCheckIn: RetrieveBookingStateV1) => retrieveSelfCheckIn?.data,
);

export const isLoadingSelector = createSelector<any, boolean>(
    moduleSelector,
    (retrieveSelfCheckIn: RetrieveBookingStateV1) =>
        retrieveSelfCheckIn?.loading,
);

export const errorSelector = createSelector<any, any>(
    moduleSelector,
    (retrieveSelfCheckIn: RetrieveBookingStateV1) => retrieveSelfCheckIn?.error,
);
