import { FC } from 'react';
import { Select } from 'cosmos-components';
import { ValidationError } from 'src/components';
import { Controller } from 'react-hook-form';
import { StyledFormField } from '../index';
import styled, { css } from 'styled-components';

declare type Option = string | number | string[] | undefined;
export interface SelectProps {
    className?: string;
    name: string;
    control: any;
    defaultValue?: any;
    label: string;
    placeholder?: string;
    options: Option[];
    valueController?: (value: any) => any;
    onChange?: (selected: any, field: any) => void;
    isRequired?: boolean;
    errorMessage?: string | null;
    selectRestProps?: any;
    customWrapperStyles?: string;
    testId?: string;
}

const CustomSelect: FC<SelectProps> = ({
    className,
    name,
    control,
    defaultValue,
    label,
    placeholder,
    options,
    onChange,
    isRequired,
    errorMessage,
    selectRestProps,
    valueController,
    testId,
}) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field, fieldState: { error, invalid } }) => (
                <Wrapper className={className}>
                    <StyledFormField
                        isRequired={isRequired}
                        label={label}
                        name={name}
                    >
                        <StyledSelect
                            data-test-id={testId}
                            options={options}
                            placeholder={placeholder}
                            value={
                                valueController
                                    ? valueController(field.value)
                                    : field.value
                            }
                            onChange={(selected) => {
                                field.onChange(selected);
                                onChange && onChange(selected, field);
                            }}
                            isError={invalid}
                            {...selectRestProps}
                        />
                    </StyledFormField>
                    <ValidationError>
                        {errorMessage ? errorMessage : error?.message}
                    </ValidationError>
                </Wrapper>
            )}
        />
    );
};

const StyledSelect = styled(Select)<{ isError: boolean }>`
    ${({ isError }) =>
        isError &&
        css`
            border: 0.1rem solid ${({ theme: { colors } }) => colors?.red};
        `}
`;

export const Wrapper = styled.div`
    flex-basis: '100%';
    flex-grow: 1;
`;
const defaultProps: Partial<SelectProps> = {
    isRequired: true,
    errorMessage: null,
    selectRestProps: {},
};
CustomSelect.defaultProps = defaultProps;
export { CustomSelect };
