/* eslint-disable react/prop-types */
import { FC } from 'react';
import { Logo } from 'cosmos-components';
import styled from 'styled-components';

import heroBanner from 'src/assets/images/justGoHeroBanner.png';
import { useWindowDimensions } from 'src/hooks/useWindowDimensions';

import {
    StyledHeader,
    HeaderIcons,
    StyledHeroBanner,
    StyledDivider,
} from './Header';

export const UKHeader: FC = () => {
    const { deviceType } = useWindowDimensions();

    return (
        <StyledHeader>
            <HeaderIcons id="header-icons" deviceType={deviceType}>
                <UkHeaderIconContainer>
                    <ColoredJustGoLogo name="coloredApollo" />
                    <ColoredJustGoLogo name="coloredBunk" />
                    <ColoredJustGoLogo name="coloredJustGo" />
                </UkHeaderIconContainer>
            </HeaderIcons>
            <StyledHeroBanner src={heroBanner} />
            <StyledDivider />
        </StyledHeader>
    );
};

const UkHeaderIconContainer = styled.div`
    min-height: 3.9rem;
    padding-bottom: 0.5rem;
`;

export const ColoredJustGoLogo = styled(Logo)`
    width: 5.478rem;
    padding-right: 2.22rem;
`;
