import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Controller } from 'react-hook-form';

import { B2C, Logo } from 'cosmos-components';

import { ContainedModal } from 'src/components/shared/ContainedModal';
import { OnClickSignature } from 'src/components/shared/ContainedModal/ContainedModal';
import { colored } from 'src/utils/intlHelper';
import { colors } from 'src/themes';
import { useWindowDimensions } from 'src/hooks/useWindowDimensions';

import msg from './loyaltyMembership.messages';
import { LoyaltyProgram, LoyaltyCodeType } from './loyaltyMembership.types';
import { LoyaltyCodes } from './loyaltyMembership.constants';
import LoyaltyMembershipModal from './loyaltyMembership.modal';
import {
    useGetLoyaltyMembership,
    useDeleteLoyaltyMembership,
} from './loyaltyMembership.hooks';
import {
    Container,
    ContentContainer,
    LoaderContainer,
    LeftContentContainer,
    LogoContainer,
    AddRemoveButton,
    LoyaltyProgramContainer,
    LoyaltyProgramParagraph,
} from './loyaltyMembership.style';

export interface LoyaltyMembershipFormProps {
    control: any;
    loyaltyProgram: LoyaltyProgram;
    points: number;
    lastName: string;
    onLoyaltyMembershipChange: (loyaltyProgramMembershipCode?: string) => void;
}

const logoLoyaltyMapping = {
    [LoyaltyCodes.QFF]: <Logo name="qantasFrequentFlyer" />,
};

export const getLoyaltyLogo = (loyaltyCodeType: LoyaltyCodeType) => {
    return logoLoyaltyMapping[loyaltyCodeType];
};

const LoyaltyMembershipForm: FC<LoyaltyMembershipFormProps> = ({
    control,
    loyaltyProgram,
    points,
    lastName,
    onLoyaltyMembershipChange,
}) => {
    const intl = useIntl();
    const { deviceType } = useWindowDimensions();
    const { isLoading, data } = useGetLoyaltyMembership(loyaltyProgram);
    const { mutate: deleteLoyaltyMembership, isLoading: isDeleting } =
        useDeleteLoyaltyMembership();

    useEffect(() => {
        onLoyaltyMembershipChange(data?.membershipNumber);
    }, [data?.membershipNumber, onLoyaltyMembershipChange]);

    if (!loyaltyProgram) {
        return <></>;
    }

    if (isLoading) {
        return (
            <LoaderContainer>
                <B2C.LoaderSpinner position={'center'} />
            </LoaderContainer>
        );
    }

    return (
        <Container>
            <Controller
                name="loyaltyProgramMembershipCode"
                control={control}
                render={({ field }) => {
                    const existingMembership =
                        field.value && data?.id?.membershipId;

                    const middleSection = existingMembership ? (
                        <LoyaltyProgramContainer>
                            <LoyaltyProgramParagraph>
                                {loyaltyProgram.name}
                            </LoyaltyProgramParagraph>
                            <p>{field.value}</p>
                        </LoyaltyProgramContainer>
                    ) : (
                        <LoyaltyProgramContainer>
                            <p>
                                {intl.formatMessage(msg.globalText.add, {
                                    name: loyaltyProgram.name,
                                    points: points,
                                    displayName: loyaltyProgram.displayName,
                                    coloredRed: colored(colors.red ?? 'red'),
                                })}
                            </p>
                        </LoyaltyProgramContainer>
                    );

                    return (
                        <ContentContainer deviceType={deviceType}>
                            <LeftContentContainer deviceType={deviceType}>
                                <LogoContainer>
                                    {getLoyaltyLogo(
                                        loyaltyProgram.code as LoyaltyCodeType,
                                    )}
                                </LogoContainer>
                                {middleSection}
                            </LeftContentContainer>
                            {existingMembership ? (
                                <AddRemoveButton
                                    disabled={isDeleting}
                                    deviceType={deviceType}
                                    onClick={() =>
                                        !isDeleting &&
                                        deleteLoyaltyMembership({
                                            membershipId: data.id.membershipId,
                                        })
                                    }
                                >
                                    {intl.formatMessage(msg.button.remove)}
                                </AddRemoveButton>
                            ) : (
                                <ContainedModal
                                    type="button"
                                    position="fixed"
                                    getButtonComponent={(
                                        onClick: OnClickSignature,
                                    ) => (
                                        <AddRemoveButton
                                            deviceType={deviceType}
                                            disabled={!lastName}
                                            onClick={(...params) =>
                                                lastName && onClick(...params)
                                            }
                                        >
                                            {intl.formatMessage(msg.button.add)}
                                        </AddRemoveButton>
                                    )}
                                    getDialogComponent={(
                                        onClick: OnClickSignature,
                                    ) => (
                                        <LoyaltyMembershipModal
                                            loyaltyProgram={loyaltyProgram}
                                            points={points}
                                            lastName={lastName}
                                            onClose={onClick}
                                        />
                                    )}
                                    hideClose
                                    closable
                                />
                            )}
                        </ContentContainer>
                    );
                }}
            />
        </Container>
    );
};

export default LoyaltyMembershipForm;
