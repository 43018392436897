import { defineMessages } from 'react-intl';

export const carouselDotsMsgs = defineMessages({
    back: {
        id: 'carouselDots.back',
        defaultMessage: 'Back',
    },
    next: {
        id: 'carouselDots.next',
        defaultMessage: 'Next',
    },
});
