/* eslint-disable react/prop-types */
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { Logo, LanguagePicker } from 'cosmos-components';
import styled from 'styled-components';

import { languageOptions } from 'src/constants';
import { getScreenBreaks } from 'src/utils/htmlHelper';
import { getLangCode } from 'src/utils/string';
import heroBanner from 'src/assets/images/canaDreamHeroBanner.png';
import { useWindowDimensions } from 'src/hooks/useWindowDimensions';
import { sciLanguageSelector } from 'src/components/shared/SciPage/SciPage.selector';

import {
    StyledHeader,
    HeaderIcons,
    StyledHeroBanner,
    StyledDivider,
} from './Header';

export interface CAHeaderProps {
    hideBanner?: boolean;
}

export const CAHeader: FC<CAHeaderProps> = ({
    hideBanner,
}) => {
    const { deviceType } = useWindowDimensions();
    const [searchParams, setSearchParams] = useSearchParams();
    const language = getLangCode(useSelector(sciLanguageSelector));
    const initLanguage = languageOptions.find(
        (opt) =>
            opt?.label?.toLowerCase() === language,
    ) || languageOptions[0];

    return (
        <StyledHeader>
            <HeaderIcons id="header-icons" deviceType={deviceType}>
                <LanguagePickerContainer key={language}>
                    <LanguagePicker
                        className="LanguagePicker"
                        testId="LanguagePicker"
                        initialValue={initLanguage}
                        options={languageOptions}
                        onOptionSelect={(selected) => {
                            searchParams.set('lng', selected?.label?.toLowerCase());
                            setSearchParams(searchParams);
                        }}
                    />
                </LanguagePickerContainer>
                <CaHeaderIconContainer>
                    <ColoredLogo name="coloredCanadream" />
                </CaHeaderIconContainer>
            </HeaderIcons>
            {!hideBanner && <StyledHeroBanner src={heroBanner} />}
            {!hideBanner && <StyledDivider />}
        </StyledHeader>
    );
};

const LanguagePickerContainer = styled.div`
    width: 100%;

    div.dropdown-options {
        @media (max-width: ${(props) => getScreenBreaks(props).screenMDMin}) {
            float: left;
            width: 9rem;
        }
    }

    input {
        min-width: 18px;
        min-width: 1.8rem;
    }
`;

const CaHeaderIconContainer = styled.div`
    min-height: 3.9rem;
    padding-bottom: 0.5rem;
`;

export const ColoredLogo = styled(Logo)`
    width: 8.9rem;
    padding-right: 2.22rem;
`;
