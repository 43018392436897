import * as R from 'ramda';
import { createSelector } from 'reselect';
import { favoriteCountriesWithCodes } from 'src/constants';
import { AxiosErrorWithSubmessage } from 'src/types/AxiosErrorWithSubmessage';
import { getModuleSelector } from 'src/utils/redux';
import {
    Product,
    RetrieveProductInfoState,
} from 'src/modules/addProducts/types';
import { selfCheckInSelector } from '../retrieveSelfCheckIn/retrieveSelfCheckIn.selector';
import { SelfCheckIn } from '../retrieveSelfCheckIn/types';
import {
    CustomerInfo,
    Countries,
    RetrieveCountriesState,
    RetrieveOrganizationSettingsState,
    UpdateCustomerInfoState,
    ShuttleInfo,
} from './types';

const updateModuleSelector = getModuleSelector<
    UpdateCustomerInfoState & ShuttleInfo
>('updateCustomerInfo');
const retrieveModuleSelector = getModuleSelector('retrieveCustomerInfo');
const retrieveCountriesSelector =
    getModuleSelector<RetrieveCountriesState>('retrieveCountries');
const retrieveOrganizationSettings =
    getModuleSelector<RetrieveOrganizationSettingsState>(
        'retrieveOrganizationSettings',
    );
const retrievePrimaryProductSelector =
    getModuleSelector<RetrieveProductInfoState>('retrievePrimaryProduct');

export const updateIsLoadingSelector = createSelector<any, boolean>(
    updateModuleSelector,
    R.prop<string, boolean>('loading'),
);

export const retrieveIsLoadingSelector = createSelector<any, boolean>(
    retrieveModuleSelector,
    R.prop<string, boolean>('loading'),
);

export const countriesIsLoadingSelector = createSelector<any, boolean>(
    retrieveCountriesSelector,
    R.prop<string, boolean>('loading'),
);

export const customerInfoSelector = createSelector<
    any,
    CustomerInfo & ShuttleInfo
>(
    retrieveModuleSelector,
    (customerInfo: UpdateCustomerInfoState) => customerInfo.data,
);

export const countriesSelector = createSelector<any, string[]>(
    retrieveCountriesSelector,
    (countries: RetrieveCountriesState) => countries.countryOptions,
);

export const defaultOrganizationCountryCodeSelector = createSelector<
    any,
    string
>(
    retrieveOrganizationSettings,
    (organizationSettings: RetrieveOrganizationSettingsState) =>
        organizationSettings.data.phoneNumbers.defaultCountryIso2Code,
);

export const countrySelector = createSelector<
    any,
    { name: string; code: string }
>(
    defaultOrganizationCountryCodeSelector,
    (organizationCountryCode: string) =>
        favoriteCountriesWithCodes.filter((item) => {
            return item.code === organizationCountryCode;
        })?.[0],
);

export const countriesObjectSelector = createSelector<any, Countries>(
    retrieveCountriesSelector,
    (countries: RetrieveCountriesState) => countries.data,
);

export const primaryProductIsLoadingSelector = createSelector<any, boolean>(
    retrievePrimaryProductSelector,
    R.prop<string, boolean>('loading'),
);
export const primaryProductSelector = createSelector<any, Product>(
    retrievePrimaryProductSelector,
    (primaryProduct: RetrieveProductInfoState) => primaryProduct.data,
);

export const updateErrorSelector = createSelector<
    any,
    AxiosErrorWithSubmessage | undefined
>(
    updateModuleSelector,
    R.prop<string, AxiosErrorWithSubmessage | undefined>('error'),
);

export const driversCountSelector = createSelector<any, number>(
    customerInfoSelector,
    selfCheckInSelector,
    (customerInfo: CustomerInfo, selfCheckIn: SelfCheckIn) => {
        if (!customerInfo.isDriver) {
            return selfCheckIn.rental?.driversCount + 1;
        } else {
            return selfCheckIn.rental?.driversCount;
        }
    },
);
