import { FC } from 'react';
import { Checkbox } from 'cosmos-components';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';

import { ValidationError } from 'src/components';

type LabelPosition = 'before' | 'after';

interface LabelProps {
    label?: string;
    labelHtml?: string;
}

export interface CheckboxProps extends LabelProps {
    className?: string;
    name: string;
    control: any;
    defaultValue?: any;
    checkboxRestProps?: any;
    disabled?: boolean;
    checked?: boolean;
    testId?: string;
    labelPosition?: LabelPosition;
}

const CustomCheckboxLabel: FC<LabelProps> = ({ label, labelHtml }) =>
    label ? (
        <span>{label}</span>
    ) : labelHtml ? (
        <span dangerouslySetInnerHTML={{ __html: labelHtml }} />
    ) : (
        <></>
    );

export const CustomCheckbox: FC<CheckboxProps> = ({
    className,
    name,
    control,
    defaultValue,
    label,
    labelHtml,
    labelPosition = 'before',
    disabled,
    checked,
    testId,
    checkboxRestProps = {},
}) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field, fieldState: { error } }) => (
                <Wrapper>
                    <CheckboxWrapper className={className}>
                        {labelPosition === 'before' && (
                            <CustomCheckboxLabel
                                label={label}
                                labelHtml={labelHtml}
                            />
                        )}
                        <StyledCheckbox
                            testId={testId}
                            checked={
                                disabled ? field.value || checked : field.value
                            }
                            disabled={disabled}
                            name={field.name}
                            handler={field.onChange}
                            labelPosition={labelPosition}
                            {...checkboxRestProps}
                        />
                        {labelPosition === 'after' && (
                            <CustomCheckboxLabel
                                label={label}
                                labelHtml={labelHtml}
                            />
                        )}
                    </CheckboxWrapper>
                    <ValidationError data-test-id={testId?.concat('-error')}>
                        {error?.message}
                    </ValidationError>
                </Wrapper>
            )}
        />
    );
};

export const StyledCheckbox = styled(Checkbox)<{
    labelPosition: LabelPosition;
}>`
    ${({ labelPosition }) =>
        labelPosition === 'before'
            ? 'margin-left: 2rem'
            : 'margin-right: 2rem'};
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CheckboxWrapper = styled.div`
    display: flex;
    margin-top: 1.9rem;
    font-size: 1.4rem;
    color: ${({ theme: { colors } }) => colors.black};
`;
