import { getApiBaseUrl } from '../../../utils/configStore';
const baseURL = getApiBaseUrl();

const orgId = '478242c4-3cbe-48af-9180-1e330e0b83d3';
const nullOrg = '00000000-0000-0000-0000-000000000000';
const createdBy = 'b3ba6304-f70d-423f-acfe-6101b2467d30';
const createdByName = 'System Admin';
const createdByDate = '2021-11-08T22:12:32.533';
export const MOCK_RES = {
    GET_paymentIssuers: [
        {
            code: 'Visa',
            name: 'Visa',
            id: {
                organizationId: orgId,
                cardIssuerId: '4cf4c971-1f2d-4f40-b351-391312530899',
            },
            _meta: {
                schema: 1,
                created: {
                    by: {
                        id: createdBy,
                        organizationId: nullOrg,
                        name: createdByName,
                    },
                    date: createdByDate,
                },
                version: 637720063515769984,
                versionString: '637720063515769984',
                enforceUniqueId: false,
                public: false,
            },
            rulesData: {},
            attributes: {},
            identifiers: {},
        },
        {
            code: 'MasterCard',
            name: 'Master Card',
            id: {
                organizationId: orgId,
                cardIssuerId: '2c99dcf3-b506-44bf-ace0-11cde21100ee',
            },
            _meta: {
                schema: 1,
                created: {
                    by: {
                        id: createdBy,
                        organizationId: nullOrg,
                        name: createdByName,
                    },
                    date: createdByDate,
                },
                version: 637720063515769984,
                versionString: '637720063515769984',
                enforceUniqueId: false,
                public: false,
            },
            rulesData: {},
            attributes: {},
            identifiers: {},
        },
        {
            code: 'Amex',
            name: 'American Express',
            id: {
                organizationId: orgId,
                cardIssuerId: 'b82d6349-0325-4946-8776-9461005488d2',
            },
            _meta: {
                schema: 1,
                created: {
                    by: {
                        id: createdBy,
                        organizationId: nullOrg,
                        name: createdByName,
                    },
                    date: createdByDate,
                },
                version: 637720063515769984,
                versionString: '637720063515769984',
                enforceUniqueId: false,
                public: false,
            },
            rulesData: {},
            attributes: {},
            identifiers: {},
        },
    ],
    POST_paymentSurcharge: {
        amount: 107.136,
        totalAmount: 6803.136,
        currency: 'AUD',
    },
    GET_payments: {
        paymentId: '302f6175-2569-4df7-869d-d777f48fa6a0',
        ajaxPost: `${baseURL}/simulateWindCaveAjaxPost`,
    },
};
