import * as React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

export class QueryClientAccessor {
    private static _instance: QueryClient;

    public static setInstance(queryClient: QueryClient) {
        this._instance = queryClient;
    }

    public static getInstance(): QueryClient {
        return this._instance;
    }
}

export const QueryClientReduxCompatible = ({
    client,
    children,
}: {
    client: QueryClient;
    children: React.ReactNode;
}) => {
    QueryClientAccessor.setInstance(client);

    return (
        <QueryClientProvider client={client}>{children}</QueryClientProvider>
    );
};
