import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { carouselDotsSelector } from 'src/components/shared/SciPage/SciPage.selector';
import { SciPagesEnum } from 'src/components/shared/SciPage/types';

export const usePageAccessibility = () => {
    const carouselDots = useSelector(carouselDotsSelector);

    const validatePageAccessibility = useCallback(
        (page: SciPagesEnum) => {
            const carouselDotsItems = carouselDots?.items;

            if (!carouselDotsItems) {
                return {
                    canAccess: true,
                    redirectUrl: '',
                };
            }

            const flowCompleted = carouselDots?.flowCompleted;
            const pageIsInFlow =
                carouselDotsItems.findIndex((item) => item.pageEnum === page) >=
                    0 || page === SciPagesEnum.RegistrationComplete;

            if (
                (flowCompleted &&
                    ![
                        SciPagesEnum.RetrieveSelfCheckInPage,
                        SciPagesEnum.RegistrationComplete,
                    ].includes(page)) ||
                !pageIsInFlow
            ) {
                return {
                    canAccess: false,
                    redirectUrl:
                        carouselDotsItems[SciPagesEnum.RetrieveSelfCheckInPage]
                            .path,
                };
            }

            for (const carouselDotItem of carouselDotsItems) {
                if (!carouselDotItem || carouselDotItem.pageEnum === page) {
                    break;
                }

                if (!carouselDotItem.isCompleted) {
                    return {
                        canAccess: false,
                        redirectUrl: carouselDotItem.path,
                    };
                }
            }

            return {
                canAccess: true,
                redirectUrl: '',
            };
        },
        [carouselDots],
    );

    return {
        validatePageAccessibility,
    };
};
