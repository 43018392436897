import * as R from 'ramda';
import { createSelector, OutputSelector } from 'reselect';
import {
    applyMiddleware,
    compose,
    legacy_createStore as createStore,
    Reducer,
    Store,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer, Persistor } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// This need to be implemented in every reducer
export const RESET_APP_ACTION = 'RESET_APP';

// Follow the standard of ducks-redux-reducer:
// https://github.com/erikras/ducks-modular-redux
const getActionWrapper = (
    appName: string,
    moduleName: string,
    nameSpaceName: string,
) => `${[appName, moduleName, nameSpaceName].join('/')}`;

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['sciPageInfo'],
};

export const wrapWithModule = R.curry(getActionWrapper)('sci-app');

export const getModuleSelector = <T = any>(
    key: string,
): OutputSelector<any, T, (...args: any) => T> =>
    createSelector(
        (state: any) => state[key],
        (module: T) => module,
    );

export const configureStore = (options: {
    rootReducer: Reducer<any, any>;
    rootSaga?: any;
    initialState?: any;
}): { store: Store; persistor: Persistor } => {
    const {
        customMiddlewares,
        initialState,
        isSupportDevtool,
        rootReducer,
        rootSaga,
    } = R.mergeDeepLeft(
        {
            initialState: (window as any).INITIAL_STATE || {},
            customMiddlewares: [],
            isSupportDevtool: true,
        },
        options,
    );

    // ref: https://github.com/zalmoxisus/redux-devtools-extension#12-advanced-store-setup
    const composeEnhancers = isSupportDevtool
        ? R.prop('__REDUX_DEVTOOLS_EXTENSION_COMPOSE__', window as any) ||
          compose
        : compose;

    let sagaMiddleware;
    const middlewares: any[] = [];

    if (rootSaga) {
        sagaMiddleware = createSagaMiddleware();
        middlewares.push(sagaMiddleware);
    }

    const middleware = composeEnhancers(
        applyMiddleware(...middlewares.concat(customMiddlewares)),
    );
    const persistedReducer = persistReducer(persistConfig, rootReducer);
    const store = createStore(persistedReducer, initialState, middleware);
    const persistor = persistStore(store);

    if (rootSaga) {
        sagaMiddleware?.run(rootSaga);
    }

    return { store, persistor };
};

export const setSingleState =
    <S = any, P = any, V = any>(key: string | string[], value?: V) =>
    (state: S, payload: P) => {
        if (typeof key === 'string') {
            return {
                ...state,
                [key]: value || payload,
            };
        } else {
            return R.set(R.lensPath(key), value || payload, state);
        }
    };

export const genApiSchemaState = <T>(options = {} as any) =>
    R.mergeDeepRight(
        {
            data: {} as T,
            loading: false,
            error: {},
        },
        options,
    );
