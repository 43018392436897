import { createRoutine } from 'redux-saga-routines';
import { Action } from 'redux-actions';

import {
    genApiSchemaState,
    setSingleState,
    wrapWithModule,
    RESET_APP_ACTION,
} from 'src/utils/redux';

import { Product, RetrieveProductInfoState } from '../addProducts/types';

const wrapWithNamespace = wrapWithModule('modules/retrievePrimaryProduct');
export const retrievePrimaryProductInfoAction = createRoutine(
    wrapWithNamespace('retrieve'),
);
const defaultProductInfo: Product = {
    id: {
        productId: '',
        organizationId: '',
    },
    code: '',
    maxPassengers: 0,
    name: '',
    defaultImageUrl: '',
    maxAdults: 0,
    maxChildren: 0,
};
const updateInitialState: RetrieveProductInfoState =
    genApiSchemaState<RetrieveProductInfoState>({
        data: defaultProductInfo,
    });

const retrieveProductInfoReducer = (
    state = updateInitialState,
    { type, payload }: Action<Product>,
): RetrieveProductInfoState => {
    const handler = HANDLERS[type];
    return handler ? handler(state, payload) : state;
};

const handleTrigger = setSingleState('loading', true);

const handleSuccess = (state: RetrieveProductInfoState, payload: any) => ({
    ...state,
    error: {},
    loading: false,
    data: { ...state.data, ...payload },
});

const handleError = (_: RetrieveProductInfoState, payload: any) => ({
    ...updateInitialState,
    error: payload,
});

const HANDLERS = {
    [retrievePrimaryProductInfoAction.TRIGGER]: handleTrigger,
    [retrievePrimaryProductInfoAction.SUCCESS]: handleSuccess,
    [retrievePrimaryProductInfoAction.FAILURE]: handleError,
    [RESET_APP_ACTION]: () => updateInitialState,
};

export default retrieveProductInfoReducer;
