import { createRoutine } from 'redux-saga-routines';
import { Action } from 'redux-actions';

import {
    genApiSchemaState,
    setSingleState,
    wrapWithModule,
    RESET_APP_ACTION,
} from 'src/utils/redux';

import { RetrieveStatesState, States } from '../types';
import { ServerError } from '../../types';

const wrapWithNamespace = wrapWithModule('modules/retrieveStates');
export const retrieveStatesAction = createRoutine(
    wrapWithNamespace('retrieve'),
);

export const defaultState: States = [
    { code: '', name: '', id: { organizationId: '', pointId: '' } },
];
const updateInitialState: RetrieveStatesState =
    genApiSchemaState<RetrieveStatesState>({
        data: null,
    });

const retrieveStatesReducer = (
    state = updateInitialState,
    { type, payload }: Action<States>,
): RetrieveStatesState => {
    const handler = HANDLERS[type];
    return handler ? handler(state, payload) : state;
};

const handleTrigger = setSingleState('loading', true);

const handleSuccess = (state: RetrieveStatesState, payload: States) => ({
    ...state,
    error: {},
    loading: false,
    data: payload,
});

const handleError = (_: RetrieveStatesState, payload: ServerError) => ({
    ...updateInitialState,
    error: payload,
});

const HANDLERS = {
    [retrieveStatesAction.TRIGGER]: handleTrigger,
    [retrieveStatesAction.SUCCESS]: handleSuccess,
    [retrieveStatesAction.FAILURE]: handleError,
    [RESET_APP_ACTION]: () => updateInitialState,
};

export default retrieveStatesReducer;
