import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useIntl } from 'react-intl';

import { SCIApi } from 'src/apis';

import { memberships } from './loyaltyMembership.api';
import {
    LoyaltyProgram,
    LoyaltyProgramProfileMembership,
    AddLoyaltyProgramsMembershipPayload,
    DeleteLoyaltyProgramsMembershipPayload,
} from './loyaltyMembership.types';
import messages from './loyaltyMembership.messages';

const getMemberships = async () => {
    const apiCalls = SCIApi.getInstance();
    const result: AxiosResponse<LoyaltyProgramProfileMembership[]> =
        await apiCalls.get(memberships);

    return result?.data ?? result;
};

const postMembership = async (payload: AddLoyaltyProgramsMembershipPayload) => {
    const apiCalls = SCIApi.getInstance();
    const result: AxiosResponse<LoyaltyProgramProfileMembership> =
        await apiCalls.post<LoyaltyProgramProfileMembership>(
            memberships,
            payload,
        );

    return result?.data ?? result;
};

const deleteMembership = async (
    payload: DeleteLoyaltyProgramsMembershipPayload,
) => {
    const apiCalls = SCIApi.getInstance();
    const result: AxiosResponse<LoyaltyProgramProfileMembership> =
        await apiCalls.delete<LoyaltyProgramProfileMembership>(
            `${memberships}/${payload.membershipId}`,
        );

    return result?.data ?? result;
};

export const useGetLoyaltyMembershipKey = 'useGetLoyaltyMembershipKey';
export const useGetLoyaltyMembership = (loyaltyProgram: LoyaltyProgram) => {
    const { isLoading, isError, data, error } = useQuery({
        queryKey: [useGetLoyaltyMembershipKey],
        queryFn: getMemberships,
    });

    return {
        data: data?.find(
            (loyaltyProgramProfileMembership) =>
                loyaltyProgramProfileMembership.loyaltyProgram.code ===
                loyaltyProgram.code,
        ),
        isLoading,
        isError,
        error,
    };
};

export const usePostLoyaltyMembership = () => {
    const intl = useIntl();
    const queryClient = useQueryClient();

    const [error, setError] = useState<string | undefined>(undefined);

    const { mutate, isLoading, isError } = useMutation(postMembership, {
        onError: () =>
            setError(intl.formatMessage(messages.errors.incorectAssociation)),
        onSuccess: () =>
            queryClient.invalidateQueries([useGetLoyaltyMembershipKey]),
    });

    return {
        mutate,
        isLoading,
        isError,
        error,
    };
};

export const useDeleteLoyaltyMembership = () => {
    const queryClient = useQueryClient();

    const { mutate, isLoading, isError } = useMutation(deleteMembership, {
        onSuccess: () =>
            queryClient.invalidateQueries([useGetLoyaltyMembershipKey]),
    });

    return {
        mutate,
        isLoading,
        isError,
    };
};
