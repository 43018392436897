import { rest } from 'msw';

import { getApiBaseUrl } from 'src/utils/configStore';

import { productsApiRoute } from '../products.api.hooks';

import { MOCK_RES } from './products.api.mock.responses';

const baseURL = getApiBaseUrl();
const delayDuration = 1500;

const productsApiMocks = [
    rest.get(`${baseURL}${productsApiRoute}`, async (req, res, ctx) => {
        return res(
            ctx.delay(delayDuration),
            ctx.status(200),
            ctx.json(MOCK_RES),
        );
    }),
];

export default productsApiMocks;
