import { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { currentRedirection as currentRedirectionSelector } from './SagaReactRouterMiddleware.selector';
import { setRedirection } from './SagaReactRouterMiddleware.duck';

export interface SagaReactRouterMiddlewareProps {
    children: React.ReactNode;
}

export const SagaReactRouterMiddlewareComponent: FC<SagaReactRouterMiddlewareProps> =
    ({ children }) => {
        const dispatch = useDispatch();
        const location = useLocation();

        const currentPathName = location?.pathname;
        const currentRedirection = useSelector(currentRedirectionSelector);
        const currentRedirectionPathName = currentRedirection
            ? currentRedirection?.split('?')?.[0]
            : null;

        if (
            currentRedirection !== null &&
            currentPathName !== currentRedirectionPathName
        ) {
            return <Navigate to={currentRedirection} />;
        }
        if (currentRedirectionPathName === currentPathName) {
            dispatch(setRedirection(null));
        }

        return <>{children}</>;
    };
