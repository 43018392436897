import { defineMessages } from 'react-intl';

const phones = defineMessages({
    callFromAustralia: {
        id: 'global.phones.callFromAustralia',
        defaultMessage: 'Call from Australia',
    },
    callFromNewZealand: {
        id: 'global.phones.callFromNewZealand',
        defaultMessage: 'Call from New Zealand',
    },
    callFromUK: {
        id: 'global.phones.callFromUK',
        defaultMessage: 'Call from UK',
    },
    callFromOutsideUK: {
        id: 'global.phones.callFromOutsideUK',
        defaultMessage: 'Call from outside the UK',
    },
    callFromCanadaOrAustralia: {
        id: 'global.phones.callFromCanadaOrAustralia',
        defaultMessage: 'Call from Australia or Canada',
    },
    callFromUs: {
        id: 'global.phones.callFromUs',
        defaultMessage: 'Call from the USA.',
    },
    canaDreamTollFreePhone: {
        id: 'global.phones.canaDreamTollFreePhone',
        defaultMessage: 'Toll Free',
    },
    canaDreamTollFreePhoneNumber: {
        id: 'global.phones.canaDreamTollFreePhoneNumber',
        defaultMessage: '<a>+1-800-347-7126</a>',
    },
    canaDreamInternationalPhone: {
        id: 'global.phones.canaDreamInternationalPhone',
        defaultMessage: 'International',
    },
    canaDreamInternationalPhoneNumber: {
        id: 'global.phones.canaDreamInternationalPhoneNumber',
        defaultMessage: '<a>+1-403-259-5447</a>',
    },
    phone: {
        id: 'global.phones.phone',
        defaultMessage: 'Phone',
    },
    primaryPhone: {
        id: 'global.phones.primaryPhone',
        defaultMessage: '<a>0800 144188</a>',
    },
    secondaryPhone: {
        id: 'global.phones.secondaryPhone',
        defaultMessage: '<a>1800 943 380</a>',
    },
    roadBearPhone: {
        id: 'global.phones.roadBearPhone',
        defaultMessage: '<a>+1-866-491-9853</a>',
    },
    elmontePhone: {
        id: 'global.phones.elmontePhone',
        defaultMessage: '<a>+1-888-337-2214</a>',
    },
    callFromUkPhone: {
        id: 'global.phones.callFromUkPhone',
        defaultMessage: '<a>01525 878000</a>',
    },
    callFromOutsideUkPhone: {
        id: 'global.phones.callFromOutsideUkPhone',
        defaultMessage: '<a>0044 1525 878 000</a>',
    },
    callFromCanadaOrAustraliaPhone: {
        id: 'global.phones.callFromCanadaOrAustraliaPhone',
        defaultMessage: '<a>(001144) 1525 878 000</a>',
    },
});

export default phones;
