import { combineReducers } from 'redux';
import retrieveDriver from './ducks/retrieveDriver.duck';
import updateDriver from './ducks/updateDriver.duck';
import retrieveStates from './ducks/retrieveStates.duck';

export default combineReducers({
    retrieveDriver,
    updateDriver,
    retrieveStates,
});
