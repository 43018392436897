import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import { B2CInterface } from 'cosmos-components';
import { sciLanguageSelector } from 'src/components/shared/SciPage/SciPage.selector';
import { getLangCode } from 'src/utils/string';
import { primaryProductSelector } from '../modules/updateCustomerInfo/updateCustomerInfo.selector';
import { SCIApi, selfCheckInRoute } from '../apis';


const bookingSummaryRoute = `${selfCheckInRoute}/rentals/summary`;

const getBookingSummary = async (language: string) => {
    const apiCalls = SCIApi.getInstance();
    apiCalls.configureLanguage(language);
    const result: AxiosResponse<B2CInterface.B2CBookingSummaryInterface> =
        await apiCalls.get<B2CInterface.B2CBookingSummaryInterface>(
            bookingSummaryRoute,
        );

    return result?.data ?? result;
};

export const useBookingSummaryKey = 'bookingSummary';

export const useBookingSummary = () => {
    const language = getLangCode(useSelector(sciLanguageSelector));
    const primaryProduct = useSelector(primaryProductSelector);
    const { isLoading, isFetching, isError, data, error, refetch } = useQuery({
        queryKey: [useBookingSummaryKey],
        queryFn: () => getBookingSummary(language),
    });

    useEffect(() => {
        refetch();
    }, [language, refetch]);

    return {
        isLoading: isLoading || isFetching,
        isError,
        data,
        error,
        primaryProduct,
    };
};
