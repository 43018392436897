import { createRoutine } from 'redux-saga-routines';
import { Action } from 'redux-actions';

import {
    genApiSchemaState,
    setSingleState,
    wrapWithModule,
} from 'src/utils/redux';
import {
    SelfCheckIn,
    RetrieveBookingStateV1,
    RetrieveSelfCheckInPayload,
} from './types';

const wrapWithNamespace = wrapWithModule('modules/retrieveSelfCheckIn');

export const retrieveSelfCheckInAction = createRoutine(
    wrapWithNamespace('retrieve'),
);
export const retrieveSelfCheckInActionByMagicTokenAction = createRoutine(
    wrapWithNamespace('retrieveByMagicToken'),
);

const initialState: RetrieveBookingStateV1 = genApiSchemaState<SelfCheckIn>();

const retrieveSelfCheckInReducer = (
    state = initialState,
    { type, payload }: Action<RetrieveSelfCheckInPayload & SelfCheckIn>,
): RetrieveBookingStateV1 => {
    const handler = HANDLERS[type];
    return handler ? handler(state, payload) : state;
};

const handleTrigger = setSingleState('loading', true);

const handleSuccess = (
    state: RetrieveBookingStateV1,
    payload: SelfCheckIn,
) => ({
    ...state,
    error: {},
    loading: false,
    data: { ...state.data, ...payload },
});

const handleError = (_state: RetrieveBookingStateV1, payload: any) => ({
    ...initialState,
    error: payload,
});

const HANDLERS = {
    [retrieveSelfCheckInAction.TRIGGER]: handleTrigger,
    [retrieveSelfCheckInAction.SUCCESS]: handleSuccess,
    [retrieveSelfCheckInAction.FAILURE]: handleError,
    [retrieveSelfCheckInActionByMagicTokenAction.TRIGGER]: handleTrigger,
    RESET_APP: () => initialState,
};

export default retrieveSelfCheckInReducer;
