import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { SciPage } from 'src/components/shared/SciPage';
import { setCarouselDotsCurrentPage } from 'src/components/shared/SciPage/SciPage.duck';
import { SciPagesEnum } from 'src/components/shared/SciPage/types';
import { sciContentKeys } from 'src/constants';
import { SciContent } from 'src/components/shared/SciContent';

import { UpdateCustomerInfoForm } from './updateCustomerInfo.form';
import msg from './updateCustomerInfoMessages';
import * as S from './updateCustomerInfo.component.style';

const MODULE_PREFIX = 'updateCustomerInfo';

export const componentUpdateCustomerInfo = `${MODULE_PREFIX}-component`;
export const UpdateCustomerInfoComponent: FC = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setCarouselDotsCurrentPage(SciPagesEnum.UpdateCustomerPage));
    }, [dispatch]);

    return (
        <SciPage data-test-id={componentUpdateCustomerInfo}>
            <S.Header>
                <S.CustomerDetails>
                    <S.CustomerDetailsLabel>
                        {intl.formatMessage(
                            msg.formHeaderItems.customerDetails,
                        )}
                    </S.CustomerDetailsLabel>
                    <S.StyleContainedModal
                        dialogInfo={
                            <SciContent
                                sciContentKey={
                                    sciContentKeys.sciInfoBoxCustomerDetails
                                }
                            />
                        }
                    />
                </S.CustomerDetails>
                <S.ConfirmMessage>
                    <SciContent
                        sciContentKey={sciContentKeys.sciCustomerDetailsPage}
                    />
                </S.ConfirmMessage>
            </S.Header>
            <UpdateCustomerInfoForm />
        </SciPage>
    );
};
