import { Point } from '../retrieveSelfCheckIn/types';

export const formatShuttleKey = (shuttle: Point) => {
    if (!shuttle) {
        return '';
    }

    const physicalAddress =
        shuttle?.address ??
        shuttle?.addresses?.find((a) => a?.type === 'Physical');

    let shuttleKey = shuttle?.name;

    if (physicalAddress?.line1) {
        shuttleKey += `, ${physicalAddress?.line1}`;
    }

    if (physicalAddress?.city) {
        shuttleKey += `, ${physicalAddress?.city}`;
    }

    return shuttleKey;
};
