import { createRoot } from 'react-dom/client';
import App from './App';
import { SCIApi } from 'src/apis';
import { storeConfigs } from './utils/configStore';

const { newrelic } = window;
const API = SCIApi.getInstance();

fetch('/config.json')
    .then((response) => {
        return response.clone().json();
    })
    .then((configs) => {
        storeConfigs(configs);
        API.changeBaseURL();
        // Setup NewRelic
        const NODE_ENV = configs.NODE_ENV;
        if (newrelic) {
            if (NODE_ENV) {
                newrelic.setCustomAttribute('env', NODE_ENV);
            }
        }
    })
    .then(() => {
        try {
            const container = document.getElementById('root');
            if (container) {
                const root = createRoot(container);

                root.render(<App />);
            } else {
                throw new Error('root not found');
            }
        } catch (err) {
            if (newrelic) {
                newrelic.noticeError(err);
            }
        }
    });
