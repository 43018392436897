import { all, put, takeLatest } from 'redux-saga/effects';

import { SCIApi } from 'src/apis';

import { ProfileIdentifier } from '../updateCustomerInfo/types';
import { BatchResponse } from '../types';

import * as api from './addDriver.api';
import {
    BatchDriverItem,
    Driver,
    GetDriversPayload,
    PostDriverPayload,
    States,
} from './types';
import { retrieveDriversAction } from './ducks/retrieveDriver.duck';
import { updateDriverAction } from './ducks/updateDriver.duck';
import { BatchResponseStatusEnum } from '../enums';
import { setCarouselPageIsCompletedAction } from 'src/components/shared/SciPage/SciPage.duck';
import { SciPagesEnum } from 'src/components/shared/SciPage/types';
import { retrieveStatesAction } from './ducks/retrieveStates.duck';

const apiCalls = SCIApi.getInstance();

function* getRentalDrivers({ payload }: { payload: GetDriversPayload }) {
    try {
        const data: Driver[] = yield apiCalls.get<Driver[]>(
            api.getRentalDriversPath(payload.rentalId),
        );
        yield put(retrieveDriversAction.success(data));
    } catch (error) {
        yield put(retrieveDriversAction.failure(error));
    }
}

function* postRentalDriver({ payload }: { payload: PostDriverPayload }) {
    try {
        const response: BatchResponse<ProfileIdentifier> = yield apiCalls.post<
            BatchResponse<ProfileIdentifier>
        >(api.getRentalDriversPath(payload.rentalId), payload.driver);

        const driverResponse: BatchDriverItem = response.items[0];

        if (response.status === BatchResponseStatusEnum.success) {
            yield put(updateDriverAction.success(driverResponse.driver));
            yield put(retrieveDriversAction.update([driverResponse.driver]));
            yield put(
                setCarouselPageIsCompletedAction({
                    page: SciPagesEnum.AddDriverPage,
                }),
            );
            payload.reset(driverResponse.driver);
        } else {
            yield put(
                updateDriverAction.failure({
                    validationErrors:
                        driverResponse.validationMessagesByProperty,
                    serverErrors: driverResponse.errorMessages,
                }),
            );
        }
    } catch (error) {
        yield put(updateDriverAction.failure({ serverErrors: error }));
    }
}

function* getStates() {
    try {
        const data: States = yield apiCalls.get<States>(api.getStates);
        yield put(retrieveStatesAction.success(data));
    } catch (error) {
        yield put(retrieveStatesAction.failure(error));
    }
}

function* addDriverSaga() {
    yield all([
        takeLatest(retrieveDriversAction, getRentalDrivers),
        takeLatest(updateDriverAction, postRentalDriver),
        takeLatest(retrieveStatesAction, getStates),
    ]);
}

export default addDriverSaga;
