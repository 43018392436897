import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';

import { useConfiguration } from 'src/hooks/useConfiguration';

import { PaymentFormValues } from '../payment.type';
import { PaymentMethodEnum } from '../payment.enum';
import msg from '../payment.messages';

export const usePaymentForm = () => {
    const intl = useIntl();
    const configuration = useConfiguration();

    const paymentMethodLabel = intl.formatMessage(msg.label.paymentMethod);
    const issuerLabel = intl.formatMessage(msg.label.issuer);
    const cardNumberLabel = intl.formatMessage(msg.label.cardNumber);
    const cardHolderNameLabel = intl.formatMessage(msg.label.cardHolderName);
    const cardExpiryDateLabel = intl.formatMessage(msg.label.cardExpiry);
    const cardCVVLabel = intl.formatMessage(msg.label.cardCVV);

    const baseShapeSchema = {
        paymentMethod: yup.string().required().label(paymentMethodLabel),
        issuer: configuration.functionalities.payments.showCardType
            ? yup.string().required().label(issuerLabel)
            : yup.string().notRequired(),
        cardNumber: yup.string().required().label(cardNumberLabel),
        cardHolderName: yup.string().required().label(cardHolderNameLabel),
        cardExpiryDate: yup.string().required().label(cardExpiryDateLabel),
        cardCVV: yup
            .string()
            .required()
            .matches(/^\d+$/, `${cardCVVLabel} should have digits only`)
            .min(3)
            .max(4)
            .label(cardCVVLabel),
        tc: yup.bool().oneOf([true], 'T&C needs to be selected'),
    };

    const schema = yup.object().shape(baseShapeSchema);

    const { control, handleSubmit, watch, setValue, getValues, formState } =
        useForm<PaymentFormValues>({
            resolver: yupResolver(schema),
            mode: 'onSubmit',
            reValidateMode: 'onChange',
            defaultValues: {
                paymentMethod: PaymentMethodEnum.CreditCard,
                issuer: '',
                cardCVV: '',
                cardExpiryDate: '',
                cardHolderName: '',
                cardNumber: '',
                tc: false,
            },
        });

    return {
        control,
        watch,
        setValue,
        getValues,
        handleSubmit,
        formErrors: formState.errors,
    };
};
