import { selfCheckInRoute } from 'src/apis';

export const updateCustomerInfo = `${selfCheckInRoute}/hirer`;
export const getCustomerInfo = `${selfCheckInRoute}/hirer`;
export const getCountries = `${selfCheckInRoute}/points/countries`;
export const getPrimaryProductInfo = `${selfCheckInRoute}/products/primary`;
export const getDefaultOrganizationCountryCodePath = (
    organizationId: string,
): string => `${selfCheckInRoute}/${organizationId}/organizationsettings`;
export const getShuttlePoints = `${selfCheckInRoute}/points/shuttles?categories=Arrival`;
export const shuttleTransitCrud = `${selfCheckInRoute}/rentals/transits`;
