import { defineMessages } from 'react-intl';

const fields = defineMessages({
    email: {
        id: 'addAdditionalDriver.formField.email',
        defaultMessage: 'Email',
    },
    firstName: {
        id: 'addAdditionalDriver.formField.firstName',
        defaultMessage: 'First Name',
    },
    lastName: {
        id: 'addAdditionalDriver.formField.lastName',
        defaultMessage: 'Last Name',
    },
    dateOfBirth: {
        id: 'addAdditionalDriver.formField.dateOfBirth',
        defaultMessage: 'Date of Birth',
    },
    licenseNumber: {
        id: 'addAdditionalDriver.formField.licenseNumber',
        defaultMessage: 'License Number',
    },
    LicenceNumber: {
        id: 'addAdditionalDriver.formField.LicenceNumber',
        defaultMessage: 'Licence Number',
    },
    issuingCountry: {
        id: 'addAdditionalDriver.formField.issuingCountry',
        defaultMessage: 'Issuing Country',
    },
    state: {
        id: 'addAdditionalDriver.formField.state',
        defaultMessage: 'Issuing State/Province/Region',
    },
    issueDate: {
        id: 'addAdditionalDriver.formField.issueDate',
        defaultMessage: 'Date of Issue',
    },
    expiryDate: {
        id: 'addAdditionalDriver.formField.expiryDate',
        defaultMessage: 'Expiry Date',
    },
    dateOfTest: {
        id: 'addAdditionalDriver.formField.dateOfTest',
        defaultMessage: 'Date of Test',
    },
    expiredLicense: {
        id: 'addAdditionalDriver.formField.expiredLicense',
        defaultMessage: 'License has expired',
    },
    invalidIssueDate: {
        id: 'addAdditionalDriver.formField.invalidIssueDate',
        defaultMessage: `Date of issue can't be in the future`,
    },
    licenseEarlierThanDropOff: {
        id: 'addAdditionalDriver.formField.licenseEarlierThanDropOff',
        defaultMessage: 'License cannot be earlier than the Drop off Date',
    },
    licenceEarlierThanDropOff: {
        id: 'addAdditionalDriver.formField.LicenceEarlierThanDropOff',
        defaultMessage: 'Licence cannot be earlier than the Drop off Date',
    },
    enterLicenseNumber: {
        id: 'addAdditionalDriver.formField.enterLicenseNumber',
        defaultMessage: `Please enter the license number`,
    },
    enterLicenceNumber: {
        id: 'addAdditionalDriver.formField.enterLicenceNumber',
        defaultMessage: `Please enter the Licence number`,
    },
    enterDateOfBirth: {
        id: 'addAdditionalDriver.formField.enterDateOfBirth',
        defaultMessage: `Please enter the date of birth`,
    },
    enterFirstName: {
        id: 'addAdditionalDriver.formField.enterFirstName',
        defaultMessage: `Please enter the first name`,
    },
    enterLastName: {
        id: 'addAdditionalDriver.formField.enterLastName',
        defaultMessage: `Please enter the last name`,
    },
    enterEmail: {
        id: 'addAdditionalDriver.formField.enterEmail',
        defaultMessage: 'Please enter email address',
    },
    validEmail: {
        id: 'addAdditionalDriver.formField.validEmail',
        defaultMessage: 'Email must be a valid email',
    },
    invalidAge: {
        id: 'addAdditionalDriver.formField.invalidAge',
        defaultMessage: `Age should be greater than {minAge}`,
    },
    invalidDateOfTest: {
        id: 'addAdditionalDriver.formField.invalidDateOfTest',
        defaultMessage: `License has to be valid for at least 2 years before pickup.`,
    },
});
const formHeaderItems = defineMessages({
    confirmMsg: {
        id: 'addAdditionalDriver.formHeaderItem.confirmMsg',
        defaultMessage:
            'Are you sure you want to remove this additional driver?',
    },
    additionalDriverFormHeader: {
        id: 'addAdditionalDriver.formHeaderItem.additionaldriverFormHeader',
        defaultMessage: 'Additional Driver Details',
    },
    feeMayApply: {
        id: 'addAdditionalDriver.formHeaderItem.feeMayApply',
        defaultMessage: 'fee may apply',
    },
    addNewAdditionalDriverTitle: {
        id: 'addAdditionalDriver.formHeaderItem.addNewAdditionalDriver',
        defaultMessage: 'Add New Additional Driver',
    },
    additionalDriverDetails: {
        id: 'addAdditionalDriver.formHeaderItem.additionalDriverDetails',
        defaultMessage: 'Additional Driver Details',
    },
    detailsAdditionalDriverDialogMsgP1: {
        id: 'addAdditionalDriver.formHeaderItem.detailsAdditionalDriverDialogMsgP1',
        defaultMessage:
            'A fee of $2 per extra driver, per day applies (maximum charge per hire, per driver is $30). ' +
            'If you have purchased the Inclusive Pack, you can add drivers for free.',
    },
    detailsAddtionalDriverDialogMsgP2: {
        id: 'addAdditionalDriver.formHeaderItem.detailsDialogMsgP2',
        defaultMessage:
            'A minimum age of {minAge} years applies if the rental vehicle is a Action Pod, ' +
            'Mighty Highball or Mighty Double Down. If this applies to you, please contact us via {webChatUrl} or phone number below for assistance.',
    },
    webChat: {
        id: 'addAdditionalDriver.formHeaderItem.webChat',
        defaultMessage: `Web Chat`,
    },
});
const placeHolders = defineMessages({
    email: {
        id: 'addAdditionalDriver.placeHolder.email',
        defaultMessage: 'Enter email',
    },
    firstName: {
        id: 'addAdditionalDriver.placeHolder.firstName',
        defaultMessage: 'Enter first name',
    },
    lastName: {
        id: 'addAdditionalDriver.placeHolder.lastName',
        defaultMessage: 'Enter last name',
    },
    dateOfBirth: {
        id: 'addAdditionalDriver.placeHolder.dateOfBirth',
        defaultMessage: 'Enter date of birth',
    },
    licenseNumber: {
        id: 'addAdditionalDriver.placeHolder.licenseNumber',
        defaultMessage: 'Enter license number',
    },
    LicenceNumber: {
        id: 'addAdditionalDriver.placeHolder.LicenceNumber',
        defaultMessage: 'Enter Licence number',
    },
    issuingCountry: {
        id: 'addAdditionalDriver.placeHolder.issuingCountry',
        defaultMessage: 'Select country',
    },
    state: {
        id: 'addAdditionalDriver.placeHolder.state',
        defaultMessage: 'Select State/Province/Region',
    },
    issueDate: {
        id: 'addAdditionalDriver.placeHolder.issueDate',
        defaultMessage: 'Enter date of issue',
    },
    expiryDate: {
        id: 'addAdditionalDriver.placeHolder.expiryDate',
        defaultMessage: 'Enter expiry date',
    },
    dateOfTest: {
        id: 'addAdditionalDriver.placeHolder.dateOfTest',
        defaultMessage: 'Date of Test',
    },
});

const buttons = defineMessages({
    complete: {
        id: 'addAdditionalDriver.buttons.completeButton',
        defaultMessage: 'Complete',
    },
    noLabel: {
        defaultMessage: `No`,
        id: 'addAdditionalDriver.buttons.noButton',
    },
    yesLabel: {
        defaultMessage: `Yes`,
        id: 'addAdditionalDriver.buttons.yesButton',
    },
});

const error = defineMessages({
    pleaseContactUs: {
        id: 'addAdditionalDriver.error.pleaseContactUs',
        defaultMessage: `Please contact us using below details.`,
    },
    someThingWrong: {
        id: 'addAdditionalDriver.error.someThingWrong',
        defaultMessage: `Oops, something went wrong.`,
    },
    emailExists: {
        id: 'addAdditionalDriver.error.emailExists',
        defaultMessage: 'Customer email already exists. Please enter another email address',
    },
    emailNotMatch: {
        id: 'addAdditionalDriver.error.emailNotMatch',
        defaultMessage: 'The email address does not match the Customer details we have in our system. Please contact us via web chat or the phone number below for assistance',
    },
    updateAddressFailed: {
        id: 'addAdditionalDriver.error.updateAddressFailed',
        defaultMessage: "Oops, something went wrong. Couldn't update customer address, please contact us using below details",
    },
    updateRentalFailed: {
        id: 'addAdditionalDriver.error.updateRentalFailed',
        defaultMessage: "Oops, something went wrong. Couldn't update rental, please contact us using below details",
    },
    updateProfileFailed: {
        id: 'addAdditionalDriver.error.updateProfileFailed',
        defaultMessage: "Oops, something went wrong. Couldn't update rental profile, please contact us using below details",
    },
});

const addAdditionalDriverMessages = {
    formHeaderItems,
    placeHolders,
    fields,
    buttons,
    error,
};

export default addAdditionalDriverMessages;
