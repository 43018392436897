import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { SciPage } from 'src/components/shared/SciPage';
import { setCarouselDotsCurrentPage } from 'src/components/shared/SciPage/SciPage.duck';
import { SciPagesEnum } from 'src/components/shared/SciPage/types';
import { sciBrandNameSelector } from 'src/components/shared/SciPage/SciPage.selector';
import { SciContent } from 'src/components/shared/SciContent';
import { UsBrandNames, UkBrandNames } from 'src/enums';
import { sciContentKeys } from 'src/constants';

import msg from './addDriverMessages';
import * as S from './addDriver.component.style';
import { AddDriverForm } from './addDriver.form';

const MODULE_PREFIX = 'add-driver';
export const componentAddDriver = `${MODULE_PREFIX}-component`;
export const AddDriverComponent: FC = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const brandName = useSelector(sciBrandNameSelector);
    const showInformationPopup = !(
        brandName === UsBrandNames.roadBear ||
        brandName === UsBrandNames.elmonte ||
        brandName === UkBrandNames.europe
    );

    useEffect(() => {
        dispatch(setCarouselDotsCurrentPage(SciPagesEnum.AddDriverPage));
    }, [dispatch]);

    return (
        <SciPage data-test-id={componentAddDriver}>
            <S.Header>
                <S.DriverDetails>
                    <S.DriverDetailsLabel>
                        {intl.formatMessage(msg.formHeaderItems.driverDetails)}
                    </S.DriverDetailsLabel>
                    {showInformationPopup && (
                        <S.StyleContainedModal
                            dialogInfo={
                                <SciContent
                                    sciContentKey={
                                        sciContentKeys.sciInfoBoxDriverDetails
                                    }
                                />
                            }
                        />
                    )}
                </S.DriverDetails>
                <S.DriverDetailsNote>
                    <SciContent
                        sciContentKey={sciContentKeys.sciDriverDetailsPage}
                    />
                </S.DriverDetailsNote>
            </S.Header>
            <AddDriverForm />
        </SciPage>
    );
};
