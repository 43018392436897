import styled from 'styled-components';
import { getScreenBreaks } from 'src/utils/htmlHelper';

interface ColumnProps {
    size: number;
    sm?: number;
    md?: number;
    lg?: number;
}

export const Column = styled.div<ColumnProps>`
    flex: ${({ size }) => size};
    @media (max-width: ${(props) => getScreenBreaks(props).screenSMax}) {
        flex-basis: ${({ sm }) => (sm ? (sm / 12) * 100 : '')}%;
    }
    @media (max-width: ${(props) => getScreenBreaks(props).screenMDMax}) {
        flex-basis: ${({ md }) => (md ? (md / 12) * 100 : '')}%;
    }
    @media (max-width: ${(props) => getScreenBreaks(props).screenMDMax}) {
        flex-basis: ${({ lg }) => (lg ? (lg / 12) * 100 : '')}%;
    }
`;
