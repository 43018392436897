import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { intlLink } from 'src/utils/intlHelper';
import { SCIApi } from 'src/apis';
import { ThlWebChatUrl } from 'src/constants';
import { UkBrandNames, UsBrandNames } from 'src/enums';
import { sciBrandNameSelector } from 'src/components/shared/SciPage/SciPage.selector';
import { sleep } from 'src/utils';

import {
    WindCavePayPayload,
    GetPaymentResponse,
    PaymentSurchargePayload,
    PaymentFormValues,
    StatusPaymentResponse,
} from '../payment.type';
import {
    payment as paymentUrl,
    getPaymentStatusGenerator,
} from '../payment.api';
import { backendError } from '../payment.messages';
import { KEY_3DSECURE } from '../payment.constants';
import { StatusPayment } from '../payment.enum';

const CHECK_PAYMENT_INTERVAL = 3000;

const getPayment = async (payload: PaymentSurchargePayload) => {
    const apiCalls = SCIApi.getInstance();
    const result: AxiosResponse<GetPaymentResponse> =
        await apiCalls.get<GetPaymentResponse>(paymentUrl, {
            method: payload.method,
            issuer: payload.issuer,
        });

    return result?.data ?? result;
};

const getPaymentStatus = async (
    paymentId: string,
): Promise<StatusPaymentResponse> => {
    const apiCalls = SCIApi.getInstance();
    const result: AxiosResponse<StatusPaymentResponse> =
        await apiCalls.get<StatusPaymentResponse>(
            getPaymentStatusGenerator(paymentId),
        );

    return result?.data ?? result;
};

const makeTransaction = async (
    url: string,
    paymentFormValues: PaymentFormValues,
) => {
    const cardExpiryDateSplitted = paymentFormValues.cardExpiryDate?.split('/');
    const unformattedCardNumber = paymentFormValues.cardNumber.replaceAll(
        ' ',
        '',
    );
    const res = await axios.request({
        headers: {
            'Content-Type': 'application/json',
        },
        url,
        method: 'POST',
        data: {
            card: {
                cardHolderName: paymentFormValues.cardHolderName,
                cardNumber: unformattedCardNumber,
                dateExpiryMonth: cardExpiryDateSplitted?.[0],
                dateExpiryYear: cardExpiryDateSplitted?.[1],
                cvc2: paymentFormValues.cardCVV,
            },
        },
    });

    return res?.data;
};

const periodicallyCheckCompletion = async (paymentId: string) => {
    while (true) {
        const result = await getPaymentStatus(paymentId);

        if (result?.transactionStatus === StatusPayment.Pending) {
            await sleep(CHECK_PAYMENT_INTERVAL);
        } else {
            return result.transactionStatus === StatusPayment.Approved;
        }
    }
};

const perform3Dsecure = (link3dSecure: string, paymentId: string) => {
    if (!link3dSecure || !paymentId) {
        return false;
    }

    return new Promise((resolve) => {
        const iframe = document.createElement('iframe');
        iframe.setAttribute(
            'style',
            'position: fixed;top: 50%;left: 50%;margin-left: -200px;margin-top: -200px;z-index: 999999;visibility: visible;border-radius: 4px;overflow: hidden;background: #fff;',
        );
        iframe.setAttribute('height', '400');
        iframe.setAttribute('width', '400');
        iframe.setAttribute('src', link3dSecure);

        document.body.appendChild(iframe);
        iframe.onload = function () {
            const proxyPath = iframe?.contentDocument?.location?.pathname;
            if (proxyPath) {
                document.body.removeChild(iframe);

                periodicallyCheckCompletion(paymentId)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch(() => {
                        resolve(false);
                    });
            }
        };
    });
};

const pay = async (payload: WindCavePayPayload) => {
    const payment = await getPayment(payload?.paymentSurchargePayload);

    if (!payment?.ajaxPost || !payment?.paymentId) {
        throw new Error('No valid session');
    }

    const makeTransactionResult = await makeTransaction(
        payment.ajaxPost,
        payload.cardDetails,
    );
    const link3dSecure = makeTransactionResult?.links?.reduce(
        (acc: string, link?: { rel?: string; href?: string }) =>
            link?.rel === KEY_3DSECURE ? link?.href : acc,
        null,
    );

    if (
        link3dSecure &&
        (await perform3Dsecure(link3dSecure, payment?.paymentId)) === false
    ) {
        throw new Error('Payment failed');
    }
    if (!link3dSecure) {
        await periodicallyCheckCompletion(payment?.paymentId)
            .then((res) => {
                if (!res) {
                    throw new Error('Payment failed');
                }
            })
            .catch(() => {
                throw new Error('Payment failed');
            });
    }
    // Payment success;
    return true;
};

export const useWindcave = (onSuccess: any) => {
    const intl = useIntl();
    const brandName = useSelector(sciBrandNameSelector);
    const displayLinkInError = ![
        ...Object.values(UkBrandNames),
        ...Object.values(UsBrandNames),
    ].includes(brandName as UkBrandNames | UsBrandNames);

    const paymentErrorMessage = displayLinkInError
        ? intl.formatMessage(backendError.paymentErrorWithLink, {
              a: intlLink(ThlWebChatUrl),
          })
        : intl.formatMessage(backendError.paymentError);

    const [error, setError] = useState<React.ReactNode | string | undefined>(
        undefined,
    );

    const { mutate, isLoading, isError, data } = useMutation(pay, {
        onMutate: () => {
            setError(undefined);
        },
        onSuccess,
        onError: () => {
            setError(paymentErrorMessage);
        },
    });

    return {
        mutate,
        isLoading,
        isError,
        data,
        error,
    };
};
