import { defineMessages } from 'react-intl';

const errorAndWarnings = defineMessages({
    warningLiability: {
        id: 'product.warningLiability',
        defaultMessage:
            // eslint-disable-next-line no-template-curly-in-string
            'You are liable for up to ${liabilityAmount}^ per incident (depending on vehicle)',
    },
    errorHeader: {
        id: 'product.errorHeader',
        defaultMessage: 'Error',
    },
    defaultError: {
        id: 'product.defaultError',
        defaultMessage: 'An unexpected error happened',
    },
});

const productMessages = {
    errorAndWarnings,
};

export default productMessages;
