export enum StatusPayment {
    Pending = 'Pending',
    Other = 'Unspecified',
    Approved = 'Approved',
    Declined = 'Declined'
}

export enum PaymentMethodEnum {
    CreditCard = 'CreditCard',
    DebitCard = 'DebitCard',
    Manual = 'Manual',
}
