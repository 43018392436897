import { Logo, Page } from 'cosmos-components';
import { Divider } from 'src/components';
import styled from 'styled-components';
import { RetrieveSelfCheckInForm } from './retrieveSelfCheckIn.form';
import { getScreenBreaks } from 'src/utils/htmlHelper';

export const CustomizedRetrieveSelfCheckInForm = styled(
    RetrieveSelfCheckInForm,
)`
    background-color: unset;
    display: inline-block;
    padding-bottom: 3.2rem;
    height: 24.5rem;
`;
export const StyledPage = styled(Page)`
    color: ${({ theme: { colors } }) => colors.secondBlack};
    background-color: unset;
    width: 100%;
    height: 100%;
    padding: 0;
    header {
        margin-bottom: 0;
    }
    section {
        display: flex;
        flex-direction: column;
        height: 100%;
        footer {
            margin-top: auto;
        }
    }
`;

export const StyledHeader = styled.div`
    display: inline-block;
    margin: 0;
    width: 100%;
    margin-bottom: 0;
`;

export const StyledDivider = styled(Divider)`
    background-image: ${({ theme: { colors } }) =>
        `linear-gradient(to left, ${colors.yellow},  ${colors.orange}, ${colors.red})`};
    height: 0.7rem;
    border-top: none;
    display: inline-block;
    padding-right: calc(100% - 0.5px);
    position: relative;
    top: -0.3rem;
    @media (min-width: ${(props) => getScreenBreaks(props).screenLMin}) {
        height: 1rem;
    }
`;

export const HeaderIcons = styled.div`
    display: flex;
    border-top: 0.1rem solid ${({ theme: { colors } }) => colors.secondaryGrey};
    border-bottom: solid 0.1rem
        ${({ theme: { colors } }) => colors.secondaryGrey};
    justify-content: flex-end;
    height: 6rem;
    align-items: center;
`;

export const ColoredMAUILogo = styled(Logo)`
    fill: ${({ theme: { colors } }) => colors.blue};
    width: 5.478rem;
    padding-right: 2.22rem;
    padding-top: 14%;
`;
export const ColoredBritzLogo = styled(Logo)`
    width: 6.24rem;
    padding-right: 2.16rem;
    padding-top: calc(0.4rem + 14%);
`;
export const ColoredMightyLogo = styled(Logo)`
    path:last-child {
        fill: ${({ theme: { colors } }) => colors.secondaryRed};
    }
    width: 6.223rem;
    padding-right: 1.5rem;
    padding-top: 14%;
`;
