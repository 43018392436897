import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import { isNil } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { UsBrandNames, AllBrands } from 'src/enums';
import { considerDateAsUtc } from 'src/utils/DateHelper';
import {
    sciBrandNameSelector,
    sciLanguageSelector,
} from 'src/components/shared/SciPage/SciPage.selector';
import {
    validateAge,
    validateAgeAtDate,
    validateNotEarlierThanPickOffDateWithExcludedValue,
    validateNotInPastWithExcludedValue,
} from 'src/utils/ValidationHelper';
import { useSci } from 'src/hooks/useSci';

import {
    AUSTRALIA,
    CANADA,
    defaultMinAge,
    StateCountries,
    UNITEDSTATES,
} from 'src/constants';
import { lookupAddress } from 'src/components/shared/GooglePlacesAutoComplete/utils';

import {
    countriesIsLoadingSelector,
    countriesObjectSelector,
    countriesSelector,
    countrySelector,
    primaryProductSelector,
} from '../updateCustomerInfo/updateCustomerInfo.selector';

import { retrieveOrganizationSettingsAction } from '../updateCustomerInfo/retrieveOrganizationSettings.duck';
import { retrieveCustomerInfoAction } from '../updateCustomerInfo/retrieveCustomerInfo.duck';
import { retrieveCountriesAction } from '../updateCustomerInfo/retrieveCountries.duck';
import { Countries } from '../updateCustomerInfo/types';

import { Driver } from './types';
import msg from './addDriverMessages';
import {
    defaultState,
    retrieveStatesAction,
} from './ducks/retrieveStates.duck';
import { updateDriverAction } from './ducks/updateDriver.duck';
import {
    defaultDriver,
    retrieveDriversAction,
} from './ducks/retrieveDriver.duck';
import {
    driverSelector,
    retrieveDriversIsLoadingSelector,
    updateDriverErrorSelector,
    updateDriverIsLoadingSelector,
    statesSelector,
} from './addDriver.selector';

const defineFormSchema = (
    intl: any,
    brandName: AllBrands | undefined,
    rental: any,
    primaryProduct: any,
) => {
    const isUSBrand =
        brandName === UsBrandNames.roadBear ||
        brandName === UsBrandNames.elmonte;

    return yup.object().shape({
        email: yup
            .string()
            .email(intl.formatMessage(msg.fields.validEmail))
            .required(intl.formatMessage(msg.fields.enterEmail)),
        firstName: yup
            .string()
            .required(intl.formatMessage(msg.fields.enterFirstName)),
        lastName: yup
            .string()
            .required(intl.formatMessage(msg.fields.enterLastName)),
        document: yup.object().shape({
            number: yup
                .string()
                .required(
                    isUSBrand
                        ? intl.formatMessage(msg.fields.enterLicenseNumber)
                        : intl.formatMessage(msg.fields.enterLicenceNumber),
                ),
            validEnd: yup
                .date()
                .nullable()
                .test(
                    'validEnd',
                    isUSBrand
                        ? intl.formatMessage(msg.fields.expiredLicense)
                        : intl.formatMessage(msg.fields.expiredLicence),
                    (value) =>
                        !value
                            ? true
                            : validateNotInPastWithExcludedValue(
                                  value?.toDateString(),
                              ),
                )
                .test(
                    'validEnd',
                    intl.formatMessage(msg.fields.licenseEarlierThanDropOff),
                    (value) =>
                        !value
                            ? true
                            : validateNotEarlierThanPickOffDateWithExcludedValue(
                                  value?.toDateString(),
                                  rental?.end?.date ?? '',
                              ),
                ),
            validStart: yup
                .date()
                .nullable()
                .max(
                    new Date(),
                    intl.formatMessage(msg.fields.invalidIssueDate),
                ),
            dateOfBirth: yup
                .date()
                .required(intl.formatMessage(msg.fields.enterDateOfBirth))
                .test(
                    'dateOfBirth',
                    intl.formatMessage(msg.fields.invalidAge, {
                        minAge: `${primaryProduct.minAge ?? defaultMinAge}`,
                    }),
                    (value?: Date) =>
                        !value ||
                        validateAge(
                            value,
                            primaryProduct.minAge ?? defaultMinAge,
                        ),
                ),
            dateOfTest: yup
                .date()
                .test(
                    'dateOfTest',
                    intl.formatMessage(msg.fields.invalidDateOfTest),
                    (value) =>
                        !rental?.start?.date ||
                        !value ||
                        validateAgeAtDate(
                            value,
                            new Date(rental?.start?.date),
                            2,
                        ),
                ),
        }),
    });
};

export const useAddDriverForm = () => {
    const intl = useIntl();
    const primaryProduct = useSelector(primaryProductSelector);
    const brandName = useSelector(sciBrandNameSelector);
    const selfCheckIn = useSci();

    const rental = selfCheckIn?.data?.rental;

    const retrieveDriversIsLoading = useSelector(
        retrieveDriversIsLoadingSelector,
    );
    const updateDriverIsLoading = useSelector(updateDriverIsLoadingSelector);
    const countriesIsLoading = useSelector(countriesIsLoadingSelector);
    const countriesObject = useSelector(countriesObjectSelector);
    const driver = useSelector(driverSelector);
    const updateDriverErrors = useSelector(updateDriverErrorSelector);
    const country = useSelector(countrySelector);
    const countries = useSelector(countriesSelector);
    const sciLanguage = useSelector(sciLanguageSelector);

    const [toggleState, setToggleState] = useState(false);
    const [stateRequired, setStateRequired] = useState(false);

    const schema = defineFormSchema(intl, brandName, rental, primaryProduct);

    const { control, formState, trigger, handleSubmit, setValue, reset } =
        useForm<Driver>({
            resolver: yupResolver(schema),
        });
    const statesWithCountries = useSelector(statesSelector);
    const [states, setStates] = useState(statesWithCountries);

    const onSubmit: SubmitHandler<Driver> = (data: Driver) => {
        const preparePayload = () => {
            if (data.document) {
                if (
                    !data.document.documentType ||
                    !data.document.documentType.issuerName
                ) {
                    data.document.documentType =
                        defaultDriver.document.documentType;
                }
                if (
                    data.document.validStart
                        ?.toDateString()
                        .includes('Tue Jan 01 1901')
                ) {
                    delete data.document['validStart'];
                }
                if (
                    data.document.validEnd
                        ?.toDateString()
                        .includes('Tue Jan 01 1901')
                ) {
                    delete data.document['validEnd'];
                }

                if (data.document.validEnd) {
                    data.document.validEnd =
                        considerDateAsUtc(data.document.validEnd) ?? undefined;
                }

                if (data.document.validStart) {
                    data.document.validStart =
                        considerDateAsUtc(data.document.validStart) ??
                        undefined;
                }

                if (data.document.dateOfBirth) {
                    data.document.dateOfBirth =
                        considerDateAsUtc(data.document.dateOfBirth) ??
                        undefined;
                }
            }

            return data;
        };
        dispatch(
            updateDriverAction({
                rentalId: rental?.id?.rentalId,
                driver: preparePayload(),
                reset,
            }),
        );
    };

    const isFormLoading =
        retrieveDriversIsLoading || updateDriverIsLoading || countriesIsLoading;

    const dispatch = useDispatch();

    useEffect(() => {
        if (rental?.id?.rentalId) {
            dispatch(
                retrieveDriversAction({
                    rentalId: rental?.id?.rentalId,
                }),
            );
        }
    }, [dispatch, rental?.id?.rentalId]);

    useEffect(() => {
        if (selfCheckIn?.data?.id?.organizationId) {
            dispatch(
                retrieveOrganizationSettingsAction({
                    organizationId: selfCheckIn?.data?.id?.organizationId,
                }),
            );
        }
    }, [dispatch, selfCheckIn?.data?.id?.organizationId]);

    useEffect(() => {
        if (!driver) {
            dispatch(retrieveCustomerInfoAction());
        }
    }, [dispatch, driver]);

    useEffect(() => {
        if (countries.length === 0) {
            dispatch(retrieveCountriesAction());
        }
    }, [dispatch, countries]);

    useEffect(() => {
        dispatch(retrieveCountriesAction());
    }, [dispatch, sciLanguage]);

    const setCountryStateProps = useCallback(
        (selectedCountry: string, countryCode: string) => {
            setToggleState(StateCountries.includes(selectedCountry));
            setStateRequired(
                selectedCountry === StateCountries[UNITEDSTATES] ||
                    selectedCountry === StateCountries[AUSTRALIA],
            );
            const newStatesList = Object.values(
                statesWithCountries ?? [],
            ).filter((item: any) =>
                item.parents?.some(
                    (parent: any) => parent.code === countryCode,
                ),
            );
            if (selectedCountry === StateCountries[CANADA]) {
                newStatesList?.unshift(defaultState[0]);
            }

            setStates(newStatesList);
        },
        [statesWithCountries],
    );

    useEffect(() => {
        if (!country) {
            return;
        }

        let issuerName = country.name;
        let issuerCode = country.code;

        if (isNil(statesWithCountries)) {
            dispatch(retrieveStatesAction());
        }
        if (driver) {
            issuerName = driver?.document
                ? driver?.document.documentType.issuerName
                : country.name;
            issuerCode = driver?.document
                ? driver?.document.documentType.issuer
                : country.code;
            reset(driver);
        }
        if ((driver && !driver.document) || !driver) {
            setValue(`document.documentType.issuer`, country.code);
            setValue(`document.documentType.issuerName`, country.name);
            country.name === StateCountries[UNITEDSTATES] ||
            country.name === StateCountries[AUSTRALIA]
                ? setValue(
                      `document.documentType.state`,
                      statesWithCountries?.filter((item: any) =>
                          item.parents?.some(
                              (parent: any) => parent.code === country.code,
                          ),
                      )[0]?.name,
                  )
                : setValue(`document.documentType.state`, '');
        }
        setCountryStateProps(issuerName, issuerCode);
    }, [
        dispatch,
        reset,
        setValue,
        setCountryStateProps,
        country,
        driver,
        statesWithCountries,
    ]);

    const handleCountryChange = (selected: string) => {
        const selectedCountry: any = Object.values(countriesObject).find(
            (item: any) => item.name === selected,
        );
        setValue('document.documentType.issuer', selectedCountry.code);
        setValue(
            'document.documentType.state',
            selectedCountry.name === StateCountries[UNITEDSTATES] ||
                selectedCountry.name === StateCountries[AUSTRALIA]
                ? statesWithCountries?.filter((item: any) =>
                      item.parents?.some(
                          (parent: any) => parent.code === selectedCountry.code,
                      ),
                  )[0]?.name
                : '',
        );
        setCountryStateProps(selected, selectedCountry.code);
    };

    const handleStateChange = (selected: string) => {
        const state: any = states.find((item: any) => item.name === selected);
        setValue('document.documentType.state', state?.name);
    };

    return {
        control,
        formState,
        trigger,
        setValue,
        onSubmit: handleSubmit(onSubmit),
        isFormLoading,
        driver,
        handleCountryChange,
        updateDriverErrors,
        toggleState,
        stateRequired,
        handleStateChange,
        states,
        countries,
    };
};

export const updateCountry = async (
    value: any,
    countriesWithCodes: Countries,
    countries: string[],
    setSelectedCountry: (country: string) => any,
) => {
    const get = await lookupAddress(value);

    const countryName = Object.entries(countriesWithCodes)?.find(
        (option) => option[1].code === get.countryCode,
    )?.[1].name;
    const foundCountry =
        countries?.find((country) => country === countryName) ?? '';

    setSelectedCountry(foundCountry);
};
