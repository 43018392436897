import { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getGoogleAnalyticsApiKey } from 'src/utils/configStore';

const useGoogleAnalytics = (env: string) => {
    const [configured, setConfigured] = useState<boolean>(false);
    const apiKey = getGoogleAnalyticsApiKey();

    useEffect(() => {
        if (env === 'production' && !configured && apiKey) {
            const firebaseConfig = {
                apiKey,
                authDomain: 'sci-prod.firebaseapp.com',
                projectId: 'sci-prod',
                storageBucket: 'sci-prod.appspot.com',
                messagingSenderId: '394630505447',
                appId: '1:394630505447:web:e110ae438734a112f95342',
                measurementId: 'G-HEKTYYTZ8F',
            };

            // Initialize Firebase
            const app = initializeApp(firebaseConfig);
            getAnalytics(app);
            setConfigured(true);
        }
    }, [configured, env, apiKey]);
};

export default useGoogleAnalytics;
