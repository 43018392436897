import { FC } from 'react';
import { useIntl } from 'react-intl';

import globalMessages from 'src/messages';
import { phoneLink } from 'src/utils/intlHelper';

import {
    ContactWrapper,
    ContactItem,
    ContactItemHeader,
    ColoredTrialMessage,
} from './Contact';

const ContactCA: FC = () => {
    const intl = useIntl();

    return (
        <ContactWrapper>
            <ContactItem>
                <ContactItemHeader>
                    <span>
                        {intl.formatMessage(
                            globalMessages.phones.canaDreamTollFreePhone,
                        )}
                    </span>
                </ContactItemHeader>
                <ColoredTrialMessage>
                    <span>
                        {intl.formatMessage(
                            globalMessages.phones.canaDreamTollFreePhoneNumber,
                            {
                                a: phoneLink,
                            },
                        )}
                    </span>
                </ColoredTrialMessage>
            </ContactItem>
            <ContactItem>
                <ContactItemHeader>
                    <span>
                        {intl.formatMessage(
                            globalMessages.phones.canaDreamInternationalPhone,
                        )}
                    </span>
                </ContactItemHeader>
                <ColoredTrialMessage>
                    <span>
                        <span>
                            {intl.formatMessage(
                                globalMessages.phones
                                    .canaDreamInternationalPhoneNumber,
                                {
                                    a: phoneLink,
                                },
                            )}
                        </span>
                    </span>
                </ColoredTrialMessage>
            </ContactItem>
        </ContactWrapper>
    );
};

export default ContactCA;
