import { FC, ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { Input } from 'cosmos-components';

import { ValidationError } from 'src/components';
import { isAscii } from 'src/utils/string';

import { StyledFormField } from '../index';
export interface InputProps {
    className?: string;
    name: string;
    control: any;
    rules?: any;
    defaultValue?: any;
    label?: string | ReactNode;
    placeholder?: string;
    disabled?: boolean;
    isRequired?: boolean;
    autoComplete?: 'off' | 'on';
    errorMessage?: string | null;
    inputRestProps?: any;
    hidden?: boolean;
    testId?: string;
    onlyAscii?: boolean;
}
interface WrapperProps {
    removeLabelMargin?: boolean;
    'data-test-id'?: string;
}

const CustomInput: FC<InputProps> = ({
    className,
    name,
    control,
    rules = {},
    defaultValue,
    label,
    placeholder,
    disabled,
    isRequired,
    autoComplete,
    errorMessage,
    inputRestProps,
    hidden,
    testId,
    onlyAscii,
}) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field, fieldState: { error, invalid } }) => (
                <Wrapper
                    removeLabelMargin={!label}
                    hidden={hidden}
                    className={className}
                >
                    <StyledFormField
                        isRequired={isRequired}
                        label={label}
                        name={name}
                    >
                        <StyledInput
                            value={field.value}
                            isError={invalid}
                            placeholder={placeholder}
                            autoComplete={autoComplete}
                            name={name}
                            onInputChange={(value) => {
                                if (!onlyAscii || isAscii(value)) {
                                    field.onChange(value);
                                }
                            }}
                            disabled={disabled}
                            testId={testId}
                            ref={field.ref}
                            data-test-id={testId}
                            {...inputRestProps}
                        />
                    </StyledFormField>
                    <ValidationError data-test-id={testId?.concat('-error')}>
                        {errorMessage ? errorMessage : error?.message}
                    </ValidationError>
                </Wrapper>
            )}
        />
    );
};

export const StyledInput = styled(Input)`
    ${({ disabled }) =>
        disabled &&
        css`
            background-color: ${({ theme: { colors } }) => colors.primaryGrey};
        `}
`;

export const Wrapper = styled.div<WrapperProps>`
    flex-basis: 100%;
    flex-grow: 1;
    margin-top: ${({ removeLabelMargin }) =>
        removeLabelMargin ? '-2rem' : ''};
`;

const defaultProps: Partial<InputProps> = {
    disabled: false,
    isRequired: true,
    autoComplete: 'off',
    errorMessage: null,
    inputRestProps: {},
};
CustomInput.defaultProps = defaultProps;
export { CustomInput };
