import { createSelector } from 'reselect';
import { getModuleSelector } from 'src/utils/redux';
import {
    driverSelector,
    driverSelectors,
} from '../addDriver/addDriver.selector';
import { Driver, UpdateAdditionalDriversState } from '../addDriver/types';
import { BatchError } from '../types';

const moduleSelector = getModuleSelector<{
    updateAdditionalDrivers: UpdateAdditionalDriversState;
}>('addAdditionalDrivers');

export const additionalDriverSelectors = createSelector<any, Driver[]>(
    driverSelectors,
    driverSelector,
    (drivers: Driver[], driver: Driver) =>
        drivers.filter(
            (item) =>
                !item.hirer && item.id?.profileId !== driver.id?.profileId,
        ),
);

export const updateModuleSelector = createSelector<
    any,
    UpdateAdditionalDriversState
>(
    moduleSelector,
    (addAdditionalDriver: {
        updateAdditionalDrivers: UpdateAdditionalDriversState;
    }) => addAdditionalDriver.updateAdditionalDrivers,
);

export const updateAdditionalDriversIsLoadingSelector = createSelector<
    any,
    boolean
>(
    updateModuleSelector,
    (updateAdditionalDrivers: UpdateAdditionalDriversState) =>
        updateAdditionalDrivers.loading,
);

export const updateAdditionalDriversErrorSelector = createSelector<
    any,
    BatchError[]
>(
    updateModuleSelector,
    (updateAdditionalDrivers: UpdateAdditionalDriversState) =>
        updateAdditionalDrivers.error,
);

export const deleteAdditionalDriversIsLoadingSelector = createSelector<
    any,
    boolean
>(moduleSelector, (updateAdditionalDrivers: any) => {
    return updateAdditionalDrivers.deleteAdditionalDrivers.loading;
});
