import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { intlLink } from 'src/utils/intlHelper';
import { SCIApi } from 'src/apis';
import { ThlWebChatUrl } from 'src/constants';
import { UkBrandNames, UsBrandNames } from 'src/enums';
import { sciBrandNameSelector } from 'src/components/shared/SciPage/SciPage.selector';

import {
    PaymentSurchargeResponse,
    PaymentSurchargePayload,
} from '../payment.type';
import { paymentSurcharge } from '../payment.api';
import { backendError } from '../payment.messages';

const postPaymentSurcharge = async (payload: PaymentSurchargePayload) => {
    const apiCalls = SCIApi.getInstance();
    const result: AxiosResponse<PaymentSurchargeResponse> =
        await apiCalls.post<PaymentSurchargeResponse>(
            paymentSurcharge,
            payload,
        );

    return result?.data ?? result;
};

export const usePaymentSurcharge = () => {
    const intl = useIntl();
    const brandName = useSelector(sciBrandNameSelector);
    const displayLinkInError = ![
        ...Object.values(UkBrandNames),
        ...Object.values(UsBrandNames),
    ].includes(brandName as UkBrandNames | UsBrandNames);

    const genericErrorMessage = displayLinkInError
        ? intl.formatMessage(backendError.genericWithLink, {
              a: intlLink(ThlWebChatUrl),
          })
        : intl.formatMessage(backendError.generic);

    const [error, setError] = useState<React.ReactNode | string | undefined>(
        undefined,
    );

    const { mutate, isLoading, isError, data } = useMutation(
        postPaymentSurcharge,
        {
            onError: () => setError(genericErrorMessage),
        },
    );

    return {
        mutate,
        isLoading,
        isError,
        data,
        error,
    };
};
