import { defineMessages } from 'react-intl';

const globalText = defineMessages({
    add: {
        id: 'loyaltyMembership.globalText.add',
        defaultMessage:
            'Add your {name} number to earn <coloredRed>{points} {displayName} Points</coloredRed> on your booking.',
    },
    enter: {
        id: 'loyaltyMembership.globalText.enter',
        defaultMessage:
            'Enter your {name} number to earn {points} on your booking.',
    },
    lastNameMessage: {
        id: 'loyaltyMembership.globalText.lastNameMessage',
        defaultMessage:
            'Last Name on booking must match that of the {name} account.',
    },
    estimateMessage: {
        id: 'loyaltyMembership.globalText.estimateMessage',
        defaultMessage:
            '† Points earned is an estimate only. Total points earned is determined by actual dates travelled and amount spent.',
    },
});

const button = defineMessages({
    add: {
        id: 'loyaltyMembership.button.add',
        defaultMessage: 'Add',
    },
    remove: {
        id: 'loyaltyMembership.button.remove',
        defaultMessage: 'Remove',
    },
    addToBooking: {
        id: 'loyaltyMembership.button.addToBooking',
        defaultMessage: 'Add to Booking',
    },
});

const fields = defineMessages({
    number: {
        id: 'loyaltyMembership.fields.number',
        defaultMessage: '{name} Number',
    },
});

const errors = defineMessages({
    programNumberRequired: {
        id: 'loyaltyMembership.errors.number',
        defaultMessage: '{name} is required',
    },
    programNumbeType: {
        id: 'loyaltyMembership.errors.numberType',
        defaultMessage: '{name} should be a number',
    },
    incorectAssociation: {
        id: 'loyaltyMembership.errors.incorectAssociation',
        defaultMessage:
            'Please check your Qantas Frequent Flyer number and last name are correct.',
    },
});

const loyaltyMembershipMessages = {
    globalText,
    button,
    fields,
    errors,
};

export default loyaltyMembershipMessages;
