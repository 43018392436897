import { Action } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';
import { BatchError } from 'src/modules/types';
import {
    genApiSchemaState,
    setSingleState,
    RESET_APP_ACTION,
} from 'src/utils/redux';
import { wrapWithNamespace } from '../constants';
import { Driver, UpdateAdditionalDriversState } from '../../addDriver/types';

export const updateAdditionalDriversAction = createRoutine(
    wrapWithNamespace('update'),
);

const initialState: UpdateAdditionalDriversState = genApiSchemaState<Driver[]>({
    data: undefined,
});

const updateAdditionalDriversReducer = (
    state = initialState,
    { type, payload }: Action<Driver[]>,
): UpdateAdditionalDriversState => {
    const handler = HANDLERS[type as any];
    return handler ? handler(state, payload) : state;
};

const handleTrigger = setSingleState('loading', true);

const handleSuccess = (
    state: UpdateAdditionalDriversState,
    payload: Driver[],
) => ({
    ...state,
    loading: false,
    data: payload,
    error: {},
});

const handleError = (
    _state: UpdateAdditionalDriversState,
    payload: BatchError[],
): UpdateAdditionalDriversState => ({
    ...initialState,
    loading: false,
    error: payload,
});

const HANDLERS = {
    [updateAdditionalDriversAction.TRIGGER]: handleTrigger,
    [updateAdditionalDriversAction.SUCCESS]: handleSuccess,
    [updateAdditionalDriversAction.FAILURE]: handleError,
    [RESET_APP_ACTION]: () => initialState,
};

export default updateAdditionalDriversReducer;
