import { Page } from 'cosmos-components';
import styled from 'styled-components';

import * as SciPageStyle from 'src/components/shared/SciPage/SciPage.style';
import { DeviceType } from 'src/enums';

export const StyledPage = styled(Page)`
    color: ${({ theme: { colors } }) => colors.secondBlack};
    background-color: unset;
    width: 100%;
    padding: 0;
    header {
        margin-bottom: 0;
    }
`;

export const PageContainer = styled(SciPageStyle.PageContainer)`
    margin: 3rem;
    width: auto;

    ${({ deviceType }) =>
        deviceType === DeviceType.Mobile &&
        `
        flex-direction: column;
    `}
`;

// IMPORTANT: This is a temporary solution for US release. Please remove this Styled component entirely once US work is done.
export const UsPageContainer = styled(SciPageStyle.PageContainer)`
    max-width: 85rem;
    flex-direction: column;

    div {
        width: auto;
        margin: 1rem !important;
    }
`;

export const BookingSummaryContainer = styled.div`
    align-self: center;
`;

export const ChildrenContainer = styled(SciPageStyle.ChildrenContainer)`
    margin: 0rem;
    padding-right: ${({ deviceType }) =>
        deviceType === DeviceType.Mobile ? '3rem' : '3.2rem'};

    ${({ deviceType }) =>
        deviceType === DeviceType.Mobile &&
        `
        padding-bottom: 2rem;
    `}
`;
