import { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { PatternFormat } from 'react-number-format';

import { ValidationError } from 'src/components';
import { Controller } from 'react-hook-form';
import { StyledFormField } from '../index';
import { Wrapper } from './Input';

export interface InputProps {
    className?: string;
    name: string;
    control: any;
    format: string;
    defaultValue?: any;
    label?: string | ReactNode;
    placeholder?: string;
    disabled?: boolean;
    isRequired?: boolean;
    errorMessage?: string | null;
    inputRestProps?: any;
    hidden?: boolean;
    testId?: string;
}

// IMPORTANT NOTE: Would need to implement that into Cosmos-component.
// Not time for MVP though since it would require intensive testing because o react-number-format being on version 4.* there
// And would also require change on Input component.
// Ticket: https://thl-digital.atlassian.net/browse/FTW-31
const FormattedInput: FC<InputProps> = ({
    className,
    name,
    control,
    format,
    defaultValue,
    label,
    placeholder,
    disabled,
    isRequired,
    errorMessage,
    inputRestProps,
    hidden,
    testId,
}) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field, fieldState: { error, invalid } }) => (
                <Wrapper
                    removeLabelMargin={!label}
                    hidden={hidden}
                    className={className}
                >
                    <StyledFormField
                        isRequired={isRequired}
                        label={label}
                        name={name}
                    >
                        <FormattedInputWrapper isError={invalid}>
                            <StyledInput
                                value={field.value}
                                format={format}
                                placeholder={placeholder}
                                name={name}
                                onChange={field.onChange}
                                disabled={disabled}
                                data-test-id={testId}
                                {...inputRestProps}
                            />
                        </FormattedInputWrapper>
                    </StyledFormField>
                    <ValidationError data-test-id={testId?.concat('-error')}>
                        {errorMessage ? errorMessage : error?.message}
                    </ValidationError>
                </Wrapper>
            )}
        />
    );
};

const FormattedInputWrapper = styled.div<{ isError?: boolean }>`
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border: 0.1rem solid hsl(0, 0%, 75%);
    border-radius: 0.6rem;
    padding: 0.8rem 1.6rem;
    height: 3.5rem;
    color: ${({ theme: { colors } }) => colors?.textColor};
    background-color: ${({ theme: { colors } }) => colors?.white};
    font-size: 1.4rem;
    border: solid 0.1rem ${({ theme: { colors } }) => colors?.grey};

    ${({ isError }) =>
        isError &&
        css`
            border: 1px solid ${({ theme: { colors } }) => colors?.red};
        `}
`;

const StyledInput = styled(PatternFormat)`
    ${({ disabled }) =>
        disabled &&
        css`
            background-color: ${({ theme: { colors } }) => colors.primaryGrey};
        `}
    width: 100%;
    height: 100%;
    border: 0;
    padding: 0;
    outline: none;
    font-size: 1.4rem;
    background: inherit;
    color: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::placeholder {
        color: ${({ theme: { colors } }) => colors?.primerGrey};
    }
`;

const defaultProps: Partial<InputProps> = {
    disabled: false,
    isRequired: true,
    errorMessage: null,
    inputRestProps: {},
};
FormattedInput.defaultProps = defaultProps;
export { FormattedInput };
