import { defineMessages } from 'react-intl';

export const buttons = defineMessages({
    complete: {
        id: 'global.buttons.completeButton',
        defaultMessage: 'Complete',
    },
    continue: {
        id: 'global.buttons.continueButton',
        defaultMessage: 'Save & Continue',
    },
    payNow: {
        id: 'global.buttons.payNowButton',
        defaultMessage: 'Pay Now',
    },
    noLabel: {
        defaultMessage: `No`,
        id: 'global.buttons.noButton',
    },
    yesLabel: {
        defaultMessage: `Yes`,
        id: 'global.buttons.yesButton',
    },
    close: {
        defaultMessage: 'Close',
        id: 'global.buttons.close',
    },
});

export default buttons;
