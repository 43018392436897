import buttons from './buttons';
import errors from './errors';
import phones from './phones';

const globalMessages = {
    buttons,
    errors,
    phones,
};

export default globalMessages;
