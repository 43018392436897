import { geocodeByAddress } from 'react-places-autocomplete';
import { AddressInfo } from './types';

const addressReducer = (
    addressInfo: AddressInfo,
    component: google.maps.GeocoderAddressComponent,
) => {
    const types: string = component?.types?.join(',');

    if (types.includes('subpremise')) {
        return {
            ...addressInfo,
            line1: addressInfo.line1
                ? `${component.long_name}/${addressInfo.line1}`
                : component.long_name,
        };
    }
    if (types.includes('street_number')) {
        return {
            ...addressInfo,
            line1: addressInfo.line1
                ? `${addressInfo.line1}/${component.long_name}`
                : component.long_name,
        };
    }

    if (types.includes('route')) {
        return {
            ...addressInfo,
            line1: addressInfo.line1
                ? `${addressInfo.line1} ${component.long_name}`
                : component.long_name,
        };
    }

    if (
        types.includes('postal_code') &&
        !types.includes('postal_code_suffix')
    ) {
        return { ...addressInfo, postCode: component.long_name };
    }

    if (
        types.includes('sublocality_level_1') ||
        types.includes('sublocality')
    ) {
        return { ...addressInfo, line2: component.long_name };
    }

    if (
        types.includes('locality') ||
        types.includes('administrative_area_level_2')
    ) {
        // If city was already populate do not overide it with administrative_area_level_2
        if (
            types.includes('administrative_area_level_2') &&
            !!addressInfo.city
        ) {
            return addressInfo;
        }
        return { ...addressInfo, city: component.long_name };
    }

    if (types.includes('administrative_area_level_1')) {
        return { ...addressInfo, state: component.long_name };
    }

    if (types.includes('country')) {
        return {
            ...addressInfo,
            country: component.long_name,
            countryCode: component.short_name,
        };
    }

    return addressInfo;
};

export const lookupAddress = async (address: string) => {
    const result = await geocodeByAddress(address);

    return result[0].address_components.reduce(addressReducer, {
        country: '',
        countryCode: '',
    });
};
