import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { getMessages } from 'cosmos-components';
import { AcceptedLocalsType } from 'cosmos-components/dist/i18n';

import { sciLanguageSelector } from 'src/components/shared/SciPage/SciPage.selector';
import { setLanguage } from 'src/components/shared/SciPage/SciPage.duck';
import { getLangCode } from 'src/utils/string';
import { DEFAULT_LOCAL } from './constants';
import messages_en from './lang/en.json';
import messages_fr from './lang/fr.json';

// Format intl message with currency format: $1,000.00
// const customFormats = {
//     number: {
//         NZD: {
//             style: 'currency',
//             currency: 'NZD',
//         },
//     },
// };

const messages: { [key: string]: { [key: string]: string } } = {
    en: messages_en,
    fr: messages_fr,
};

export const Internationalization: FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const urlLanguage = getLangCode(searchParams.get('lng'));
    const navLanguage = getLangCode(navigator.language);
    const reduxLanguage  = getLangCode(useSelector(sciLanguageSelector));
    const local = urlLanguage || navLanguage || DEFAULT_LOCAL;

    useEffect(() => {
        if (!urlLanguage) {
            dispatch(setLanguage(undefined));
        } else if (local !== reduxLanguage) {
            dispatch(setLanguage(local as AcceptedLocalsType));
        }
    }, [dispatch, urlLanguage, local, reduxLanguage]);

    return (
        <IntlProvider
            locale={local}
            defaultLocale={DEFAULT_LOCAL}
            messages={{
                ...messages[local],
                ...getMessages(local as AcceptedLocalsType),
            }}
            // TODO: https://github.com/formatjs/formatjs/issues/3981
            // defaultFormats={customFormats}
        >
            {children}
        </IntlProvider>
    );
};
