import { all, put, select, takeLatest } from 'redux-saga/effects';

import { AcceptedLocalsType } from 'cosmos-components/dist/i18n';

import { setRedirection } from '../SagaReactRouterMiddleware/SagaReactRouterMiddleware.duck';

import { SciCarouselDotsState, SciPagesEnum } from './types';
import { setCarouselPageIsCompletedAction } from './SciPage.duck';
import { carouselDotsSelector, sciLanguageSelector } from './SciPage.selector';

function* handleCarouselPageIsCompleted({
    payload,
}: {
    payload: {
        page: SciPagesEnum;
        urlParams?: {
            [key: string]: string;
        };
    };
}) {
    const carouselDotState: SciCarouselDotsState = yield select(
        // Error with current reselect, we need consider ts is wrong here.
        // eslint-disable-next-line
        // @ts-ignore
        carouselDotsSelector,
    );

    // Error with current reselect, we need consider ts is wrong here.
    // eslint-disable-next-line
    // @ts-ignore
    const reduxLanguage: AcceptedLocalsType = yield select(sciLanguageSelector);

    if (carouselDotState.currentPage !== payload.page) {
        yield put(setCarouselPageIsCompletedAction.failure());
    } else {
        yield put(setCarouselPageIsCompletedAction.success(payload.page));

        const currentPageIndex = carouselDotState.items.findIndex(
            (item) => item.pageEnum === payload.page,
        );
        const nextPage = carouselDotState.items[currentPageIndex + 1];
        const url = new URL(
            `${window.location.origin}${
                nextPage ? nextPage.path : '/registrationComplete'
            }`,
        );

        if (payload.urlParams) {
            const keys = Object.keys(payload.urlParams) ?? [];

            for (const key of keys) {
                const value = payload.urlParams[key];

                url.searchParams.append(key, value);
            }
        }

        if (reduxLanguage) {
            url.searchParams.append('lng', reduxLanguage);
        }

        const finalUrl = `${url.pathname}${url.search ? url.search : ''}`;

        yield put(setRedirection.trigger(finalUrl));
    }
}

export default function* SciPageSagas() {
    yield all([
        takeLatest(
            setCarouselPageIsCompletedAction,
            handleCarouselPageIsCompleted,
        ),
    ]);
}
