import { AxiosResponse } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { SCIApi, selfCheckInRoute } from 'src/apis';
import { useBookingSummaryKey } from 'src/hooks/useBookingSummary';
import {
    ProductsResponse,
    ProductCategory,
    ConfigurationNodeGroup,
    UpdateConfigurationNodePayload,
} from './products.types';

export const productsApiRoute = `${selfCheckInRoute}/products/insurances-extras`;
export const useGetConfigurationNodeGroupsKey = 'getConfigurationNodeGroups';

export const getConfigurationNodeGroups = async (
    category: ProductCategory,
    language: string,
): Promise<ConfigurationNodeGroup[]> => {
    const apiCalls = SCIApi.getInstance();
    apiCalls.configureLanguage(language);
    const axiosResult: AxiosResponse<ProductsResponse> =
        await apiCalls.get<ProductsResponse>(productsApiRoute, {
            category: category,
        });
    const result = axiosResult?.data ?? axiosResult;

    return result?.groups;
};

const postConfigurationNodeGroups = async (
    payload: UpdateConfigurationNodePayload,
) => {
    const apiCalls = SCIApi.getInstance();
    const result: AxiosResponse<ProductsResponse> =
        await apiCalls.post<ProductsResponse>(productsApiRoute, payload);

    return result?.data ?? result;
};

export const usePostConfigurationNodeGroups = (category: ProductCategory) => {
    const queryClient = useQueryClient();

    const { mutate, isLoading, isError } = useMutation(
        postConfigurationNodeGroups,
        {
            onSuccess: (data) => {
                queryClient.setQueryData(
                    [useGetConfigurationNodeGroupsKey, category],
                    data.groups,
                );
                queryClient.invalidateQueries([useBookingSummaryKey]);
            },
        },
    );

    return {
        mutate,
        isLoading,
        isError,
    };
};
