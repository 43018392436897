import { defineMessages } from 'react-intl';

const errors = defineMessages({
    pleaseContactUs: {
        id: 'global.error.pleaseContactUs',
        defaultMessage: `Please contact us using below details.`,
    },
    someThingWrong: {
        id: 'global.error.someThingWrong',
        defaultMessage: `Oops, something went wrong.`,
    },
});

export default errors;
