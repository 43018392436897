import { useIntl } from 'react-intl';

import { B2C } from 'cosmos-components';

import { useWindowDimensions } from 'src/hooks/useWindowDimensions';
import { colors } from 'src/themes';

import { PaymentMethodEnum } from './payment.enum';
import { PAYMENT_METHOD_ICON_MAPPING } from './payment.constants';
import msg from './payment.messages';
import * as SForm from './payment.form.style';

export const PaymentMethodCard = ({
    paymentMethod,
    selected,
    disabled,
    loading,
    onSelect,
}: {
    paymentMethod: PaymentMethodEnum;
    selected: boolean;
    disabled?: boolean;
    loading?: boolean;
    onSelect: () => void;
}) => {
    const { deviceType } = useWindowDimensions();
    const intl = useIntl();
    const paymentMethodMessage = intl.formatMessage(
        msg.paymentMethod[paymentMethod],
    );

    return (
        <B2C.LoaderContainer
            loading={loading}
            opacity={{ opacity: '90%', color: colors.white }}
        >
            <SForm.PaymentMethodCard
                onClick={() => {
                    !disabled && !selected && onSelect();
                }}
                deviceType={deviceType}
                selected={selected}
                disabled={disabled}
            >
                <SForm.PaymentMethodCardContentWrapper>
                    <SForm.PaymentMethodCardStyledIcon
                        name={PAYMENT_METHOD_ICON_MAPPING[paymentMethod]}
                        deviceType={deviceType}
                        selected={selected}
                    />
                    <SForm.PaymentMethodCardText
                        deviceType={deviceType}
                        selected={selected}
                    >
                        {paymentMethodMessage}
                    </SForm.PaymentMethodCardText>
                </SForm.PaymentMethodCardContentWrapper>
            </SForm.PaymentMethodCard>
        </B2C.LoaderContainer>
    );
};
