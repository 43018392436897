import { CosmosTheme } from 'cosmos-components';
import { FC } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient } from '@tanstack/react-query';
import styled from 'styled-components';
import { BrowserRouter } from 'react-router-dom';

import 'cosmos-components/dist/index.css';

import './styles/app.css';

import rootReducer from './redux';
import rootSaga from './redux/rootSaga';
import { Routes } from './Routes';
import { configureStore } from './utils/redux';
import { Internationalization } from './Internationalization';
import { sciTheme } from './themes';
import useGoogleAnalytics from './hooks/useGoogleAnalytics';
import { getNodeEnvironment } from './utils/configStore';

import { QueryClientReduxCompatible } from './redux/QueryClientReduxCompatible';
import { GoogleReCaptchaWrapper } from './GoogleReCaptchaWrapper';

if (!!process.env.REACT_APP_MSW) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { worker } = require('./mockAPI/browser');
    worker
        .start({
            onUnhandledRequest: 'bypass',
        })
        .catch((err: any) => {
            console.warn('REACT_APP_MSW error :', err);
            console.warn(
                'Check if public/mockServiceWorker.js is setup. If not re-install node_modules',
            );
        });
}

export const { store, persistor } = configureStore({ rootReducer, rootSaga });
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});

const App: FC = () => {
    useGoogleAnalytics(getNodeEnvironment());

    return (
        <GoogleReCaptchaWrapper>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <QueryClientReduxCompatible client={queryClient}>
                        <BrowserRouter>
                            <Internationalization>
                                <CosmosTheme theme={sciTheme}>
                                    <AppWrapper>
                                        <Routes />
                                    </AppWrapper>
                                </CosmosTheme>
                            </Internationalization>
                        </BrowserRouter>
                    </QueryClientReduxCompatible>
                </PersistGate>
            </Provider>
        </GoogleReCaptchaWrapper>
    );
};

export default App;

const AppWrapper = styled.div`
    margin: auto;
    height: 100%;
    width: calc(100% - 2px);
`;
