/* eslint-disable react/prop-types */
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Logo, Link } from 'cosmos-components';

import { getScreenBreaks } from 'src/utils/htmlHelper';
import heartBanner from 'src/assets/images/heart-footer-banner.png';
import {
    ThlWebsiteUrl,
    ThlEmailUsUrl,
    RoadBearEmailUrl,
    ElMonteEmailUrl,
    CanaDreamEmailUrl,
    EuropeEmailUrl,
    ThlWebChatUrl,
} from 'src/constants';
import { sciBrandNameSelector } from 'src/components/shared/SciPage/SciPage.selector';
import { UkBrandNames, UsBrandNames, CaBrandNames } from 'src/enums';

import Contact from './Contact';
import ContactUS from './ContactUS';
import ContactUK from './ContactUK';
import ContactCA from './ContactCA';
import msg from './footerMessages';

export const Footer: FC = () => {
    const intl = useIntl();
    const currentYear = ` ${new Date().getFullYear()} `;
    const brandName = useSelector(sciBrandNameSelector);
    const isRoadBear = brandName === UsBrandNames.roadBear;
    const isElmonte = brandName === UsBrandNames.elmonte;
    const isCa = brandName === CaBrandNames.canadream;
    const isUk = brandName === UkBrandNames.europe;
    const isUs = isRoadBear || isElmonte;

    const useWebChat = !(isRoadBear || isElmonte || isCa || isUk);
    let emailUsLink = '';

    if (isRoadBear) {
        emailUsLink = RoadBearEmailUrl;
    } else if (isElmonte) {
        emailUsLink = ElMonteEmailUrl;
    } else if (isCa) {
        emailUsLink = CanaDreamEmailUrl;
    } else if (isUk) {
        emailUsLink = EuropeEmailUrl;
    } else {
        emailUsLink = ThlEmailUsUrl;
    }

    return (
        <>
            <StyledImage src={heartBanner} />
            <TrialMessage id="footer-trial-message">
                <NeedHelp> {intl.formatMessage(msg.footer.needHelp)} </NeedHelp>
                <EmailUsLink
                    href={useWebChat ? ThlWebChatUrl : emailUsLink}
                    target="_blank"
                >
                    {useWebChat
                        ? intl.formatMessage(msg.footer.webChat)
                        : intl.formatMessage(msg.footer.emailUs)}
                </EmailUsLink>
                {isUs ? (
                    <ContactUS brandName={brandName as UsBrandNames} />
                ) : isCa ? (
                    <ContactCA />
                ) : isUk ? (
                    <ContactUK />
                ) : (
                    <Contact />
                )}
            </TrialMessage>
            <BrandingSection>
                <BrandingContainer id="footer-branding-container">
                    <FooterIconsContainer>
                        <StyledTHLLogoWrapper>
                            <StyledTHLLogo name="thl" isInverted />
                        </StyledTHLLogoWrapper>
                        <LineDiv>
                            <Line />
                        </LineDiv>
                        <FooterIcons id="footer-icons">
                            <StyledLogo name="maui" isInverted />
                            <StyledLogo name="apollo" isInverted />
                            <StyledLogo name="britz" isInverted />
                            <StyledLogo name="mighty" isInverted />
                            <StyledLogo name="cheapa" isInverted />
                            <StyledLogo name="hippie" isInverted />
                            <StyledLogo name="canadream" isInverted />
                            <StyledLogo name="roadBear" isInverted />
                            <StyledLogo name="monteRV" isInverted />
                            <StyledLogo name="justGo" isInverted />
                            <StyledLogo name="bunk" isInverted />
                        </FooterIcons>
                    </FooterIconsContainer>
                    <CopyRightWrapper>
                        <CopyRight>
                            {intl.formatMessage(msg.footer.copyRight) +
                                currentYear +
                                intl.formatMessage(msg.footer.Rights)}
                        </CopyRight>
                        <PrivacyPolicy href={ThlWebsiteUrl} target="_blank">
                            {intl.formatMessage(msg.footer.privacyPolicy)}
                        </PrivacyPolicy>
                    </CopyRightWrapper>
                </BrandingContainer>
            </BrandingSection>
        </>
    );
};

export const TrialMessage = styled.div`
    text-align: center;
    font-size: 1.3rem;
    color: ${({ theme: { colors } }) => colors.secondBlack};
    background-color: ${({ theme: { colors } }) => colors.tertiaryGrey};
    margin-top: -1.5rem;
    padding: 2.5rem 2.4rem 1.5rem 2.4rem;
    @media (min-width: ${(props) =>
            getScreenBreaks(props).screenMDMin}) and (max-width: ${(props) =>
            getScreenBreaks(props).screenMDMax}) {
        padding: 2.5rem 17.5rem 1.5rem 17.5rem;
        margin-top: -2rem;
    }

    @media (min-width: ${(props) => getScreenBreaks(props).screenLMin}) {
        padding: 2.5rem 35.5rem 1.5rem 35.5rem;
        margin-top: -3rem;
    }
`;

export const BrandingSection = styled.div`
    background-color: ${({ theme: { colors } }) => colors.secondaryBlack};
    margin-left: -0.1rem;
    margin-right: -0.1rem;
    margin-bottom: -0.1rem;
    font-size: 0.9rem;
    color: ${({ theme: { colors } }) => colors.quaternaryGrey};
    height: 14.3rem;

    @media (min-width: ${(props) => getScreenBreaks(props).screenMDMin}) {
        height: 20rem;
    }
    @media (max-width: ${(props) => getScreenBreaks(props).screenSMax}) {
        height: 38rem;
    }
`;
export const BrandingContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: auto 18rem;
    @media (max-width: ${(props) => getScreenBreaks(props).screenSMax}) {
        margin: auto 7rem;
    }

    @media (min-width: ${(props) =>
            getScreenBreaks(props).screenSMax}) and (max-width: ${(props) =>
            getScreenBreaks(props).screenMDMax}) {
        margin: auto 4.5rem;
    }
`;

export const FooterIconsContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    gap: 1.7rem;

    @media (max-width: ${(props) => getScreenBreaks(props).screenSMax}) {
        flex-direction: column;
        align-items: center;
        gap: 3rem;
    }
`;

export const FooterIcons = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    column-gap: 2rem;
    row-gap: 1.8rem;
    @media (max-width: ${(props) => getScreenBreaks(props).screenSMax}) {
        gap: 1.5rem 1.8rem;
        justify-content: center;
    }
`;

export const StyledTHLLogo = styled(Logo)`
    width: 8.7rem;
    height: 4rem;
`;

const logoMixin = css`
    max-width: 9rem;
    width: auto;
    height: 3rem;
`;

export const StyledLogo = styled(Logo)`
    ${logoMixin};
`;

export const StyledJustGoLogoWrapper = styled.div`
    ${logoMixin};
    width: 4rem;
`;

export const CopyRight = styled.div`
    text-align: center;
    position: relative;
    left: 0;
    right: 0;
    padding-top: 0.2rem;
`;

export const PrivacyPolicy = styled(Link)`
    display: block;
    text-align: center;
    position: relative;
    left: 0;
    right: 0;
`;

export const EmailUsLink = styled(Link)`
    display: block;
    text-align: center;
    position: relative;
    left: 0;
    right: 0;
    color: ${({ theme: { colors } }) => colors.secondaryRed};
    font-weight: bold;
    &:hover {
        color: ${({ theme: { colors } }) => colors.secondaryRed};
    }
    &:active {
        color: ${({ theme: { colors } }) => colors.secondaryRed};
    }
`;

export const StyledThlMobileGradiant = styled.img`
    height: 3.9rem;
    width: 100%;
`;

export const StyledTHLLogoWrapper = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: ${(props) => getScreenBreaks(props).screenSMax}) {
        flex-basis: initial;
        padding-top: 1.7rem;
    }
`;

const CopyRightWrapper = styled.div`
    margin-top: auto;
    padding: 2rem 0;
    font-size: 1.3rem;
`;

export const NeedHelp = styled.div`
    font-weight: bold;
    font-size: 1.4rem;
`;
const StyledImage = styled.img`
    height: 3.3rem;
    width: 100%;
    object-fit: cover;
    margin-top: 2rem;
    @media (min-width: ${(props) =>
            getScreenBreaks(props).screenMDMin}) and (max-width: ${(props) =>
            getScreenBreaks(props).screenMDMax}) {
        height: 4.8rem;
        object-fit: contain;
    }

    @media (min-width: ${(props) => getScreenBreaks(props).screenLMin}) {
        height: 8rem;
        object-fit: contain;
    }
`;

const LineDiv = styled.div`
    @media (min-width: ${(props) => getScreenBreaks(props).screenMDMin}) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    @media (max-width: ${(props) => getScreenBreaks(props).screenSMax}) {
        width: 50%;
    }
`;

const Line = styled.div`
    @media (min-width: ${(props) => getScreenBreaks(props).screenMDMin}) {
        border-left: 0.2rem solid ${({ theme: { colors } }) => colors.orange};
        height: 70%;
    }
    @media (max-width: ${(props) => getScreenBreaks(props).screenSMax}) {
        border-top: 0.2rem solid ${({ theme: { colors } }) => colors.orange};
    }
`;
