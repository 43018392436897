/* eslint-disable react/prop-types */
import { FC } from 'react';
import styled from 'styled-components';
import { Logo } from 'cosmos-components';

import heroBanner from 'src/assets/images/hero-banner.jpg';
import { Divider } from 'src/components';
import { DeviceType } from 'src/enums';
import { useWindowDimensions } from 'src/hooks/useWindowDimensions';
import { getScreenBreaks } from 'src/utils/htmlHelper';

export const Header: FC = () => {
    const { deviceType } = useWindowDimensions();

    return (
        <StyledHeader>
            <HeaderIcons id="header-icons" deviceType={deviceType}>
                <HeaderIconContainer>
                    <ColoredMAUILogo name="maui" deviceType={deviceType} />
                </HeaderIconContainer>
                <HeaderIconContainer>
                    <ApolloLogo name="coloredApollo" deviceType={deviceType} />
                </HeaderIconContainer>
                <HeaderIconContainer>
                    <ColoredBritzLogo
                        name="coloredBritz"
                        deviceType={deviceType}
                    />
                </HeaderIconContainer>
                <HeaderIconContainer>
                    <ColoredMightyLogo name="mighty" deviceType={deviceType} />
                </HeaderIconContainer>
                <HeaderIconContainer>
                    <ColoredCheapaLogo
                        name="coloredCheapa"
                        deviceType={deviceType}
                    />
                </HeaderIconContainer>
                <HeaderIconContainer>
                    <ColoredHippieLogo
                        name="coloredHippie"
                        deviceType={deviceType}
                    />
                </HeaderIconContainer>
            </HeaderIcons>
            <StyledHeroBanner src={heroBanner} />
            <StyledDivider />
        </StyledHeader>
    );
};

export const HeaderIconContainer = styled.div``;
export const StyledHeader = styled.div`
    display: inline-block;
    margin: 0;
    width: 100%;
    margin-bottom: 0;
`;
export const StyledDivider = styled(Divider)`
    background-image: ${({ theme: { colors } }) =>
        `linear-gradient(to left, ${colors.yellow},  ${colors.orange}, ${colors.red})`};
    height: 0.7rem;
    border-top: none;
    display: inline-block;
    padding-right: calc(100% - 0.5px);
    position: relative;
    top: -0.3rem;
    @media (min-width: ${(props) => getScreenBreaks(props).screenLMin}) {
        height: 1rem;
    }
`;
export const HeaderIcons = styled.div<{ deviceType: DeviceType }>`
    display: flex;
    padding: 0rem;
    min-height: ${({ deviceType }) =>
        deviceType === DeviceType.Mobile ? '3.6rem' : '5.6rem'};
    margin: auto;
    border-top: 0.1rem solid ${({ theme: { colors } }) => colors.secondaryGrey};
    border-bottom: solid 0.1rem
        ${({ theme: { colors } }) => colors.secondaryGrey};
    justify-content: flex-end;
    align-items: center;
    @media (max-width: ${(props) => getScreenBreaks(props).screenSMax}) {
        width: 30.2rem;
    }

    @media (min-width: ${(props) =>
            getScreenBreaks(props).screenSMax}) and (max-width: ${(props) =>
            getScreenBreaks(props).screenMDMax}) {
        width: 60.8rem;
    }

    @media (min-width: ${(props) => getScreenBreaks(props).screenLMin}) {
        width: 72rem;
    }
`;

export const ColoredMAUILogo = styled(Logo)<{ deviceType: DeviceType }>`
    fill: ${({ theme: { colors } }) => colors.blue};
    width: ${({ deviceType }) =>
        deviceType === DeviceType.Mobile ? '3.478rem' : '5.478rem'};
    padding-right: ${({ deviceType }) =>
        deviceType === DeviceType.Mobile ? '1.22rem' : '2.22rem'};
`;
export const ColoredBritzLogo = styled(Logo)<{ deviceType: DeviceType }>`
    width: ${({ deviceType }) =>
        deviceType === DeviceType.Mobile ? '3.478rem' : '5.24rem'};
    padding-right: ${({ deviceType }) =>
        deviceType === DeviceType.Mobile ? '1.16rem' : '2.16rem'};
`;
export const ColoredMightyLogo = styled(Logo)<{ deviceType: DeviceType }>`
    path:last-child {
        fill: ${({ theme: { colors } }) => colors.secondaryRed};
    }
    width: ${({ deviceType }) =>
        deviceType === DeviceType.Mobile ? '3.223rem' : '6.223rem'};
    padding-right: ${({ deviceType }) =>
        deviceType === DeviceType.Mobile ? '1.16rem' : '2.16rem'};
`;

const ApolloLogo = styled(Logo)<{ deviceType: DeviceType }>`
    width: ${({ deviceType }) =>
        deviceType === DeviceType.Mobile ? '3.6rem' : '5.623rem'};
    padding-right: ${({ deviceType }) =>
        deviceType === DeviceType.Mobile ? '1.16rem' : '2.16rem'};
`;

const ColoredCheapaLogo = styled(Logo)<{ deviceType: DeviceType }>`
    width: ${({ deviceType }) =>
        deviceType === DeviceType.Mobile ? '7.33rem' : '10.223rem'};
    padding-right: ${({ deviceType }) =>
        deviceType === DeviceType.Mobile ? '1.16rem' : '2.16rem'};
`;

const ColoredHippieLogo = styled(Logo)<{ deviceType: DeviceType }>`
    width: ${({ deviceType }) =>
        deviceType === DeviceType.Mobile ? '2.91rem' : '4.223rem'};
`;

export const StyledHeroBanner = styled.img`
    display: block;
    width: 100%;
    object-fit: cover;
    object-position: 0px 100%;
    height: 20.6rem;

    @media (min-width: ${(props) =>
            getScreenBreaks(props).screenMDMin}) and (max-width: ${(props) =>
            getScreenBreaks(props).screenMDMax}) {
        height: 38.4rem;
    }

    @media (min-width: ${(props) => getScreenBreaks(props).screenLMin}) {
        height: 48.9rem;
    }
`;
