import { rest } from 'msw';

import { getApiBaseUrl } from '../../utils/configStore';
import { MOCK_RES } from './useSciContent.mock.response';
import { getContentRoute } from '../useSciContent';
import { SciContent } from '../../types/SciContent';
import { sciContentKeys } from '../../constants';

const baseURL = getApiBaseUrl();
const delayDuration = 1000;

const mock = [
    rest.get<SciContent[]>(`${baseURL}${getContentRoute}`, (req, res, ctx) => {
        const key = req.url.searchParams.get(
            'key',
        ) as (typeof sciContentKeys)[keyof typeof sciContentKeys];

        if (!key || !MOCK_RES[key]) {
            return res(ctx.delay(delayDuration), ctx.status(500));
        }

        return res(
            ctx.delay(delayDuration),
            ctx.status(200),
            ctx.json(MOCK_RES[key]),
        );
    }),
];

export default mock;
