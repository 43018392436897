type PossibleDateType = string | Date | undefined | null;

export const prepareDate = (date: PossibleDateType): Date | undefined =>
    !date ||
    (typeof date === 'string' && date.startsWith('0001-01-01')) ||
    (typeof date === 'object' &&
        date.toDateString().startsWith('Mon Jan 01 0001'))
        ? undefined
        : new Date(date);

export const considerDateAsUtc = (date?: string | Date): Date | null => {
    if (!date) {
        return null;
    }

    const _date = typeof date === 'string' ? new Date(date) : date;

    return new Date(
        Date.UTC(
            _date.getFullYear(),
            _date.getMonth(),
            _date.getDate(),
            12,
            0,
            0,
        ),
    );
};
