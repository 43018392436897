/* eslint-disable react/prop-types */
import { FC, ReactElement } from 'react';
import styled from 'styled-components';

type NotificationBannerMessageProps = {
    header?: ReactElement | string;
    content: ReactElement | string;
};

export const NotificationBannerMessage: FC<NotificationBannerMessageProps> = ({
    header,
    content,
}) => (
    <StyledMessage data-test-id="notification-banner-message">
        {header && <StyledMessageHeader>{header}</StyledMessageHeader>}
        {content}
    </StyledMessage>
);
const StyledMessage = styled.div`
    color: ${({ theme: { colors } }) => colors.black};
    font-size: 1.4rem;
`;

const StyledMessageHeader = styled.div`
    margin: 0;
    font-weight: 600;
`;
