import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useWindowDimensions } from 'src/hooks/useWindowDimensions';

import messages from './loyaltyMembership.messages';
import { LoyaltyProgram } from './loyaltyMembership.types';
import { usePostLoyaltyMembership } from './loyaltyMembership.hooks';
import {
    LoyaltyMembershipModalContainer,
    PointsSpan,
    StyledNumberInput,
    StyledPrimaryButton,
    Sup,
} from './loyaltyMembership.style';

export interface LoyaltyMembershipFormProps {
    loyaltyProgram: LoyaltyProgram;
    points: number;
    lastName: string;
    onClose: any;
}

const LoyaltyMembershipModal: FC<LoyaltyMembershipFormProps> = ({
    loyaltyProgram,
    points,
    lastName,
}) => {
    const intl = useIntl();
    const { deviceType } = useWindowDimensions();

    const { error, isLoading, mutate } = usePostLoyaltyMembership();

    const fieldsSchema = yup.object().shape({
        number: yup
            .number()
            .transform((value, originalValue) => {
                return originalValue === '' ? undefined : value;
            })
            .typeError(
                intl.formatMessage(messages.errors.programNumbeType, {
                    name: `${loyaltyProgram.name} Number`,
                }),
            )
            .required(
                intl.formatMessage(messages.errors.programNumberRequired, {
                    name: `${loyaltyProgram.name} Number`,
                }),
            ),
    });

    const { handleSubmit, control, setError, clearErrors } = useForm({
        resolver: yupResolver(fieldsSchema),
    });

    const onSubmit = (data: any) => {
        mutate({
            hirerLastName: lastName,
            loyaltyProgramCode: loyaltyProgram.code,
            membershipNumber: data.number.toString(),
        });
    };

    useEffect(() => {
        if (!isLoading && error) {
            setError('number', { type: 'custom', message: error });
        } else if (isLoading) {
            clearErrors('number');
        }
    }, [error, isLoading, setError, clearErrors]);

    return (
        <LoyaltyMembershipModalContainer>
            <p>
                {intl.formatMessage(messages.globalText.enter, {
                    name: loyaltyProgram.name,
                    points: (
                        <PointsSpan>
                            {points} {loyaltyProgram.displayName} Points
                            <Sup>†</Sup>
                        </PointsSpan>
                    ),
                    displayName: loyaltyProgram.displayName,
                })}
            </p>
            <StyledNumberInput
                name="number"
                inputRestProps={{
                    type: 'number',
                    onKeyPress: (e: any) => {
                        if (e.key === 'e' || e.key === '-' || e.key === '.') {
                            e.preventDefault();
                        }
                    },
                }}
                control={control}
                label={intl.formatMessage(messages.fields.number, {
                    name: loyaltyProgram.name,
                })}
                isRequired
            />
            <ul>
                <li>
                    {intl.formatMessage(messages.globalText.lastNameMessage, {
                        name: loyaltyProgram.name,
                    })}
                </li>
                <li>
                    {intl.formatMessage(messages.globalText.estimateMessage)}
                </li>
            </ul>
            <StyledPrimaryButton
                isLoading={isLoading}
                deviceType={deviceType}
                onClick={handleSubmit(onSubmit)}
            >
                {intl.formatMessage(messages.button.addToBooking)}
            </StyledPrimaryButton>
        </LoyaltyMembershipModalContainer>
    );
};

export default LoyaltyMembershipModal;
