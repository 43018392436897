import { rest } from 'msw';

import { getApiBaseUrl } from 'src/utils/configStore';
import {
    PaymentIssuerResponse,
    PaymentSurchargeResponse,
    GetPaymentResponse,
} from '../payment.type';
import * as paymentApi from '../payment.api';
import { MOCK_RES } from './payment.api.mock.responses';

const baseURL = getApiBaseUrl();
const delayDuration = 1500;

const paymentApiMocks = [
    rest.get<PaymentIssuerResponse[]>(
        `${baseURL}${paymentApi.paymentIssuers}`,
        (req, res, ctx) =>
            res(
                ctx.delay(delayDuration),
                ctx.status(200),
                ctx.json(MOCK_RES.GET_paymentIssuers),
            ),
    ),
    rest.post<PaymentSurchargeResponse>(
        `${baseURL}${paymentApi.paymentSurcharge}`,
        (req, res, ctx) =>
            res(
                ctx.delay(delayDuration),
                ctx.status(200),
                ctx.json(MOCK_RES.POST_paymentSurcharge),
            ),
    ),
    rest.get<GetPaymentResponse>(
        `${baseURL}${paymentApi.payment}`,
        (req, res, ctx) =>
            res(
                ctx.delay(delayDuration),
                ctx.status(200),
                ctx.json(MOCK_RES.GET_payments),
            ),
    ),
    rest.post(`${baseURL}/simulateWindCaveAjaxPost`, (req, res, ctx) =>
        res(ctx.delay(delayDuration), ctx.status(200)),
    ),
    rest.post(`${baseURL}${paymentApi.payment}/:paymentId`, (req, res, ctx) =>
        res(ctx.delay(delayDuration), ctx.status(200)),
    ),
];

export default paymentApiMocks;
