import {
    createRoutineCreator,
    defaultRoutineStages,
} from 'redux-saga-routines';
import { Action } from 'redux-actions';

import { genApiSchemaState, setSingleState } from 'src/utils/redux';
import { RetrieveChoicesState, ProductChoice } from '../types';
import { wrapWithNamespace } from '../constants';

const createRetrieveChoicesRoutine = createRoutineCreator([
    ...defaultRoutineStages,
    'CALCULATE',
    'CALCULATESUCCESS',
]);
export const retrieveChoicesAction = createRetrieveChoicesRoutine(
    wrapWithNamespace('retrieveChoices'),
);

const initialState: RetrieveChoicesState = genApiSchemaState<ProductChoice[]>({
    loading: true,
    calculatingProductId: null,
});

const retrieveChoicesReducer = (
    state = initialState,
    { type, payload }: Action<ProductChoice[]>,
): RetrieveChoicesState => {
    const handler = HANDLERS[type as any];
    return handler ? handler(state, payload) : state;
};

const handleTrigger = setSingleState('loading', true);

const handleCalculate = (state: RetrieveChoicesState, payload: any) => ({
    ...state,
    error: {},
    calculatingProductId: payload.productId,
});

const handleSetCalculateSuccess = (state: RetrieveChoicesState) => ({
    ...state,
    error: {},
    calculatingProductId: null,
});

const handleSuccess = (
    state: RetrieveChoicesState,
    payload: ProductChoice[],
) => ({
    ...state,
    loading: false,
    data: payload,
    error: {},
});

const handleError = (_state: RetrieveChoicesState, payload: any) => ({
    ...initialState,
    loading: false,
    error: payload,
});

const HANDLERS = {
    [retrieveChoicesAction.TRIGGER]: handleTrigger,
    [retrieveChoicesAction.SUCCESS]: handleSuccess,
    [retrieveChoicesAction.CALCULATE]: handleCalculate,
    [retrieveChoicesAction.CALCULATESUCCESS]: handleSetCalculateSuccess,
    [retrieveChoicesAction.FAILURE]: handleError,
};

export default retrieveChoicesReducer;
