import { createRoutine } from 'redux-saga-routines';
import { Action } from 'redux-actions';

import {
    genApiSchemaState,
    setSingleState,
    wrapWithModule,
    RESET_APP_ACTION,
} from 'src/utils/redux';

import {
    OrganizationSettings,
    RetrieveOrganizationSettingsState,
} from './types';

const wrapWithNamespace = wrapWithModule(
    'modules/retrieveDefaultOrganizationsCountryCode',
);
export const retrieveOrganizationSettingsAction = createRoutine(
    wrapWithNamespace('retrieve'),
);

const defaultOrganizationCountryCode: OrganizationSettings = {
    id: { organizationId: '' },
    phoneNumbers: { defaultCountryIso2Code: '' },
};

const initialState: RetrieveOrganizationSettingsState =
    genApiSchemaState<OrganizationSettings>({
        data: defaultOrganizationCountryCode,
    });

const retrieveOrganizationSettingsReducer = (
    state = initialState,
    { type, payload }: Action<OrganizationSettings>,
): RetrieveOrganizationSettingsState => {
    const handler = HANDLERS[type];
    return handler ? handler(state, payload) : state;
};

const handleTrigger = setSingleState('loading', true);

const handleSuccess = (
    state: RetrieveOrganizationSettingsState,
    payload: OrganizationSettings,
) => ({
    ...state,
    loading: false,
    data: payload,
    error: {},
});

const handleError = (
    _state: RetrieveOrganizationSettingsState,
    payload: any,
) => ({
    ...initialState,
    loading: false,
    error: payload,
});

const HANDLERS = {
    [retrieveOrganizationSettingsAction.TRIGGER]: handleTrigger,
    [retrieveOrganizationSettingsAction.SUCCESS]: handleSuccess,
    [retrieveOrganizationSettingsAction.FAILURE]: handleError,
    [RESET_APP_ACTION]: () => initialState,
};

export default retrieveOrganizationSettingsReducer;
