import { fork, all } from 'redux-saga/effects';
import sciPageSaga from 'src/components/shared/SciPage/SciPage.saga';

import updateCustomerInfoSaga from '../modules/updateCustomerInfo/updateCustomerInfo.saga';
import addProductsSaga from '../modules/addProducts/addProducts.saga';
import selfCheckInSaga from '../modules/retrieveSelfCheckIn/retrieveSelfCheckIn.saga';
import addDriverSaga from 'src/modules/addDriver/addDriver.saga';
import addAdditionalDriversSaga from 'src/modules/addAdditionalDrivers/addAdditionalDrivers.saga';
import registerCompletion from 'src/modules/registerCompletion/registerCompletion.saga';

function* rootSaga() {
    yield all([
        fork(sciPageSaga),
        fork(updateCustomerInfoSaga),
        fork(addProductsSaga),
        fork(selfCheckInSaga),
        fork(addDriverSaga),
        fork(addAdditionalDriversSaga),
        fork(registerCompletion),
    ]);
}

export default rootSaga;
