import { getScreenBreaks } from 'src/utils/htmlHelper';
import styled, { css } from 'styled-components';
import { DotProps, NextWrapperProps } from './types';

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 3rem 0;
    line-height: 1.8rem;
`;

export const BackWrapper = styled.div`
    flex-basis: 20%;
    display: flex;
    color: ${({ theme: { colors } }) => colors.secondaryOrange};
    font-weight: 600;
    font-size: 1.4rem;
`;

export const ArrowBack = styled.i`
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    border: solid ${({ theme: { colors } }) => colors.secondaryOrange};
    border-width: 0 3px 3px 0;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
`;

export const BackLabel = styled.span`
    margin-left: 0.8rem;
`;

export const DotsWrapper = styled.div`
    flex-basis: 60%;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    > div {
        margin-right: 3.5rem;

        @media (min-width: ${(props) =>
                getScreenBreaks(props).screenXSMin}) and (max-width: ${(
                props,
            ) => getScreenBreaks(props).screenXSMax}) {
            margin-right: 1.5rem;
        }

        &:last-child {
            margin-right: 0;
        }
    }
`;

export const Dot = styled.div<DotProps>`
    font-size: 1.4rem;
    font-weight: 600;
    height: 1.8rem;
    width: 1.8rem;
    color: ${({ theme: { colors } }) => colors.secondaryOrange};
    background-color: ${({ theme: { colors } }) => colors.lightOrange};
    border: 2px solid;
    border-color: ${({ theme: { colors } }) => colors.transparent};
    border-radius: 50%;
    text-align: center;
    line-height: 1.8rem;

    ${({ active, isCompleted }) =>
        active &&
        css`
            border-color: ${({ theme: { colors } }) =>
                isCompleted ? colors.orange : colors.secondaryOrange};
        `}
    ${({ isCompleted }) =>
        isCompleted &&
        css`
            color: ${({ theme: { colors } }) => colors.white};
            background-color: ${({ theme: { colors } }) =>
                colors.secondaryOrange};
        `}
`;

export const NextWrapper = styled.div<NextWrapperProps>`
    flex-basis: 20%;
    justify-content: flex-end;
    display: flex;
    font-weight: 600;
    font-size: 1.4rem;
    color: ${({ theme: { colors } }) => colors.lightOrange};

    ${({ active }) =>
        active &&
        css`
            color: ${({ theme: { colors } }) => colors.secondaryOrange};
        `}
`;

export const ArrowNext = styled.i<NextWrapperProps>`
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    border: solid ${({ theme: { colors } }) => colors.lightOrange};
    border-width: 0 3px 3px 0;
    margin-left: 0.8rem;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);

    ${({ active }) =>
        active &&
        css`
            border-color: ${({ theme: { colors } }) => colors.secondaryOrange};
        `}
`;

export const ClickableDiv = styled.div<{ active: boolean }>`
    cursor: not-allowed;

    ${({ active }) =>
        active &&
        css`
            cursor: pointer;
        `}
`;
