import { sciContentKeys } from '../../constants';

type MockResType = {
    [key in (typeof sciContentKeys)[keyof typeof sciContentKeys]]: [
        {
            title: string;
            key: string;
            order: number;
            content: string;
        },
    ];
};

export const MOCK_RES: MockResType = {
    [sciContentKeys.sciCustomerDetailsPage]: [
        {
            title: 'Sci Customer Details Page',
            key: sciContentKeys.sciCustomerDetailsPage,
            order: 1,
            content: '<p>Sci Customer Details Page</p>',
        },
    ],
    [sciContentKeys.sciDriverDetailsPage]: [
        {
            title: 'Sci Driver Details Page',
            key: sciContentKeys.sciDriverDetailsPage,
            order: 1,
            content: '<p>Sci Driver Details Page</p>',
        },
    ],
    [sciContentKeys.sciAdditionalDriverDetailsPage]: [
        {
            title: 'Sci Additional Driver Details Page',
            key: sciContentKeys.sciAdditionalDriverDetailsPage,
            order: 1,
            content: '<p>Sci Additional Driver Details Page</p>',
        },
    ],
    [sciContentKeys.sciAccidentLiabilityPage]: [
        {
            title: 'Sci Accident Liability Page',
            key: sciContentKeys.sciAccidentLiabilityPage,
            order: 1,
            content: '<p>Sci Accident Liability Page</p>',
        },
    ],
    [sciContentKeys.sciExtrasPage]: [
        {
            title: 'Sci Extra Page',
            key: sciContentKeys.sciExtrasPage,
            order: 1,
            content: '<p>Sci Extra Page</p>',
        },
    ],
    [sciContentKeys.sciPaymentPage]: [
        {
            title: 'Sci Payment Page',
            key: sciContentKeys.sciPaymentPage,
            order: 1,
            content: '<p>Sci Payment Page</p>',
        },
    ],
    [sciContentKeys.termsAndConditionsAcceptance]: [
        {
            title: 'Payment Term and Condition',
            key: sciContentKeys.termsAndConditionsAcceptance,
            order: 1,
            content:
                '<p><strong style="color: rgb(23, 43, 77);">Yes.</strong><span style="color: rgb(23, 43, 77);"> I have read and accepted the rental </span><a href="https://thl.widen.net/s/gq7lsflftp/mighty-nz-rental-agreement-2223-english.pdf" rel="noopener noreferrer" target="_blank" style="color: var(--ds-link, #0052CC);">Terms &amp; Conditions</a><span style="color: rgb(23, 43, 77);"> this includes acknowledgment of booking cancellation fees and credit card administration fees.</span></p>',
        },
    ],
    [sciContentKeys.infoBoxCardAdminFee]: [
        {
            title: 'Info Box Card Admin Fee',
            key: sciContentKeys.infoBoxCardAdminFee,
            order: 1,
            content:
                '<p>This is the content of the info box card admin fee</p>',
        },
    ],
    [sciContentKeys.sciBankTransferConfirmation]: [
        {
            title: 'SCI Bank Transfer Confirmation',
            key: sciContentKeys.sciBankTransferConfirmation,
            order: 1,
            content:
                '<p><strong>Your Self Check In is now completed!</strong></p><p><br></p><p>Look out for some other emails from us in the lead-up to your arrival. They are packed full of handy hints, tips and travel tools to get you prepared for the adventure ahead.</p><p><br></p><p>We look forward to welcoming you soon!</p>',
        },
    ],
    [sciContentKeys.sciOnlinePaymentConfirmation]: [
        {
            title: 'Sci Online Payment Confirmation',
            key: sciContentKeys.sciOnlinePaymentConfirmation,
            order: 1,
            content:
                '<p><strong>Thanks for your payment. Your Self Check In is now completed</strong></p><p><br></p><p>Look out for some other emails from us in the lead-up to your arrival. They are packed full of handy hints, tips and travel tools to get you prepared for the adventure ahead. We look forward to welcoming you soon!</p>',
        },
    ],
    [sciContentKeys.sciConfirmationPage]: [
        {
            title: 'Sci Confirmation Page',
            key: sciContentKeys.sciConfirmationPage,
            order: 1,
            content:
                '<p><strong>Thanks for your payment. Your Self Check In is now completed</strong></p><p><br></p><p>Look out for some other emails from us in the lead-up to your arrival. They are packed full of handy hints, tips and travel tools to get you prepared for the adventure ahead. We look forward to welcoming you soon!</p>',
        },
    ],
    [sciContentKeys.bankTransferInfo]: [
        {
            title: 'Bank Transfer Information',
            key: sciContentKeys.bankTransferInfo,
            order: 1,
            content:
                '<div><p>Bank Transfer (for final balance only).&nbsp;<strong>IMPORTANT</strong>: Money must be received by us before vehicle collection. Please complete the transfer at least&nbsp;<strong>21 working days</strong>&nbsp;prior to your pick up date.</p><p><br></p><p><strong>PLEASE REMIT TO</strong>: Tourism Holdings Limited - Rentals Private Bag 92133, Auckland, New Zealand</p><p><strong>BANK TRANSFERS</strong>: Westpac Banking Corporation, 79 Queen Street, Auckland, New Zealand</p><p><strong>ACCOUNT NO</strong>: 03-0104-0950190-03</p><p><strong>SWIFT NO</strong>: WPACNZ2W</p><p><br></p><p>Please use your booking number as the payment reference.</p><p><strong>IMPORTANT</strong>: Once deposit has been transacted please forward receipt of the transaction (internet receipt or stamped deposit slip) to&nbsp;<a href="http://localhost:3000/booking.maui@thlonline.com" rel="noopener noreferrer" target="_blank">booking.maui@thlonline.com</a>.</p></div>',
        },
    ],
    [sciContentKeys.sciInfoBoxCustomerDetails]: [
        {
            title: 'SCI Popup Customer Details',
            key: sciContentKeys.sciInfoBoxCustomerDetails,
            order: 1,
            content: `<span>These are the details we currently have in our system for your booking. Please contact us via 
            <a href="https://cornerstonecx.co.nz/symbee-connect-chatclient/chatClient/thlonline/prod-sso/SelfCheckIn?QueueName=SelfCheckIn" target="_blank">web chat</a> or the phone number below to make any changes.</span>`,
        },
    ],
    [sciContentKeys.sciInfoBoxDriverDetails]: [
        {
            title: 'SCI Popup Driver Details',
            key: sciContentKeys.sciInfoBoxDriverDetails,
            order: 1,
            content: `<p class="ContainedModal__StyledPargraph-sc-pg7ywv-2 jiwUXH"><p class="addDrivercomponentstyle__ContainedModalParagraph-sc-1r29err-7 eplnwu">A minimum age of 21 years applies if the rented vehicle is a Action Pod, Mighty Highball or Mighty Double Down.</p><p class="addDrivercomponentstyle__ContainedModalParagraph-sc-1r29err-7 eplnwu">If this applies to you, please contact us via 
            <a href="https://cornerstonecx.co.nz/symbee-connect-chatclient/chatClient/thlonline/prod-sso/SelfCheckIn?QueueName=SelfCheckIn" target="_blank">Web Chat</a> or phone number below for assistance.</p></p>`,
        },
    ],
    [sciContentKeys.sciInfoBoxAdditionalDriverDetails]: [
        {
            title: 'SCI Popup Additional Driver Details',
            key: sciContentKeys.sciInfoBoxAdditionalDriverDetails,
            order: 1,
            content: `<p class="ContainedModal__StyledPargraph-sc-pg7ywv-2 jiwUXH"><p class="addAdditionalDriversComponentstyle__ContainedModalParagraph-sc-dm863y-7 bxXyKg">A fee of $2 per extra driver, per day applies (maximum charge per hire, per driver is $30). If you have purchased the Inclusive Pack, you can add drivers for free.</p><p class="addAdditionalDriversComponentstyle__ContainedModalParagraph-sc-dm863y-7 bxXyKg">A minimum age of 21 years applies if the rental vehicle is a Action Pod, Mighty Highball or Mighty Double Down. If this applies to you, please contact us via 
            <a href="https://cornerstonecx.co.nz/symbee-connect-chatclient/chatClient/thlonline/prod-sso/SelfCheckIn?QueueName=SelfCheckIn" target="_blank">Web Chat</a> or phone number below for assistance.</p></p>`,
        },
    ],
};
