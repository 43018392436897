import styled from 'styled-components';

import { PrimaryButton } from 'cosmos-components';

import { Input } from 'src/components/form';
import { DeviceType } from 'src/enums';

export const Container = styled.div`
    margin-top: 3.2rem;
    border: 0.1rem solid ${({ theme: { colors } }) => colors.b2cLightGrey};
`;

export const ContentContainer = styled.div<{ deviceType: DeviceType }>`
    display: flex;
    flex-direction: ${({ deviceType }) =>
        deviceType === DeviceType.Mobile ? 'column' : 'row'};
    justify-content: space-between;
    padding: 2rem;
`;

export const LoaderContainer = styled(Container)`
    padding: 2rem;
`;

export const LeftContentContainer = styled.div<{ deviceType: DeviceType }>`
    display: flex;
    gap: 0.8rem;
    flex-direction: ${({ deviceType }) =>
        deviceType === DeviceType.Mobile ? 'column' : 'row'};
`;

export const LogoContainer = styled.div`
    height: 3.2rem;

    svg {
        height: 100%;
        width: initial;
    }
`;

export const AddRemoveButton = styled.a<{
    deviceType: DeviceType;
    disabled?: boolean;
}>`
    width: ${({ deviceType }) =>
        deviceType === DeviceType.Mobile ? '100%' : '6.9rem'};
    font-size: 1.28rem;
    font-weight: 600;
    padding: 1rem 0rem;
    text-align: center;
    border: 0.1rem solid ${({ theme: { colors } }) => colors.b2cRedThl};
    cursor: pointer;
    color: ${({ theme: { colors } }) => colors.b2cRedThl};

    ${({ deviceType }) =>
        deviceType !== DeviceType.Mobile &&
        `
        margin-left: 3.2rem;
    `}

    ${({ deviceType }) =>
        deviceType === DeviceType.Mobile &&
        `
        margin-top: 2rem;
    `}

    ${({ disabled, theme: { colors } }) =>
        disabled &&
        `
        border-color: ${colors.b2cLightGrey};
        color: ${colors.b2cLightGrey};
        cursor: not-allowed;
    `}
`;

export const LoyaltyProgramContainer = styled.div`
    flex: 1;
`;

export const LoyaltyProgramParagraph = styled.p`
    font-weight: bold;
`;

export const LoyaltyMembershipModalContainer = styled.form`
    display: flex;
    margin-top: 3rem;
    flex-direction: column;
`;

export const PointsSpan = styled.span`
    color: ${({ theme: { colors } }) => colors.b2cRedThl};
`;

export const Sup = styled.span`
    vertical-align: super;
    font-size: 1rem;
`;

export const StyledPrimaryButton = styled(PrimaryButton)<{
    deviceType: DeviceType;
}>`
    width: ${({ deviceType }) =>
        deviceType === DeviceType.Mobile ? '100%' : '15rem'};
    align-self: end;
    margin-top: 4rem;
    margin-bottom: 1rem;
`;

export const StyledNumberInput = styled(Input)`
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type='number'] {
        -moz-appearance: textfield;
    }
`;
