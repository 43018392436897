import { createRoutine } from 'redux-saga-routines';
import { Action } from 'redux-actions';

import { genApiSchemaState, setSingleState } from 'src/utils/redux';
import { wrapWithNamespace } from './constants';
import { RegisterCompletionPayload, RegisterCompletionState } from './types';

export const registerCompletionAction = createRoutine(
    wrapWithNamespace('update'),
);

const initialState: RegisterCompletionState = genApiSchemaState({
    data: undefined,
});

const updateRegisterCompletionReducer = (
    state = initialState,
    { type, payload }: Action<RegisterCompletionPayload>,
): RegisterCompletionState => {
    const handler = HANDLERS[type];
    return handler ? handler(state, payload) : state;
};

const handleTrigger = setSingleState('loading', true);

const handleSuccess = (state: RegisterCompletionState) => ({
    ...state,
    loading: false,
    error: {},
});

const handleError = (_: RegisterCompletionState, payload: any) => ({
    ...initialState,
    error: payload,
});

const HANDLERS = {
    [registerCompletionAction.TRIGGER]: handleTrigger,
    [registerCompletionAction.SUCCESS]: handleSuccess,
    [registerCompletionAction.FAILURE]: handleError,
};

export default updateRegisterCompletionReducer;
