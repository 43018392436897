import styled from 'styled-components';
import { FormField } from 'cosmos-components';

export const ValidationError = styled.div`
    color: ${({ theme: { colors } }) => colors.red};
    padding-top: 0.5rem;
    font-size: 1.4rem;
`;
export const ButtonWrapper = styled.div`
    text-align: center;
`;
export const StyledFormField = styled(FormField)`
    margin-top: 1.5rem;
    margin-bottom: 0;
    flex: 1 1 0;
    label {
        margin-bottom: 0.5rem;
    }
`;

export { CustomInput as Input } from './Input/Input';
export { FormattedInput } from './Input/FormattedInput';

export { CustomPhoneInput as PhoneInput } from './PhoneInput/PhoneInput';
export { CustomSelect as Select } from './Select/Select';
export { CustomCheckbox as Checkbox } from './Checkbox/Checkbox';
