import { FC, useEffect } from 'react';
import { B2C } from 'cosmos-components';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { SciPage } from 'src/components/shared/SciPage';
import { setCarouselDotsCurrentPage } from 'src/components/shared/SciPage/SciPage.duck';
import { SciPagesEnum } from 'src/components/shared/SciPage/types';
import { useSciContent } from 'src/hooks/useSciContent';
import { sciContentKeys } from 'src/constants';

import { colors } from 'src/themes';

import * as S from './payment.style';
import { MODULE_PREFIX } from './payment.constants';
import { usePayment } from './hooks/usePayment';
import msg from './payment.messages';
import { DueToday } from './dueToday.component';

import { PaymentForm } from './payment.form';

export const componentVehicleProtections = `${MODULE_PREFIX}-component`;

export const PaymentComponent: FC = () => {
    const {
        paymentAmount,
        paymentIssuers,
        paymentIssuerLoading,
        paymentSurcharge,
        paymentSurchargeIsLoading,
        needToConsiderSurcharge,
        isPaymentInProgress,
        selectedPaymentMethod,
        selectedPaymentIssuer,
        currency,
        control,
        error,
        paymentError,
        onSubmitManual,
        onSubmit,
    } = usePayment();

    const intl = useIntl();
    const dispatch = useDispatch();

    const title = intl.formatMessage(msg.headerItems.title);

    useEffect(() => {
        dispatch(setCarouselDotsCurrentPage(SciPagesEnum.Payment));
    }, [dispatch]);

    const {
        data: sciPaymentPageLabel,
        isLoading: isSciPaymentPageLabelLoading,
    } = useSciContent(sciContentKeys.sciPaymentPage);

    return (
        <B2C.LoaderContainer
            loading={isPaymentInProgress}
            size={'large'}
            opacity={{ opacity: '90%', color: colors.white }}
        >
            <SciPage data-test-id={componentVehicleProtections}>
                <S.Container>
                    <S.Header>
                        <S.PaymentTitle>{title}</S.PaymentTitle>
                        <S.PaymentText>
                            <B2C.LoaderContainer
                                loading={isSciPaymentPageLabelLoading}
                                size={'small'}
                            >
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: sciPaymentPageLabel ?? '',
                                    }}
                                ></span>
                            </B2C.LoaderContainer>
                        </S.PaymentText>
                    </S.Header>
                    <DueToday
                        paymentAmount={paymentAmount}
                        currency={currency}
                    />
                    <PaymentForm
                        paymentIssuers={paymentIssuers ?? []}
                        paymentIssuerLoading={paymentIssuerLoading}
                        paymentSurcharge={paymentSurcharge}
                        paymentSurchargeIsLoading={paymentSurchargeIsLoading}
                        needToConsiderSurcharge={needToConsiderSurcharge}
                        selectedPaymentMethod={selectedPaymentMethod}
                        selectedPaymentIssuer={selectedPaymentIssuer}
                        isPaymentInProgress={isPaymentInProgress}
                        control={control}
                        error={error}
                        paymentError={paymentError}
                        onSubmit={onSubmit}
                        onSubmitManual={onSubmitManual}
                    />
                </S.Container>
            </SciPage>
        </B2C.LoaderContainer>
    );
};
