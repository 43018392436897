import { FC } from 'react';
import { useIntl } from 'react-intl';

import { phoneLink } from 'src/utils/intlHelper';
import globalMessages from 'src/messages';

const ContactPhonesUK: FC = () => {
    const intl = useIntl();

    return (
        <>
            <span>
                {intl.formatMessage(globalMessages.phones.callFromUK)}
                {': '}
                {intl.formatMessage(globalMessages.phones.callFromUkPhone, {
                    a: phoneLink,
                })}
            </span>
            <br />
            <span>
                {intl.formatMessage(globalMessages.phones.callFromOutsideUK)}
                {': '}
                {intl.formatMessage(
                    globalMessages.phones.callFromOutsideUkPhone,
                    {
                        a: phoneLink,
                    },
                )}
            </span>
            <br />
            <span>
                {intl.formatMessage(
                    globalMessages.phones.callFromCanadaOrAustralia,
                )}
                {': '}
                {intl.formatMessage(
                    globalMessages.phones.callFromCanadaOrAustraliaPhone,
                    {
                        a: phoneLink,
                    },
                )}
            </span>
        </>
    );
};

export default ContactPhonesUK;
