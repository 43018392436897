import { FC, useState } from 'react';
import { Input, Spinner } from 'cosmos-components';
import PlacesAutocomplete from 'react-places-autocomplete';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import { isAscii } from 'src/utils/string';

import { AddressInfo } from './types';
import { placesAutoCompleteMsgs } from './messages';
import { lookupAddress } from './utils';

export interface GooglePlacesAutoCompleteProps {
    onSelect: (addressInfo: AddressInfo) => void;
}

export const GooglePlacesAutoComplete: FC<GooglePlacesAutoCompleteProps> = ({
    onSelect,
}) => {
    const intl = useIntl();
    const [addressLookup, setAddressLookup] = useState('');

    const handleAddressLookupSelect = async (address: string) => {
        setAddressLookup(address);
        const addressInfo = await lookupAddress(address);
        onSelect?.(addressInfo);
    };

    return (
        <PlacesAutocomplete
            value={addressLookup}
            onChange={setAddressLookup}
            onSelect={handleAddressLookupSelect}
            searchOptions={{
                types: ['street_address'],
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                language: intl.locale,
            }}
            data-test-id="google-places-autocomplete"
        >
            {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
            }) => {
                const inputProps = getInputProps();

                return (
                    <PlacesAutocompleteFieldWrapper>
                        <Input
                            {...getInputProps({
                                placeholder: intl.formatMessage(
                                    placesAutoCompleteMsgs.searchPlaces,
                                ),
                            })}
                            onInputChange={(value) => {
                                if (isAscii(value)) {
                                    inputProps.onChange({
                                        target: { value },
                                    });
                                }
                            }}
                        />
                        {loading && <StyledSpinner size="small" />}
                        {suggestions?.length > 0 && (
                            <SuggestionList>
                                {suggestions.map((suggestion, index) => (
                                    <SuggestionItem
                                        {...getSuggestionItemProps(suggestion)}
                                        key={index}
                                        active={suggestion.active}
                                    >
                                        {suggestion.description}
                                    </SuggestionItem>
                                ))}
                            </SuggestionList>
                        )}
                    </PlacesAutocompleteFieldWrapper>
                );
            }}
        </PlacesAutocomplete>
    );
};

const SuggestionList = styled.div`
    position: absolute;
    z-index: 100;
    background: ${({ theme: { colors } }) => colors.white};
    border: 1px solid ${({ theme: { colors } }) => colors.grey};
    border-radius: 3px;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
    width: 100%;
    font-size: 1.4rem;
    padding: 0.2rem;
    box-sizing: border-box;
`;

const SuggestionItem = styled.div<{ active: boolean }>`
    background-color: ${({ active, theme: { colors } }) =>
        active ? colors.primaryGrey : colors.white};
    cursor: pointer;
    padding: 0.8rem;
`;

const PlacesAutocompleteFieldWrapper = styled.div`
    position: relative;
`;

const StyledSpinner = styled(Spinner)`
    position: absolute;
    right: 1rem;
    top: 1.1rem;
`;
