import { FC } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, createSearchParams } from 'react-router-dom';

import { useWindowDimensions } from 'src/hooks/useWindowDimensions';
import { DeviceType } from 'src/enums';

import { carouselDotsMsgs } from './messages';
import * as S from './CarousalDots.style';
import { SciPagesEnum } from '../SciPage/types';

export interface DotItem {
    label: string;
    isCompleted: boolean;
    path: string;
    pageEnum: SciPagesEnum;
}

export interface CarousalDotsProps {
    currentPageEnum: SciPagesEnum;
    items: DotItem[];
    lng?:string;
    className?: string;
}

export const CarousalDots: FC<CarousalDotsProps> = ({
    currentPageEnum,
    items,
    lng,
    className,
}) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { deviceType } = useWindowDimensions();
    const currentPageIndex = items.findIndex(
        (item) => item.pageEnum === currentPageEnum,
    );
    const nextPageIndex = currentPageIndex + 1;
    const previousPageIndex = currentPageIndex - 1;

    const isNextActive =
        currentPageIndex < items.length - 1 &&
        items[currentPageIndex].isCompleted;

    const showNextButton = nextPageIndex < items.length;

    const handleBack = () => {
        if (currentPageIndex > 0) {
            navigate({
                pathname: items[previousPageIndex].path,
                search: createSearchParams(lng ? {lng} : {}).toString(),
            });
        }
    };

    const handleNext = () => {
        if (isNextActive) {
            navigate({
                pathname: items[nextPageIndex].path,
                search: createSearchParams(lng ? {lng} : {}).toString(),
            });
        }
    };

    return (
        <S.Wrapper data-test-id="carousel-dots" className={className}>
            <S.BackWrapper>
                <S.ClickableDiv active onClick={handleBack}>
                    <S.ArrowBack role="back" />
                    {deviceType !== DeviceType.Mobile && (
                        <S.BackLabel>
                            {intl.formatMessage(carouselDotsMsgs.back)}
                        </S.BackLabel>
                    )}
                </S.ClickableDiv>
            </S.BackWrapper>
            <S.DotsWrapper>
                {items?.map((item, index) => (
                    <S.Dot
                        key={`Dot-${item.label}`}
                        isCompleted={item.isCompleted}
                        active={index === currentPageIndex}
                        role="dot"
                    >
                        {item.label}
                    </S.Dot>
                ))}
            </S.DotsWrapper>
            <S.NextWrapper active={isNextActive}>
                {showNextButton && (
                    <S.ClickableDiv active={isNextActive} onClick={handleNext}>
                        {deviceType !== DeviceType.Mobile && (
                            <span>
                                {intl.formatMessage(carouselDotsMsgs.next)}
                            </span>
                        )}
                        <S.ArrowNext active={isNextActive} role="next" />
                    </S.ClickableDiv>
                )}
            </S.NextWrapper>
        </S.Wrapper>
    );
};
