import { FC } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import * as GlobalStyle from 'src/styles/global.style';
import { Row } from 'src/components/shared/Row';
import { Column } from 'src/components/shared/Column';

import { Select, Checkbox } from 'src/components/form';
import { Point } from 'src/types/Point';

import msg from './shuttleService.messages';
import { formatShuttleKey } from '../utils';

const MODULE_PREFIX = 'shuttleService';
export const shuttleServiceCheckbox = `${MODULE_PREFIX}-shuttleRequested`;
export const shuttleServiceSelect = `${MODULE_PREFIX}-shuttleServiceSelect`;

export interface ShuttleServiceFormProps {
    control: any;
    shuttlePoints: Point[];
    isShuttleSelected: boolean;
}

const ShuttleServiceForm: FC<ShuttleServiceFormProps> = ({
    control,
    shuttlePoints,
    isShuttleSelected,
}) => {
    const intl = useIntl();

    return (
        <>
            <Row>
                <GlobalStyle.SubHeaderText>
                    {intl.formatMessage(msg.headers.shuttleRequested)}
                </GlobalStyle.SubHeaderText>
            </Row>
            <Row>
                <StyledCheckbox
                    className="shuttle-checkbox"
                    testId={shuttleServiceCheckbox}
                    name="isShuttleSelected"
                    control={control}
                    labelPosition="after"
                    label={`${intl.formatMessage(msg.fields.shuttleRequested)}`}
                />
            </Row>
            {isShuttleSelected && (
                <Row>
                    <Column size={12}>
                        <Select
                            testId={shuttleServiceSelect}
                            name="shuttleSelected"
                            control={control}
                            label={intl.formatMessage(
                                msg.fields.shuttlePickupLocation,
                            )}
                            options={[null, ...shuttlePoints].map(
                                (shuttlePoint) =>
                                    shuttlePoint
                                        ? `${formatShuttleKey(shuttlePoint)}`
                                        : '',
                            )}
                        />
                    </Column>
                </Row>
            )}
        </>
    );
};

const StyledCheckbox = styled(Checkbox)`
    &.shuttle-checkbox {
        margin-top: 0;
    }
`;

export default ShuttleServiceForm;
