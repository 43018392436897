import { AxiosRequestConfig } from 'axios';
import { HttpClient } from 'src/utils/axios';
import { getApiBaseUrl } from 'src/utils/configStore';
import { languageOptions } from 'src/constants';

export const selfCheckInRoute = '/bff/b2c/sci';

export class SCIApi extends HttpClient {
    private static classInstance?: SCIApi;
    private static readonly sciSessionHeader = 'X-SciSessionId';
    private static readonly sciLanguageHeader = 'accept-language';

    private constructor() {
        super({});
        this.initializeResponseInterceptor();
    }

    public changeBaseURL(): void {
        const baseUrl = getApiBaseUrl();
        this._instance.defaults.baseURL = baseUrl;
    }

    protected handleError = (error: any) => {
        for (const ErrorKey in this.ErrorHandlers) {
            this.ErrorHandlers[ErrorKey](error);
        }
        return Promise.reject(error?.response?.data?.error);
    };

    public addErrorHandler(key: string, onError: (error: any) => void): void {
        this.ErrorHandlers[key] = onError;
    }

    public removeErrorHandler(key: string): void {
        delete this.ErrorHandlers[key];
    }

    public static getInstance(): SCIApi {
        if (!this.classInstance) {
            this.classInstance = new SCIApi();
        }
        if (
            !this.classInstance.getHeaderElementByKey(SCIApi.sciSessionHeader)
        ) {
            const sessionId = sessionStorage.getItem(SCIApi.sciSessionHeader);

            if (!!sessionId) {
                this.classInstance.appendInstanceHeadersByKey(
                    SCIApi.sciSessionHeader,
                    sessionId,
                );
            }
        }
        if (
            !this.classInstance.getHeaderElementByKey(SCIApi.sciLanguageHeader)
        ) {
            const language = sessionStorage.getItem(SCIApi.sciLanguageHeader);

            if (!!language) {
                this.classInstance.appendInstanceHeadersByKey(
                    SCIApi.sciLanguageHeader,
                    language,
                );
            }
        }
        return this.classInstance;
    }

    public configureSessionId(sessionId?: string): void {
        sessionStorage.setItem(SCIApi.sciSessionHeader, sessionId ?? '');
        this.appendInstanceHeadersByKey(
            SCIApi.sciSessionHeader,
            sessionId ? sessionId : null,
        );
    }

    public configureLanguage(language?: string): void {
        const langId = (languageOptions.find(
            (opt: any) =>
                opt?.label?.toLowerCase() === language,
        ) || languageOptions[0])?.id;
        sessionStorage.setItem(SCIApi.sciLanguageHeader, langId ?? '');
        this.appendInstanceHeadersByKey(
            SCIApi.sciLanguageHeader,
            langId ?? null,
        );
    }

    public get = <T>(
        url: string,
        params: any = {},
        config: AxiosRequestConfig = {},
    ) => this._instance.get<T>(url, { ...config, params });

    public post = <T>(
        url: string,
        data: any = {},
        config: AxiosRequestConfig = {},
    ) => this._instance.post<T>(url, { ...config, data });

    public put = <T>(
        url: string,
        data: any = {},
        config: AxiosRequestConfig = {},
    ) => this._instance.put<T>(url, { ...config, data });

    public delete = <T>(url: string, config: AxiosRequestConfig = {}) =>
        this._instance.delete<T>(url, config);
}
