import { createRoutine } from 'redux-saga-routines';
import { Action } from 'redux-actions';

import { genApiSchemaState, setSingleState } from 'src/utils/redux';
import { ProductChoice, SubmitProductsState } from '../types';
import { wrapWithNamespace } from '../constants';

export const submitProductsAction = createRoutine(
    wrapWithNamespace('submitProducts'),
);

const initialState: SubmitProductsState = genApiSchemaState({
    data: undefined,
});

const submitProductsReducer = (
    state = initialState,
    { type, payload }: Action<ProductChoice[]>,
): SubmitProductsState => {
    const handler = HANDLERS[type];
    return handler ? handler(state, payload) : state;
};

const handleTrigger = setSingleState('loading', true);

const handleSuccess = (state: SubmitProductsState) => ({
    ...state,
    loading: false,
    error: {},
});

const handleError = (_: SubmitProductsState, payload: any) => ({
    ...initialState,
    error: payload,
});

const HANDLERS = {
    [submitProductsAction.TRIGGER]: handleTrigger,
    [submitProductsAction.SUCCESS]: handleSuccess,
    [submitProductsAction.FAILURE]: handleError,
};

export default submitProductsReducer;
