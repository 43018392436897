import styled, { css } from 'styled-components';
import {
    Input,
    PrimaryButton,
    FormField,
    NotificationBanner,
} from 'cosmos-components';
import { getScreenBreaks } from 'src/utils/htmlHelper';

export const StyledFormField = styled(FormField)`
    margin-top: 1.5rem;
    margin-bottom: 0;
    flex: 1 1 0;
    label {
        margin-bottom: 0.5rem;
        height: 100%;
    }
`;

export const ActionWrapper = styled.div`
    width: 100%;
    text-align: center;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
    position: relative;
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    width: 100%;
`;

export const StyledAddressLine2Input = styled(Input)`
    margin-top: 1.5rem;
`;

export const StyledInput = styled(Input)`
    ${({ disabled }) =>
        disabled &&
        css`
            background-color: ${({ theme: { colors } }) => colors.primaryGrey};
        `}
`;

export const AddDriverForm = styled.form`
    margin: auto;
`;

export const Row = styled.div`
    display: flex;
    > div:last-child {
        margin-left: 0.8rem;
    }
    .dateOfBirth_fr label, .licenseNumber_fr label {
        height: 3rem;
    }
`;

export const DriverFormHeader = styled.h3`
    margin-top: 0;
    margin-bottom: 0;
    padding: 0.8rem 0rem;
    border-bottom: 2px solid ${({ theme: { colors } }) => colors.red};
`;

export const AdjacentPairsWrapper = styled.div`
    flex-basis: 50%;
`;

export const StyledNotificationBanner = styled(NotificationBanner)`
    margin-top: 1rem;
    @media (min-width: ${(props) => getScreenBreaks(props).screenMDMin}) {
        margin-left: auto;
        margin-right: auto;
        width: 32.7rem;
    }
`;

export const StyledMessageContent = styled.div`
    margin: 0;
    padding-top: 0.9rem;
    font-weight: 500;
`;
