import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { CosmosEnums } from 'cosmos-components';

import { organizationIds } from 'src/constants';
import { SCIApi, selfCheckInRoute } from 'src/apis';
import { SelfCheckIn } from 'src/modules/retrieveSelfCheckIn/types';

import { useBookingSummary } from './useBookingSummary';
import { usePrimaryProductInfo } from './usePrimaryProductInfo';

const selfCheckInSessionRoute = `${selfCheckInRoute}/session`;

export const getSciData = async () => {
    const apiCalls = SCIApi.getInstance();
    const result: AxiosResponse<SelfCheckIn> = await apiCalls.get<SelfCheckIn>(
        selfCheckInSessionRoute,
    );

    return result?.data ?? result;
};

export const useSciKey = 'Sci';
export const usePrimaryProductInfoKey = 'primaryProductInfo';

export const useSci = () => {
    const { isLoading, isError, data, error } = useQuery({
        queryKey: [useSciKey],
        queryFn: getSciData,
    });

    const {
        isLoading: isPrimaryProductInfoLoading,
        isError: isPrimaryProductInfoError,
        data: primaryProductInfoData,
    } = usePrimaryProductInfo();

    const {
        isLoading: isBookingSummaryLoading,
        isError: isBookingSummaryError,
        data: bookingSummaryData,
        error: bookingSummaryError,
    } = useBookingSummary();

    const [countryCode, setcountryCode] = useState<CosmosEnums.CountryCode>(
        CosmosEnums.CountryCode.AU,
    );

    useEffect(() => {
        const orgId = data?.id?.organizationId;
        const newCountryCode = Object.entries(organizationIds).find(
            ([key, value]) => value === orgId,
        )?.[0] as CosmosEnums.CountryCode;

        setcountryCode(newCountryCode);
    }, [data?.id?.organizationId]);

    return {
        isLoading: isLoading || isPrimaryProductInfoLoading,
        isError: isError || isPrimaryProductInfoError || isBookingSummaryError,
        data,
        error,
        primaryProductInfoData,
        isBookingSummaryLoading,
        bookingSummaryData,
        bookingSummaryError,
        countryCode,
    };
};
