import { all, put, takeLatest } from 'redux-saga/effects';

import { SCIApi } from 'src/apis';
import { setFlowAsCompleted } from 'src/components/shared/SciPage/SciPage.duck';

import * as api from '../registerCompletion/registerCompletion.api';
import { RegisterCompletionPayload } from './types';
import { registerCompletionAction } from './registerCompletion.duck';

function* completeSelfCheckIn({
    payload,
}: {
    payload: RegisterCompletionPayload;
}) {
    try {
        yield put(setFlowAsCompleted());
        const apiCalls = SCIApi.getInstance();

        apiCalls.post(yield api.completeSelfCheckIn(payload.rentalId));

        yield put(registerCompletionAction.success({}));
    } catch (error) {
        yield put(registerCompletionAction.failure(error));
    }
}

function* registerCompletionSaga() {
    yield all([takeLatest(registerCompletionAction, completeSelfCheckIn)]);
}

export default registerCompletionSaga;
