import styled from 'styled-components';

import { Icon, Modal, PrimaryButton } from 'cosmos-components';

import { DeviceType } from 'src/enums';

import { ConfigurationNodeGroupDisplay } from './products.types';

export const Container = styled.div`
    font-size: 1rem;
    margin: auto;
`;

export const Header = styled.div`
    display: flex;
    font-size: 2rem;
    flex-direction: column;
    padding-bottom: 1.5rem;
    margin: auto;
`;

const defaultSpan = styled.span`
    padding-bottom: 0.5rem;
`;

export const Title = styled(defaultSpan)`
    font-size: 1em;
    font-weight: bold;
    padding-bottom: 0.5rem;
`;

export const Text = styled(defaultSpan)`
    font-size: 0.7em;
`;

export const ProductNodesContainer = styled.div<{
    display: ConfigurationNodeGroupDisplay;
    deviceType: DeviceType;
}>`
    display: flex;
    margin-top: 3.2rem;

    ${({ theme: { colors }, display, deviceType }) =>
        display === 'Horizontally' && deviceType !== DeviceType.Mobile
            ? `
        flex-direction: row;
        gap: 0.8rem;
        `
            : `
        flex-direction: column;
        gap: 2rem;
    `};
`;

export const ProductNodeContainer = styled.div``;

export const FormUpgradeContainer = styled.div`
    width: 90%;
    margin: auto;
    margin-top: 0.5rem;
`;

export const StyledModalPrimaryButton = styled(PrimaryButton)`
    position: relative;
    margin-left: auto;
    display: flex;
    opacity: 1;
    min-width: 5.6rem;
    align-items: center;
`;

export const Close = styled.div`
    text-align: left;
    letter-spacing: 0;
    color: ${({ theme: { colors } }) => colors.white};
    text-transform: capitalize;
    opacity: 1;
`;

export const StyledExitIcon = styled(Icon)`
    position: relative;
    margin-right: 0.5rem;
`;

export const StyledModal = styled(Modal)`
    .rc-dialog-content {
        border-top: ${({ theme: { colors } }) =>
            `0.4rem solid ${colors.secondaryOrange}`};
        color: ${({ theme }) => theme.colors.black};
        background: ${({ theme }) => theme.colors.white};
        overflow: auto;
    }
`;

export const Line = styled.hr`
    height: 1px;
    border: 0;
    border-top: ${({ theme: { colors } }) => `1px solid ${colors.grey}`};
    margin: 3.2rem 0;
`;
