import { selfCheckInRoute } from 'src/apis';

export const getDriverLicense = `${selfCheckInRoute}/documents/driverlicense`;
export const getRentalDriversPath = (rentalId: string): string =>
    `${selfCheckInRoute}/rentals/${rentalId}/drivers`;
export const getRentalDriversDeletePath = (
    rentalId: string,
    profileId: string,
): string => `${selfCheckInRoute}/rentals/${rentalId}/drivers/${profileId}`;
export const getStates = `${selfCheckInRoute}/points/states`;
