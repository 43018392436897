import { createSelector } from 'reselect';
import { getModuleSelector } from 'src/utils/redux';

import { SetRedirectionState } from './types';

const moduleSelector = getModuleSelector('sagaReactRouterMiddleware');

export const currentRedirection = createSelector<any, string | null>(
    moduleSelector,
    (sagaReactRouter: SetRedirectionState) => sagaReactRouter?.redirection,
);
