import { defineMessages } from 'react-intl';

const fields = defineMessages({
    email: {
        id: 'updateCustomerInfo.formField.email',
        defaultMessage: 'Email',
    },
    firstName: {
        id: 'updateCustomerInfo.formField.firstName',
        defaultMessage: 'First Name',
    },
    lastName: {
        id: 'updateCustomerInfo.formField.lastName',
        defaultMessage: 'Last Name',
    },
    mobile: {
        id: 'updateCustomerInfo.formField.mobile',
        defaultMessage: 'Mobile',
    },
    phone: {
        id: 'updateCustomerInfo.formField.phone',
        defaultMessage: 'Phone',
    },
    homeAddressLookup: {
        id: 'updateCustomerInfo.formField.homeAddressLookup',
        defaultMessage: 'Home Address Lookup',
    },
    addressLine1: {
        id: 'updateCustomerInfo.formField.addressLine1',
        defaultMessage: 'Home Address',
    },
    city: {
        id: 'updateCustomerInfo.formField.city',
        defaultMessage: 'City',
    },
    postCode: {
        id: 'updateCustomerInfo.formField.postCode',
        defaultMessage: 'Post/Zip Code',
    },
    country: {
        id: 'updateCustomerInfo.formField.country',
        defaultMessage: 'Country',
    },
    preferredLanguage: {
        id: 'updateCustomerInfo.formField.preferredLanguage',
        defaultMessage: 'Preferred Language',
    },
    state: {
        id: 'updateCustomerInfo.formField.state',
        defaultMessage: 'State/Province/Region',
    },
    adults: {
        id: 'updateCustomerInfo.formField.adults',
        defaultMessage: 'Adults',
    },
    children: {
        id: 'updateCustomerInfo.formField.children',
        defaultMessage: 'Children',
    },
    driver: {
        id: 'updateCustomerInfo.formField.driver',
        defaultMessage: 'Will you be driving?',
    },
    validEmail: {
        id: 'updateCustomerInfo.formField.validEmail',
        defaultMessage: 'Email must be a valid email',
    },
    enterEmail: {
        id: 'updateCustomerInfo.formField.enterEmail',
        defaultMessage: 'Please enter email address',
    },
    enterFirstName: {
        id: 'updateCustomerInfo.formField.enterFirstName',
        defaultMessage: 'Please enter first name',
    },
    enterLastName: {
        id: 'updateCustomerInfo.formField.enterLastName',
        defaultMessage: 'Please enter last name',
    },
    enterMobile: {
        id: 'updateCustomerInfo.formField.enterMobile',
        defaultMessage: 'Please enter mobile number',
    },
    enterPhone: {
        id: 'updateCustomerInfo.formField.enterPhone',
        defaultMessage: 'Please enter phone number',
    },
    enterHomeAddress: {
        id: 'updateCustomerInfo.formField.enterHomeAddress',
        defaultMessage: 'Please enter your home address',
    },
    enterCity: {
        id: 'updateCustomerInfo.formField.enterCity',
        defaultMessage: 'Please enter city',
    },
    enterPostCode: {
        id: 'updateCustomerInfo.formField.enterPostCode',
        defaultMessage: 'Please enter a valid Post/Zip code',
    },
    selectCountry: {
        id: 'updateCustomerInfo.formField.selectCountry',
        defaultMessage: 'Please select country',
    },
    selectState: {
        id: 'updateCustomerInfo.formField.selectState',
        defaultMessage: 'Please select State/Province/Region',
    },
    selectAdults: {
        id: 'updateCustomerInfo.formField.selectAdults',
        defaultMessage:
            'Number of adults should not be less than hirer + number of additional drivers',
    },
    selectShuttle: {
        id: 'updateCustomerInfo.formField.selectShuttle',
        defaultMessage: 'Please select your shuttle pickup location.',
    },
    emailMisMatch: {
        id: 'updateCustomerInfo.formField.emailMisMatch',
        defaultMessage:
            'Please update the First Name and Last Name to match the email, or contact us via Webchat or the phone numbers below for assistance.',
    },
    emailExists: {
        id: 'updateCustomerInfo.formField.emailExists',
        defaultMessage: 'This email is already taken.',
    },
    passengersExceeded: {
        id: 'updateCustomerInfo.formField.passengersExceeded',
        defaultMessage: 'Maximum Passenger exceeded for this product.',
    },
});

const placeHolders = defineMessages({
    email: {
        id: 'updateCustomerInfo.placeHolder.email',
        defaultMessage: 'Enter email',
    },
    firstName: {
        id: 'updateCustomerInfo.placeHolder.firstName',
        defaultMessage: 'Enter first name',
    },
    lastName: {
        id: 'updateCustomerInfo.placeHolder.lastName',
        defaultMessage: 'Enter last name',
    },
    mobile: {
        id: 'updateCustomerInfo.placeHolder.mobile',
        defaultMessage: 'Enter mobile',
    },
    phone: {
        id: 'updateCustomerInfo.placeHolder.phone',
        defaultMessage: 'Enter phone',
    },
    homeAddressLookup: {
        id: 'updateCustomerInfo.placeHolder.homeAddressLookup',
        defaultMessage: 'Search for an address',
    },
    addressLine1: {
        id: 'updateCustomerInfo.placeHolder.addressLine1',
        defaultMessage: 'Enter address line 1',
    },
    addressLine2: {
        id: 'updateCustomerInfo.placeHolder.addressLine2',
        defaultMessage: 'Enter address line 2',
    },
    city: {
        id: 'updateCustomerInfo.placeHolder.city',
        defaultMessage: 'Enter city',
    },
    postCode: {
        id: 'updateCustomerInfo.placeHolder.postCode',
        defaultMessage: 'Enter post/zip code',
    },
    country: {
        id: 'updateCustomerInfo.placeHolder.country',
        defaultMessage: 'Select country',
    },
    state: {
        id: 'updateCustomerInfo.placeHolder.state',
        defaultMessage: 'Enter state/province/region',
    },
});

const formHeaderItems = defineMessages({
    customerDetails: {
        id: 'updateCustomerInfo.formHeaderItem.customerDetails',
        defaultMessage: 'Customer Details',
    },
    passengersHeader: {
        id: 'updateCustomerInfo.formHeaderItem.passengersHeader',
        defaultMessage: 'How many passengers are traveling?',
    },
    detailsDialogMsg: {
        id: 'updateCustomerInfo.formHeaderItem.detailsDialogMsg',
        defaultMessage: `These are the details we currently have in our system for your booking.
         Please contact us via {url} or the phone number below to make any changes.`,
    },
    detailsDialogContactPhoneMsg: {
        id: 'updateCustomerInfo.formHeaderItem.detailsDialogContactPhoneMsg',
        defaultMessage: `These are the details we currently have in our system for your booking.
         Please contact us via the phone number below to make any changes.`,
    },
    emailUs: {
        id: 'updateCustomerInfo.formHeaderItem.emailUs',
        defaultMessage: `email`,
    },
    webChat: {
        id: 'updateCustomerInfo.formHeaderItem.webChat',
        defaultMessage: `web chat`,
    },
});

const buttons = defineMessages({
    continue: {
        id: 'updateCustomerInfo.buttons.continueButton',
        defaultMessage: 'Save & Continue',
    },
});

const updateCustomerInfoMessages = {
    fields,
    placeHolders,
    formHeaderItems,
    buttons,
};

export default updateCustomerInfoMessages;
