import { defineMessages } from 'react-intl';

export const formHeaderItems = defineMessages({
    title: {
        id: 'extras.formHeaderItem.title',
        defaultMessage: 'Extras',
    },
    errorHeader: {
        id: 'extras.formHeaderItem.errorHeader',
        defaultMessage: `Error`,
    },
});

const vehicleProtectionsMessages = {
    formHeaderItems,
};

export default vehicleProtectionsMessages;
