import { createSelector } from 'reselect';
import { getModuleSelector } from 'src/utils/redux';
import { customerInfoSelector } from 'src/modules/updateCustomerInfo/updateCustomerInfo.selector';
import { CustomerInfo } from 'src/modules/updateCustomerInfo/types';
import { BatchError } from 'src/modules/types';

import { defaultDriver } from './ducks/retrieveDriver.duck';
import {
    AddDriverReducerType,
    Driver,
    States,
    RetrieveDriversState,
    RetrieveStatesState,
    UpdateDriversState,
} from './types';

const moduleSelector = getModuleSelector<AddDriverReducerType>('addDriver');

export const retrieveModuleSelector = createSelector<any, RetrieveDriversState>(
    moduleSelector,
    (addDriver: AddDriverReducerType) => addDriver.retrieveDriver,
);

export const retrieveStatesSelector = createSelector<any, RetrieveStatesState>(
    moduleSelector,
    (addDriver: AddDriverReducerType) => addDriver.retrieveStates,
);

export const driverSelectors = createSelector<any, Driver[]>(
    retrieveModuleSelector,
    (retrieveDrivers: RetrieveDriversState) => retrieveDrivers.data,
);

export const retrieveDriversIsLoadingSelector = createSelector<any, boolean>(
    retrieveModuleSelector,
    (retrieveDrivers: RetrieveDriversState) => retrieveDrivers.loading,
);

export const driverSelector = createSelector<any, Driver | undefined>(
    driverSelectors,
    customerInfoSelector,
    (drivers: Driver[], customerInfo: CustomerInfo) => {
        if (customerInfo.isDriver && drivers) {
            return drivers?.find((driver) => driver.hirer);
        } else if (!customerInfo.isDriver && drivers && drivers.length) {
            return drivers.find((driver) => !driver.hirer);
        }

        return defaultDriver;
    },
);

export const updateModuleSelector = createSelector<any, UpdateDriversState>(
    moduleSelector,
    (addDriver: AddDriverReducerType) => addDriver.updateDriver,
);

export const updateDriverIsLoadingSelector = createSelector<any, boolean>(
    updateModuleSelector,
    (updateDriver: UpdateDriversState) => updateDriver.loading,
);

export const updateDriverErrorSelector = createSelector<any, BatchError>(
    updateModuleSelector,
    (updateDriver: UpdateDriversState) => updateDriver.error,
);

export const statesSelector = createSelector<any, States>(
    retrieveStatesSelector,
    (states: RetrieveStatesState) => states.data,
);
