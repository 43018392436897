import { createSelector } from 'reselect';
import { AcceptedLocalsType } from 'cosmos-components/dist/i18n';
import { getModuleSelector } from 'src/utils/redux';
import { AllBrands } from 'src/enums';

import { SciCarouselDotsState, SciPageState } from './types';

const moduleSelector = getModuleSelector('sciPageInfo');

export const sciPageInfoSelector = createSelector<any, SciPageState>(
    moduleSelector,
    (sciPageState: SciPageState) => sciPageState,
);
export const carouselDotsSelector = createSelector<
    any,
    SciCarouselDotsState | undefined
>(
    sciPageInfoSelector,
    (sciPageState: SciPageState) => sciPageState?.carouselDotsState,
);

export const sciBrandNameSelector = createSelector<any, AllBrands | undefined>(
    moduleSelector,
    (sciPageState: SciPageState) => sciPageState?.brandName,
);

export const sciLanguageSelector = createSelector<any, AcceptedLocalsType | undefined>(
    moduleSelector,
    (sciPageState: SciPageState) => sciPageState?.language,
);
