import { Action } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

import { BatchError } from 'src/modules/types';
import {
    genApiSchemaState,
    setSingleState,
    RESET_APP_ACTION,
} from 'src/utils/redux';

import { wrapWithNamespace } from '../constants';
import { Driver, UpdateDriversState } from '../types';

export const updateDriverAction = createRoutine(wrapWithNamespace('update'));

const initialState: UpdateDriversState = genApiSchemaState<Driver>({
    data: undefined,
});

const updateDriversReducer = (
    state = initialState,
    { type, payload }: Action<Driver>,
): UpdateDriversState => {
    const handler = HANDLERS[type as any];
    return handler ? handler(state, payload) : state;
};

const handleTrigger = setSingleState('loading', true);

const handleSuccess = (state: UpdateDriversState, payload: Driver) => ({
    ...state,
    loading: false,
    data: payload,
    error: {},
});

const handleError = (
    _state: UpdateDriversState,
    payload: BatchError,
): UpdateDriversState => ({
    ...initialState,
    loading: false,
    error: payload,
});

const HANDLERS = {
    [updateDriverAction.TRIGGER]: handleTrigger,
    [updateDriverAction.SUCCESS]: handleSuccess,
    [updateDriverAction.FAILURE]: handleError,
    [RESET_APP_ACTION]: () => initialState,
};

export default updateDriversReducer;
