import { defineMessages } from 'react-intl';

const footer = defineMessages({
    privacyPolicy: {
        id: 'footer.privacyPolicy',
        defaultMessage: 'Privacy Policy',
    },
    allotherlocations: {
        id: 'footer.allotherlocations',
        defaultMessage: 'All other locations',
    },
    Rights: {
        id: 'footer.Rights',
        defaultMessage: 'All Rights Reserved',
    },
    callingFrom: {
        id: 'footer.callingFrom',
        defaultMessage: 'Calling From',
    },
    copyRight: {
        id: 'footer.copyRight',
        defaultMessage: 'Copyright',
    },
    needHelp: {
        id: 'footer.needHelp?',
        defaultMessage: 'Need Help?',
    },
    webChat: {
        id: 'footer.webChat',
        defaultMessage: 'try web chat',
    },
    emailUs: {
        id: 'footer.emailUs',
        defaultMessage: 'Email Us',
    },
});

const footerMessages = {
    footer,
};

export default footerMessages;
