import { defineMessages } from 'react-intl';

import { PaymentMethodEnum } from './payment.enum';

export const headerItems = defineMessages({
    title: {
        id: 'payment.headerItem.title',
        defaultMessage: 'Payment',
    },
});

export const label = defineMessages({
    paymentMethod: {
        id: 'payment.label.paymentMethod',
        defaultMessage: 'Choose Payment Method Below',
    },
    issuer: {
        id: 'payment.label.issuer',
        defaultMessage: 'Card Type',
    },
    cardNumber: {
        id: 'payment.label.cardNumber',
        defaultMessage: 'Card Number',
    },
    cardHolderName: {
        id: 'payment.label.cardHolderName',
        defaultMessage: 'Card Holder Name',
    },
    cardExpiry: {
        id: 'payment.label.cardExpiry',
        defaultMessage: 'Expiry Date',
    },
    cardExpiryUS: {
        id: 'payment.label.cardExpiryUS',
        defaultMessage: 'Expiration Date',
    },
    cardCVV: {
        id: 'payment.label.cardCVV',
        defaultMessage: 'Security Code',
    },
});

export const placeHolder = defineMessages({
    cardExpiry: {
        id: 'payment.placeHolder.cardExpiry',
        defaultMessage: 'MM/YY',
    },
    cardCVV: {
        id: 'payment.placeHolder.cardCVV',
        defaultMessage: '3 or 4 digits',
    },
});

export const subLabel = defineMessages({
    cardType: {
        id: 'payment.subLabel.cardType',
        defaultMessage:
            'A non-refundable {amount} card administration fee will apply.',
    },
});

export const paymentBox = defineMessages({
    finalBalanceDue: {
        id: 'payment.paymentBox.finalBalanceDue',
        defaultMessage: 'Final Balance Due',
    },
    dueToday: {
        id: 'payment.paymentBox.dueToday',
        defaultMessage: 'Due Today',
    },
    fullPayment: {
        id: 'payment.paymentBox.fullPayment',
        defaultMessage: 'Full Payment',
    },
});

export const paymentMethod = defineMessages({
    [PaymentMethodEnum.CreditCard]: {
        id: 'payment.paymentMethod.creditCard',
        defaultMessage: 'Credit Card',
    },
    [PaymentMethodEnum.DebitCard]: {
        id: 'payment.paymentMethod.debitCard',
        defaultMessage: 'Debit Card',
    },
    [PaymentMethodEnum.Manual]: {
        id: 'payment.paymentMethod.manual',
        defaultMessage: 'Others',
    },
});

export const backendError = defineMessages({
    generic: {
        id: 'payment.backendError.generic',
        defaultMessage:
            'Please try to refresh or contact us to progress booking. If you are still experiencing issues, please contact us.',
    },
    genericWithLink: {
        id: 'payment.backendError.genericWithLink',
        defaultMessage:
            'Please try to refresh or contact us to progress booking. If you are still experiencing issues, please contact us <a>here</a>.',
    },
    paymentError: {
        id: 'payment.backendError.paymentError',
        defaultMessage:
            'An error has occurred during payment processing. No money has been taken. ' +
            'Please try to process payment again or contact us to progress booking. If you are still experiencing issues, please contact us.',
    },
    paymentErrorWithLink: {
        id: 'payment.backendError.paymentErrorWithLink',
        defaultMessage:
            'An error has occurred during payment processing. No money has been taken. ' +
            'Please try to process payment again or contact us to progress booking. If you are still experiencing issues, please contact us <a>here</a>.',
    },
});

const paymentMessages = {
    headerItems,
    label,
    placeHolder,
    subLabel,
    paymentBox,
    paymentMethod,
    backendError,
};

export default paymentMessages;
