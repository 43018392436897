import styled, { css } from 'styled-components';
import { Icon } from 'cosmos-components';

export const Container = styled.div`
    font-size: 1rem;
    margin: auto;
`;

export const Header = styled.div`
    display: flex;
    font-size: 2rem;
    flex-direction: column;
    padding-bottom: 3.2rem;
    margin: auto;
`;

export const PaymentTitle = styled.span`
    font-size: 1em;
    font-weight: bold;
    padding-bottom: 0.8rem;
`;

export const PaymentText = styled.span<{ bold?: boolean }>`
    font-size: 0.8em;

    ${({ bold }) =>
        bold &&
        css`
            font-weight: 600;
        `}
`;

export const DueTodayContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fcfcfc;
    border: 0.1rem solid #cccccc;
    border-radius: 0.2rem;
    padding: 3.2rem;
    font-size: 2rem;
`;

export const DueTodayTitleContainer = styled.div`
    display: flex;
`;

export const DueTodayPaymentSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const StyledIcon = styled(Icon)`
    padding-right: 0.8rem;
    padding-top: 0.1rem;
`;
