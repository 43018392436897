import { Action } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';
import { BatchError } from 'src/modules/types';
import { genApiSchemaState, setSingleState } from 'src/utils/redux';
import { wrapWithNamespace } from '../constants';
import {
    DeleteAdditionalDriverPayload,
    DeleteAdditionalDriverState,
} from '../../addDriver/types';

export const deleteAdditionalDriverAction = createRoutine(
    wrapWithNamespace('delete'),
);

const initialState: DeleteAdditionalDriverState = genApiSchemaState({
    data: undefined,
});

const deleteAdditionalDriverReducer = (
    state = initialState,
    { type, payload }: Action<DeleteAdditionalDriverPayload>,
): DeleteAdditionalDriverState => {
    const handler = HANDLERS[type as any];
    return handler ? handler(state, payload) : state;
};

const handleTrigger = setSingleState('loading', true);

const handleSuccess = (state: DeleteAdditionalDriverState, payload: []) => ({
    ...state,
    loading: false,
    data: payload,
    error: {},
});

const handleError = (
    _state: DeleteAdditionalDriverState,
    payload: BatchError,
): DeleteAdditionalDriverState => ({
    ...initialState,
    loading: false,
    error: payload,
});

const HANDLERS = {
    [deleteAdditionalDriverAction.TRIGGER]: handleTrigger,
    [deleteAdditionalDriverAction.SUCCESS]: handleSuccess,
    [deleteAdditionalDriverAction.FAILURE]: handleError,
};

export default deleteAdditionalDriverReducer;
