import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { SCIApi } from 'src/apis';
import { Point } from 'src/types/Point';

import { getShuttlePoints as getShuttlePointsApiPath } from '../updateCustomerInfo.api';

const getShuttlePoints = async () => {
    const apiCalls = SCIApi.getInstance();
    const result: AxiosResponse<Point[]> = await apiCalls.get<Point[]>(
        getShuttlePointsApiPath,
    );

    return result?.data ?? result;
};

export const useGetShuttleServicePointsKey = 'useGetShuttleServicePointsKey';

export const useGetShuttleService = () => {
    const { isLoading, data: shuttlePoints } = useQuery({
        queryKey: [useGetShuttleServicePointsKey],
        queryFn: getShuttlePoints,
    });

    return { shuttlePoints, isLoading };
};
