import styled from 'styled-components';
import { ContainedModal } from 'src/components/shared/ContainedModal';

export const Header = styled.div`
    padding-bottom: 1.5rem;
    margin: auto;
`;

export const AdditionalDriverDetails = styled.div`
    font-size: 2rem;
    font-weight: bold;
    display: flex;
    position: relative;
`;

export const AdditionalDriverDetailsLabel = styled.span`
    flex-basis: 93%;
`;

export const AdditionalDriverDetailsNote = styled.div`
    font-size: 1.4rem;
    margin-top: 0.8rem;
`;

export const AdditionalDriverDetailsNoteParagraph = styled.p`
    margin: 0.8rem 0 0 0;
`;

export const AdditionalDriverDetailsNoteBold = styled.span`
    font-weight: bold;
`;

export const StyleContainedModal = styled(ContainedModal)`
    & > button {
        margin-top: 0.2rem;
    }
    .rc-dialog {
        width: 100% !important;
        .rc-dialog-body {
            font-size: 1.4rem;
            a {
                color: ${({ theme: { colors } }) => colors.secondaryOrange};
            }
        }
    }
`;

export const ContainedModalParagraph = styled.p`
    margin: 0.8rem 0 0 0;

    &:first-child {
        margin-top: 0;
    }
`;
