import { all, put, takeLatest } from 'redux-saga/effects';

import { retrieveChoicesAction } from './ducks/retrieveChoices.duck';
import { SCIApi } from 'src/apis';
import * as api from './addProducts.api';
import { ProductChoice } from './types';
import { submitProductsAction } from './ducks/submitProducts.duck';

const apiCalls = SCIApi.getInstance();

function* retrieveChoices({ payload }: { payload: ProductChoice[] }) {
    try {
        const data: ProductChoice[] = yield apiCalls.get<ProductChoice[]>(
            api.retrieveChoices,
            payload,
        );
        yield put(retrieveChoicesAction.success(data));
    } catch (e) {
        yield put(retrieveChoicesAction.failure(e));
    }
}

function* submitProducts({ payload }: { payload: ProductChoice[] }) {
    try {
        yield apiCalls.post(api.addProducts, payload);
        yield put(submitProductsAction.success());
    } catch (e) {
        yield put(submitProductsAction.failure(e));
    }
}

function* calculate({ payload }: { payload: any }) {
    try {
        yield apiCalls.post(api.calculate, payload);
        yield put(retrieveChoicesAction.calculatesuccess());
    } catch (e) {
        yield put(retrieveChoicesAction.failure(e));
    }
}

function* addProductsSaga() {
    yield all([
        takeLatest(retrieveChoicesAction, retrieveChoices),
        takeLatest(submitProductsAction, submitProducts),
        takeLatest(retrieveChoicesAction.calculate, calculate),
    ]);
}

export default addProductsSaga;
