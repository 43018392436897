export const storeConfigs = (configs: any): void => {
    Object.defineProperty(window, '__COSMOS_ENV_CONFIG__', {
        value: Object.freeze(configs),
        writable: false,
    });
};
export const getNodeEnvironment = (): any => {
    return window.__COSMOS_ENV_CONFIG__?.NODE_ENV || 'test';
};
export const getApiBaseUrl = (): any => {
    return (
        window.__COSMOS_ENV_CONFIG__?.API_BASE_URL ||
        'https://cosmos-alb-test-1.aws.thlonline.com'
    );
};
export const getReCaptchaKey = (): string =>
    window.__COSMOS_ENV_CONFIG__?.RECAPTCHA_SITE_KEY;

export const getGoogleAnalyticsApiKey = (): string =>
    window.__COSMOS_ENV_CONFIG__?.GOOGLE_ANALYTICS_API_KEY;
