import { FC } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import styled, { css } from 'styled-components';

export interface DateTimePickerProps extends ReactDatePickerProps {
    isError?: boolean;
    testId?: string;
}

const DateTimePicker: FC<DateTimePickerProps> = (props) => {
    return <StyledReactDatePicker {...props}></StyledReactDatePicker>;
};

const DateTimePickerWrapper = styled(({ className, testId, ...props }) => {
    return (
        <div className={className} data-test-id={testId}>
            <DatePicker {...props} />
        </div>
    );
})``;

const StyledReactDatePicker = styled(DateTimePickerWrapper)`
    input {
        width: 100%;
        height: 100%;
        border: 0;
        padding: 0;
        outline: none;
        font-size: 1.4rem;
        background: inherit;
        color: inherit;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &::placeholder {
            color: ${({ theme: { colors } }) => colors?.primerGrey};
        }
    }

    .react-datepicker__input-container {
        width: 100%;
        height: 4rem;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        color: ${({ theme: { colors } }) => colors?.textColor};
        border: 0.1rem solid ${({ theme: { colors } }) => colors?.grey};
        background-color: ${({ theme: { colors } }) => colors?.white};
        transition: background-color 0.1s ease-out;
        padding: 0.8rem 1.6rem;
        border-radius: 0.6rem;
        height: 3.5rem;
        ${({ disabled }) =>
            disabled &&
            css`
                background-color: ${({ theme: { colors } }) =>
                    colors.primaryGrey};
            `}

        ${({ isError }) =>
            isError &&
            css`
                border: 0.1rem solid ${({ theme: { colors } }) => colors?.red};
            `}
    }

    .react-datepicker{
        display: flex;
    }

    .react-datepicker__navigation {
        width: 30%;
        position: initial;
    }

    .react-datepicker__navigation-icon {
        position: initial; 
        &:before {
            border-color: ${({ theme: { colors } }) => colors?.secondaryBlack};
        }
    }

    .react-datepicker__navigation-icon--next::before {
        left: 24rem;
        top: 2.2rem;
    }

    .react-datepicker__navigation-icon--previous::before {
        right: 7rem;
        top: 2.2rem;
    }

    .react-datepicker__header {
        background-color: ${({ theme: { colors } }) => colors?.white};
        border-bottom: none;
        gap: 1.4rem;
        display: flex;
        flex-direction: column;
        margin: 1rem 1.7rem 0rem 1.7rem;
    }

    .react-datepicker__header__dropdown--scroll {
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: flex-start;
        z-index: 10;
        width: 75%;
    }

    .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
        border-bottom-color: ${({ theme: { colors } }) => colors?.white};;
       
    }

    .react-datepicker__year-read-view,
    .react-datepicker__month-read-view {
        padding: 0rem;
        margin: 0rem;
        &:hover {
            background-color: ${({ theme: { colors } }) =>
                colors?.quaternaryGrey};
        }

        span {
            padding: 0.2rem;
        }
         
    }

    .react-datepicker__month-read-view  { 
        width: 7rem;
    }

    .react-datepicker__current-month,
    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow {
         display none;
    }


    .react-datepicker__year-dropdown-container--select,
    .react-datepicker__month-dropdown-container--select {
        max-width: 35%;
        margin-top: 1rem;
    }

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__navigation-icon::before,
    .react-datepicker__month-read-view--down-arrow {
        border-width: 0.2rem 0.2rem 0 0;
        height: 0.6rem;
        width: 0.6rem;
    }

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow {
        right: -1.2rem;
    }

    .react-datepicker__month-read-view--selected-month,
    .react-datepicker__year-read-view--selected-year {
        font-size: 1.2rem;
        visibility: visible;
        color: ${({ theme: { colors } }) => colors?.secondaryBlack};
        font-weight: 600;
    }

    .react-datepicker__month-dropdown {
        width: 60%;
        left: -13%;
        background: ${({ theme: { colors } }) => colors?.white};;
    }

    .react-datepicker__year-dropdown {
        width: 60%;
        left: 7%;
        background: ${({ theme: { colors } }) => colors?.white};;
    }

    .react-datepicker__year-option,
    .react-datepicker__month-option {
        font-size: 1.2rem;
        margin: 0.2rem auto;
        &:hover {
            width: 80%;
            border-radius: 1rem;
            background-color: ${({ theme: { colors } }) => colors?.lightOrange};
        }
    }

    .react-datepicker__month-option--selected_month,
    .react-datepicker__year-option--selected_year {
        background-color: ${({ theme: { colors } }) => colors?.secondaryOrange};
        border-radius: 1rem;
        width: 80%;
        color: ${({ theme: { colors } }) => colors?.white};;
        &:hover {
            background-color: ${({ theme: { colors } }) =>
                colors?.secondaryOrange};
        }
    }

    .react-datepicker__month-option--selected,
    .react-datepicker__year-option--selected {
        display: none;
    }

    .react-datepicker__day {
        border-radius: 50%;
        font-weight: 400;
        font-size: 1.2rem;
        width: 3.2rem;
        line-height: 3.2rem;
        &:hover {
            border-radius: 50%;
            background-color: ${({ theme: { colors } }) => colors?.lightOrange};
        }
    }
    
    .react-datepicker__day-name{
        font-size: 1.2rem;
        font-weight: 600;
        width: 3.2rem;
        color: ${({ theme: { colors } }) => colors?.secondaryBlack};
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected {
        background-color: ${({ theme: { colors } }) => colors?.secondaryOrange};
        border-radius: 50%;
        &:hover {
            background-color: ${({ theme: { colors } }) =>
                colors?.secondaryOrange};
        }
    }
    .react-datepicker__day-names{
        margin-bottom: 0rem;
    }
`;

const defaultProps: Partial<DateTimePickerProps> = {
    showMonthDropdown: true,
    showYearDropdown: true,
    yearDropdownItemNumber: 100,
    scrollableYearDropdown: true,
    popperPlacement: 'bottom',
    useWeekdaysShort: true,
    testId: 'date-picker-item',
};
DateTimePicker.defaultProps = defaultProps;
export { DateTimePicker };
